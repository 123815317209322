// for th
// タイ語
import raMessage from 'ra-language-thai';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'ไม่พบ',
      page_rows_per_page: 'แสดงผลแล้ว:',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'ตกลง',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
      search: 'ค้นหา',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'ต้องการลบ %{name} หรือไม่',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'รอการอนุมัติ',
    usermenu: {
      userNotice: 'การแจ้งเตือน',
      terms: 'ข้อกำหนดในการใช้งาน',
      manual: 'คู่มือ',
      downloads: 'ดาวน์โหลด',
    },
    breadcrumbs: {
      details: 'รายละเอียด',
      retrofitEdit: 'รายละเอียด',
      taskCreate: 'สร้างงาน',
      taskEdit: 'รายละเอียดงาน',
      geofenceCreate: 'สร้าง',
      geofenceEdit: 'แก้ไข',
      geofenceAlertList: 'การแจ้งเตือนรั้วภูมิศาสตร์',
      geofenceAlertShow: 'รายละเอียด',
      compactionWorkAreaCreate: 'เพิ่มพื้นที่งานบดอัด',
      compactionWorkAreaShow: 'พื้นที่งานบดอัด',
      compactionWorkAreaEdit: 'แก้ไขพื้นที่งานบดอัด',
      compactionLayerCreate: 'สร้างเลเยอร์การบดอัด',
      compactionLayerEdit: 'แก้ไขเลเยอร์การบดอัด',
      supportMode: 'โหมดสนับสนุน',
      retrofitManage: 'การลงทะเบียนเครื่องควบคุม',
      retrofitManageAdmin: 'การลงทะเบียนเครื่องควบคุม (แอดมิน)',
      retrofitNamePlateFiles: 'การลงทะเบียนเครื่องควบคุม',
      retrofitAlternateRegistCreate: 'การลงทะเบียนพร็อกซี่เครื่องควบคุม',
      retrofitAlternateRegistEdit: 'รายละเอียด',
      retrofitManageAdminBulkActionCreate: 'การลงทะเบียน',
      terms: 'ข้อกำหนดในการใช้งาน',
      downloads: 'ดาวน์โหลด',
    },
    pages: {
      siteList: 'รายการไซต์งาน',
      siteRetrofitCreate: 'เพิ่มเครื่องในไซต์งาน',
      siteRetrofitEdit: '',
      siteBucketCreate: 'เพิ่มบุ้งกี๋ในไซต์งาน',
      siteBucketEdit: 'รายละเอียดบุ้งกี๋',
      siteProjectEdit: 'รายละเอียดโครงการ',
      siteProjectCreate: 'การลงทะเบียนไฟล์โครงการ',
      siteExtensionarmCreate: 'เพิ่มแขนต่อในไซต์งาน',
      taskCreate: 'สร้างงาน',
      taskEdit: 'รายละเอียดงาน',
      geofenceAlertList: 'การแจ้งเตือนรั้วภูมิศาสตร์',
      geofenceAlertShow: 'รายละเอียดการแจ้งเตือนรั้วภูมิศาสตร์',
      geofenceCreate: 'สร้างรั้วภูมิศาสตร์',
      geofenceEdit: 'แก้ไขรั้วภูมิศาสตร์',
      compactionWorkAreaCreate: 'เพิ่มพื้นที่งานบดอัด',
      compactionLayerCreate: 'สร้างเลเยอร์การบดอัด',
      compactionLayerEdit: 'แก้ไขเลเยอร์การบดอัด',
      siteFileEdit: 'รายละเอียด',
      siteFileCreate: 'เพิ่มไฟล์ในไซต์งาน',
      retrofitList: 'รายการเครื่องจักร',
      retrofitEdit: '',
      retrofitRentalCreate: 'การลงทะเบียนการมอบหมาย',
      retrofitTransferCreate: 'การลงทะเบียนการถ่ายโอน',
      retrofitSendBack: 'การลงทะเบียนการส่งกลับ',
      bucketList: 'รายการบุ้งกี๋',
      bucketEdit: 'รายละเอียดบุ้งกี๋',
      bucketCreate: 'การลงทะเบียนบุ้งกี๋',
      bucketRentalCreate: 'การแชร์ข้อมูลบุ้งกี๋',
      extensionarmList: 'รายการแขนต่อ',
      extensionarmEdit: 'รายละเอียดแขนต่อ',
      extensionarmShare: 'การแชร์ข้อมูลแขนต่อ',
      retrofitBulkActionList: 'รายการการลงทะเบียนชุด',
      retrofitBulkActionCreate: 'การลงทะเบียนชุด',
      retrofitBasicInfoList: 'รายการเครื่องควบคุม',
      retrofitBasicInfoCreate: 'การลงทะเบียนเครื่องควบคุม',
      retrofitBasicInfoEdit: 'การอัปเดตเครื่องควบคุม',
      gnssSettingList: 'รายการการตั้งค่า Ntrip',
      gnssSettingCreate: 'สร้างการตั้งค่า Ntrip',
      gnssSettingAdminCreate: 'สร้างการตั้งค่า Ntrip',
      gnssSettingOwnerCreate: 'สร้างการตั้งค่า Ntrip',
      gnssSettingEdit: 'แก้ไขการตั้งค่า Ntrip',
      gnssSettingAdminEdit: 'แก้ไขการตั้งค่า Ntrip',
      gnssSettingOwnerEdit: 'แก้ไขการตั้งค่า Ntrip',
      approvalList: 'รายการเครื่องจักร',
      retrofitRentalEdit: 'รายละเอียดการมอบหมาย',
      retrofitTransferEdit: 'รายละเอียดการถ่ายโอน',
      asbuiltsCreate: 'ดึงข้อมูลผลการก่อสร้าง',
      asbuiltsShow: 'ประวัติแบบบันทึกการก่อสร้าง',
      retrofitConfigList: 'ข้อมูลการกำหนดค่า',
      retrofitKitInfoList: 'ข้อมูลเครื่องควบคุม',
      retrofitAccuraciesList: 'ผลการตรวจสอบความแม่นยำ',
      retrofitAccuraciesShow: 'รายละเอียด',
      retrofitCalibaList: 'ข้อมูลการสอบเทียบ',
      retrofitCalibInfo: 'รายละเอียดข้อมูลการสอบเทียบเครื่อง',
      retrofitBasicSettingList: 'ข้อมูลการตั้งค่าพื้นฐาน',
      retrofitErrorList: 'ข้อมูลข้อผิดพลาด',
      retrofitErrorShow: 'รายละเอียด',
      retrofitTopographicSurveyList: 'รายการการสำรวจภูมิประเทศ',
      retrofitTopographicSurveyShow: 'รายละเอียดการสำรวจภูมิประเทศ',
      retrofitShareFileList: 'บันทึกแอปพลิเคชัน',
      retrofitCorporationLicenseList: 'รายการใบอนุญาต',
      retrofitCorporationLicenseEdit: 'รายละเอียดใบอนุญาต',
      CompactionLayerList: 'เลเยอร์การบดอัด',
      userNoticeList: 'รายการการแจ้งเตือน',
      userNoticeCreate: 'เพิ่มการแจ้งเตือน',
      userNoticeEdit: 'อัปเดตการแจ้งเตือน',
      userNoticeDrawer: 'การแจ้งเตือน',
      retrofitAlternateRegists: 'รายการการลงทะเบียนพร็อกซี่เครื่องควบคุม',
      firmwareList: 'รายการเฟิร์มแวร์',
      firmwareShow: 'รายละเอียดเฟิร์มแวร์',
      firmwareCreate: 'เพิ่มเฟิร์มแวร์',
      retrofitManage: 'ข้อความ',
      retrofitNamePlateFiles: 'รูปภาพ',
      retrofitManageAdminBulkAction: 'การลงทะเบียนชุด',
      retrofitManageAdminBulkActionCreate: 'การลงทะเบียนชุด',
      retrofitAlternateRegistCreate: 'การลงทะเบียนพร็อกซี่เครื่องควบคุม',
      retrofitAlternateRegistEdit: 'รายเอียดการลงทะเบียนพร็อกซี่เครื่องควบคุม',
      retrofitAlternateRegistCreateText: 'ข้อความ',
      retrofitAlternateRegistCreatePhoto: 'รูปภาพ',
      ntripList: 'ข้อมูล Ntrip',
      ntripEdit: 'การบริหารข้อมูล Ntrip',
    },
    label: {
      login: 'ลงชื่อเข้าใช้',
      selected: 'เลือก (%{length} กรณี)',
      listNoResults: 'ไม่พบข้อมูล',
      maintenance: {
        title: 'กำลังซ่อมบำรุง',
        message: `เราระงับการให้บริการชั่วคราวเพื่อดำเนินการซ่อมบำรุง
      ขออภัยสำหรับความไม่สะดวก โปรดรอสักครู่`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder: 'วางหรือเลือกไฟล์ (%{fileTypes}) ที่จะอัปโหลด',
        maxFileNum:
          'จำนวนเครื่องควบคุมที่สามารถลงทะเบียนได้สูงสุดคือ 5 เครื่อง',
      },
      nosetting: 'ไม่มีการตั้งค่า',
      siteRetrofitList: {
        remoteSupport: 'การสนับสนุนทางไกล',
      },
      retrofitEdit: {
        breadcrumbs: 'รายละเอียด',
        belongsDetails: '',
        ntripSettingInfo: 'ข้อมูลการตั้งค่า Ntrip',
        machineryDetails: 'เครื่องจักร',
        retrofitDetails: 'เครื่องควบคุม',
        basicInfo: 'ข้อมูลพื้นฐาน',
        controller: 'เครื่องควบคุม',
        reciverMain: 'ตัวรับ GNSS หลัก',
        reciverSub: 'ตัวรับ GNSS รอง',
        retrofitstatus: 'สถานะเครื่องควบคุม',
        license: 'ใบอนุญาต',
        licenseId: 'ID ใบอนุญาต',
        expiredDate: 'วันที่หมดอายุ ',
      },
      retrofitRental: {
        rentalDetails: 'รายละเอียดการมอบหมาย',
        rentalInfo: 'รายละเอียดการมอบหมาย',
        lending: 'การมอบหมาย',
        return: 'คืน',
        lendingDestination: 'บริษัทที่มอบหมาย',
        lendingCompany: 'ชื่อบริษัท',
        lendingEmail: 'อีเมล',
        companyName: 'ชื่อบริษัท',
      },
      retorfitTransfer: {
        tab: 'การถ่ายโอน',
        transferInfo: 'รายละเอียดการถ่ายโอน',
        transferButton: 'การถ่ายโอน',
        transferEditButton: 'แก้ไขการถ่ายโอน',
      },
      siteConfigureEdit: {
        onButton: 'ON',
        offButton: 'OFF',
      },
      approvals: {
        rentalTab: 'การมอบหมาย',
        transferTab: 'การถ่ายโอน',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'ID บริษัทที่ใช้งาน',
        corporationName: 'บริษัทที่ใช้งาน',
      },
      buckets: {
        bucketTab: 'บุ้งกี๋',
        extensionarmTab: 'แขนต่อ',
        extensionarmFile: 'ไฟล์แขนต่อ',
        shareTo: 'แชร์ให้',
      },
      bucketRental: {
        corporation: 'แชร์ให้',
        bucketType: 'ประเภท',
        bk3File: 'ไฟล์ BK3',
      },
      retrofitBasic: {
        basicInfoTab: 'ข้อมูลพื้นฐาน',
        bulkActionTab: 'การลงทะเบียนชุด',
        ntripTab: 'การบริหารข้อมูล Ntrip',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'การอัปเดตแบบชุด',
        retrofitDistributor: 'ผู้แทนจำหน่าย',
        retrofitRegion: 'ภูมิภาค',
        corporationId: 'ID บริษัท',
        corporationName: 'ชื่อบริษัท',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'ส่งกลับ',
        initialize: 'การตั้งค่าเริ่มต้น',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'ชื่อบริษัท',
      },
      terms: {
        title: 'ข้อกำหนดในการใช้งาน',
        message: 'โปรดคลิกลิงก์ด้านล่างเพื่อทบทวนข้อกำหนดในการใช้งาน',
        confirmMessage1:
          'ก่อนใช้งาน Smart Construction Pilot คุณต้องตกลงยอมรับเอกสารดังต่อไปนี้',
        confirmMessage2:
          'หากไม่ยอมรับ คุณจะไม่สามารถใช้งาน Smart Construction Pilot ได้',
        select: 'โปรดเลือกภูมิภาคของคุณ',
      },
      downloads: {
        title: 'Project File Converter',
        message:
          'เป็นแอปสแตนด์อโลนที่สามารถสร้างไฟล์โครงการจากไฟล์การออกแบบได้',
        button: 'ดาวน์โหลด',
      },
      retrofitBasicInfoCreate: {
        uuid: 'การกำหนดตัวเลขอัตโนมัติ',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'ดาวน์โหลด',
        resultFileDownload: 'ดาวน์โหลด',
      },
      tasks: {
        coordinate: 'พิกัด (หน่วย:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'ขนาดบล็อก (%{unit})',
      },
      compactionLayers: {
        thickness: 'ความหนา (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'รายละเอียด',
        rentalDetail: 'รายละเอียดการมอบหมาย',
      },
      pendingTransfers: {
        breadcrumbs: 'รายละเอียด',
        transferDetail: 'รายละเอียดการถ่ายโอน',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'รายละเอียด',
        alternateRegistDetail: 'รายเอียดการลงทะเบียนพร็อกซี่เครื่องควบคุม',
      },
      supportMode: {
        retrofit: 'เครื่องจักร',
        optional: 'เสริม',
      },
      retrofitList: {
        assignment: 'การมอบหมาย',
        license: 'ใบอนุญาต',
      },
      userNotices: {
        languageDetails: 'เนื้อหาการแจ้งเตือน',
        language: 'ภาษา',
        languageEn: 'ภาษาอังกฤษ',
        languageJa: 'ภาษาญี่ปุ่น',
        languageFr: 'ภาษาฝรั่งเศส',
        languageDe: 'ภาษาเยอรมัน',
        languageEs: 'ภาษาสเปน',
        Information: 'การแจ้งเตือน',
        Maintenance: 'การซ่อมบำรุง',
        emergency: 'ฉุกเฉิน',
        important: 'สำคัญ',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'การลงทะเบียนเครื่องควบคุม',
        corporationName: 'ชื่อบริษัท',
        basicInfoSerialNumber: 'หมายเลขซีเรียล',
        confirmInput: 'กรอกหมายเลขซีเรียลอีกครั้ง',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'หมายเลขซีเรียล',
        status: 'สถานะ',
        imageFile: 'ภาพ',
        message1:
          'โปรดถ่ายภาพหมายเลขซีเรียลเครื่องควบคุมหรือคิวอาร์โค้ดให้ชัดเจนและไม่มีภาพเบลอ',
        message2: 'ภาพต้องมีขนาดต่ำกว่า %{mbyte}MB',
        errormessage:
          'จำนวนเครื่องควบคุมที่สามารถลงทะเบียนได้สูงสุดคือ 5 เครื่อง',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'ถูกต้อง',
          disable: 'ไม่ถูกต้อง',
          unLicense: 'ไม่ได้จัดสรร',
        },
        machine: 'เครื่องจักร',
        before: 'ก่อน',
        after: 'หลัง',
      },
      geofences: {
        project: 'โครงการ',
        projectSourceLayer: 'เลเยอร์',
        rgb: 'สี (RGB)',
        select: 'การเลือกรั้วภูมิศาสตร์',
        coordinates: 'พิกัด',
        coordinatesLabel: 'พิกัด (N,E)',
        unitLength: 'หน่วย:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'พิกัด (หน่วย:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'รัศมี',
      },
      retrofitAlternateRegists: {
        menuTitle: 'การลงทะเบียนพร็อกซี่เครื่องควบคุม',
        corporationName: 'ชื่อบริษัท',
        email: 'อีเมล',
        basicInfoSerialNumber: 'หมายเลขซีเรียล',
        confirmInput: 'กรอกหมายเลขซีเรียลอีกครั้ง',
      },
      ntripList: {
        unusedTotal: 'ทั้งหมดที่ไม่ใช้',
      },
      calibrationDetail: {
        breadcrumbs: 'รายละเอียด',
        machineInfo: 'ข้อมูลเครื่อง',
        machineGeometryInfo: 'ข้อมูลรูปร่างเครื่องจักร',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'ข้อมูลการติดตั้ง IMU',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'ข้อมูลการติดตั้ง GNSS หลัก',
        bodyCenterToGnssSub: 'ข้อมูลการติดตั้ง GNSS รอง',
        extendedModel: 'โมเดลแบบขยาย',
        measuredValues: 'ข้อมูลการสอบเทียบ',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'ข้อผิดพลาดการแปลงข้อมูล',
        system_localization_quantity_error: `ไม่สามารถลงทะเบียนไฟล์ที่มีข้อมูลการปรับตามปลายทางหลายไฟล์พร้อมกันได้ โปรดลงทะเบียนไฟล์ที่มีการปรับตามปลายทางเพียงไฟล์เดียว`,
        system_detect_0byte_file_error: `การลงทะเบียนไฟล์ไปเซิร์ฟเวอร์ล้มเหลวเนื่องจากมีข้อผิดพลาดในการสื่อสาร โปรดลงทะเบียนไฟล์อีกครั้ง`,
        system_batch_error: 'ข้อผิดพลาดระบบ (P001)',
        system_parameter_error: 'ข้อผิดพลาดระบบ (P002)',
        system_parameterfile_notfound: 'ข้อผิดพลาดระบบ (P003)',
        system_exception: 'ข้อผิดพลาดระบบ (P004)',
        system_logfile_error: 'ข้อผิดพลาดระบบ (P005)',
        system_parameterfile_parameter_error: 'ข้อผิดพลาดระบบ (P006)',
        desing_localization_error: `ไม่มีข้อมูลการปรับตามปลายทางที่พร้อมใช้งาน
        โปรดลงทะเบียนไฟล์ใดไฟล์หนึ่งต่อไปนี้:
        
        ・ไฟล์ GC3
        ・ไฟล์ TP3 ที่มีจุดควบคุม
        ・ไฟล์ CAL`,
        desing_no_support_dxf_error:
          'ไม่สามารถอ่านไฟล์ DXF ได้เนื่องจากเป็นเวอร์ชันเก่ากว่า AutoCAD2000',
        desing_tile_error: 'สร้างไฟล์โครงการล้มเหลว',
        desing_outline_error: 'การสร้างเส้นขอบเขตภายนอกล้มเหลว',
        desing_area_length_error:
          'ยกเลิกกระบวนการเนื่องจากพื้นที่โครงการใหญ่เกินไป โปรดตรวจสอบว่าข้อมูลการออกแบบและขนาดของข้อมูล CAD ถูกต้อง',
        desing_layer_name_duplication_error: `ข้อมูลโครงการที่มีชื่อเลเยอร์ซ้ำจะไม่สามารถลงทะเบียนได้
        โปรดเปลี่ยนชื่อเลเยอร์การออกแบบหรือลงทะเบียนในลักษณะที่ไม่มีไฟล์ที่มีชื่อเลเยอร์การออกแบบซ้ำกัน`,
        targetFiles: '[ชื่อไฟล์]',
        targetLayers: '[เลเยอร์]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'ไม่มี',
        system_batch_error: 'ข้อผิดพลาดระบบ (T001)',
        system_parameter_error: 'ข้อผิดพลาดระบบ (T002)',
        system_parameterfile_notfound: 'ข้อผิดพลาดระบบ (T003)',
        system_exception: 'ข้อผิดพลาดระบบ (T004)',
        system_logfile_error: 'ข้อผิดพลาดระบบ (T005)',
        system_basefile_notfound: 'ข้อผิดพลาดระบบ (T006)',
        system_prjfile_multi: 'ข้อผิดพลาดระบบ (T007)',
        system_basefile_read_error: 'ข้อผิดพลาดระบบ (T008)',
        system_target_layer_notfound: 'ข้อผิดพลาดระบบ (T009)',
        system_target_layer_duplication: 'มีชื่อเลเยอร์นี้อยู่แล้ว',
        system_taskarea_error: 'พื้นที่งานไม่ถูกต้อง',
        system_taskarea_design_notfound:
          'ไม่มีข้อมูลสำหรับไฟล์โครงการภายในพื้นที่งาน (TIN เส้น จุด)',
        system_tile_error: 'สร้างไฟล์โครงการล้มเหลว',
        system_outline_error: 'การสร้างเส้นขอบเขตภายนอกล้มเหลว',
        system_parameterfile_error: 'ข้อผิดพลาดระบบ',
      },
      retrofitError: {
        1000: 'ไม่พบข้อมูล UDP จากเครื่องควบคุม',
        2000: 'ไม่พบ IMU ตัวเครื่อง',
        2001: 'ข้อผิดพลาดในฮาร์ดแวร์ IMU ตัวเครื่อง',
        2002: 'ข้อผิดพลาดในอัลกอริทึม IMU ตัวเครื่อง',
        2003: 'ข้อผิดพลาดในข้อมูล IMU ตัวเครื่อง',
        2004: 'ข้อผิดพลาดในการเริ่มต้น IMU ตัวเครื่อง',
        2100: 'ไม่พบ IMU บูม',
        2101: 'ข้อผิดพลาดในฮาร์ดแวร์ IMU บูม',
        2102: 'ข้อผิดพลาดในอัลกอริทึม IMU บูม',
        2103: 'ข้อผิดพลาดในข้อมูล IMU บูม',
        2104: 'ข้อผิดพลาดในการเริ่มต้น IMU บูม',
        2200: 'ไม่พบ IMU แขน',
        2201: 'ข้อผิดพลาดในฮาร์ดแวร์ IMU แขน',
        2202: 'ข้อผิดพลาดในอัลกอริทึม IMU แขน',
        2203: 'ข้อผิดพลาดในข้อมูล IMU แขน',
        2204: 'ข้อผิดพลาดในการเริ่มต้น IMU แขน',
        2300: 'ไม่พบ IMU บุ้งกี๋',
        2301: 'ข้อผิดพลาดในฮาร์ดแวร์ IMU บุ้งกี๋',
        2302: 'ข้อผิดพลาดในอัลกอริทึม IMU บุ้งกี๋',
        2303: 'ข้อผิดพลาดในข้อมูล IMU บุ้งกี๋',
        2304: 'ข้อผิดพลาดในการเริ่มต้น IMU บุ้งกี๋',
        2400: 'ไม่พบ IMU บุ้งกี๋เอียง',
        2401: 'ข้อผิดพลาดในฮาร์ดแวร์ IMU บุ้งกี๋เอียง',
        2402: 'ข้อผิดพลาดในอัลกอริทึม IMU บุ้งกี๋เอียง',
        2403: 'ข้อผิดพลาดในข้อมูล IMU บุ้งกี๋เอียง',
        2404: 'ข้อผิดพลาดในการเริ่มต้น IMU บุ้งกี๋เอียง',
        2500: 'ไม่พบ IMU บูมที่ 2',
        2501: 'ข้อผิดพลาดในฮาร์ดแวร์ IMU บูมที่ 2',
        2502: 'ข้อผิดพลาดในอัลกอริทึม IMU บูมที่ 2',
        2503: 'ข้อผิดพลาดในข้อมูล IMU บูมที่ 2',
        2504: 'ข้อผิดพลาดในการเริ่มต้น IMU บูมที่ 2',
        2600: 'ไม่พบเซ็นเซอร์สวิง',
        2601: 'ข้อผิดพลาดในระบบเซ็นเซอร์สวิง',
        2602: 'ข้อผิดพลาดในฮาร์ดแวร์เซ็นเซอร์สวิง',
        2700: 'ไม่พบเซ็นเซอร์ออฟเซ็ต',
        2701: 'ข้อผิดพลาดในระบบเซ็นเซอร์ออฟเซ็ต',
        2702: 'ข้อผิดพลาดในฮาร์ดแวร์เซ็นเซอร์ออฟเซ็ต',
        3000: 'ไม่พบเซ็นเซอร์วัดแรงดันส่วนหัว',
        3100: 'ไม่พบเซ็นเซอร์วัดแรงดันส่วนล่าง',
        4000: 'ไม่พบเสาอากาศ GNSS',
        5000: 'ไม่พบข้อมูลปรับแก้',
        5001: 'ไม่พบสัญญาณเครื่องรับวิทยุ',
      },
    },
    dialog: {
      menu: {
        confirmManualLink: 'ระบบจะนำคุณไปเว็บเซ็ตอื่น ตกลงหรือไม่',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'บุ้งกี๋',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'ต้องใช้ ID บริษัทและชื่อบริษัท',
        },
        save: {
          message1: 'ไม่สามารถเปลี่ยน ID บริษัท/ชื่อบริษัทได้เนื่องจากมีข้อมูล',
          message2: 'เครื่องจักรที่มอบหมายแก่บริษัทอื่น',
          message3: '',
          message4: 'โปรดตรวจสอบหมายเลขซีเรียลด้านล่าง',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'เครื่องควบคุม',
        },
        undeletable: {
          message: 'กำลังใช้งานเครื่องควบคุม ไม่สามารถลบได้',
        },
      },
      Ntrip: {
        save: {
          stop: 'ต้องการหยุดการตั้งค่า Ntrip นี้หรือไม่',
          delete: 'ต้องการลบการตั้งค่า Ntrip นี้หรือไม่',
        },
      },
      siteRetrofitList: {
        title: 'ข้อมูลเสริม',
        message: 'หมดเวลาการก่อสร้าง',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'การตั้งค่าทั่วไป',
          siteSetting: 'การตั้งค่าไซต์งาน',
        },
        mapBackgroundColor: 'สีพื้นหลังแผนที่',
        message:
          'การตรวจจับการสัมผัสรั้วภูมิศาสตร์/ฟังก์ชันแจ้งเตือนอาจทำงานผิดปกติโดยขึ้นอยู่กับสภาพแวดล้อมและสภาวะขณะใช้งาน ใช้งานฟังก์ชันนี้ด้วยความระมัดระวัง โดยตรวจสอบให้แน่ใจว่าตนเองเข้าใจฟังก์ชันและสภาวะในการใช้งาน',
      },
      geofenceList: {
        confirmDelete: {
          message: 'ต้องการลบรั้วภูมิศาสตร์นี้หรือไม่',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1: 'เครื่องนี้กำลังเชื่อมโยงกับไซต์งาน ไม่สามารถมอบหมายได้',
          message2: 'โปรดถอดเครื่องนี้ออกจากไซต์งาน',
        },
        sameOwner: {
          message:
            'ไม่สามารถมอบหมายเครื่องนี้ได้เนื่องจากบริษัทของคุณเป็นเจ้าของ',
        },
        unableTransfer: {
          message:
            'เครื่องกำลังรอการอนุมัติการถ่ายโอน ไม่สามารถลงทะเบียนการมอบหมายได้',
        },
        unableAlternateRegist: {
          message:
            'เครื่องกำลังรอการอนุมัติการลงทะเบียนพร็อกซี่เครื่องควบคุม ไม่สามารถลงทะเบียนการมอบหมายได้',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'เครื่องนี้กำลังเชื่อมโยงกับไซต์งาน ไม่สามารถถ่ายโอนได้ โปรดถอดเครื่องนี้ออกจากไซต์งาน',
        messageRentaled: 'เครื่องนี้ถูกมอบหมายแล้ว ไม่สามารถถ่ายโอนได้',
        messageLicense: 'เครื่องนี้มีใบอนุญาต ไม่สามารถถ่ายโอนได้',
        pendingApprovals: {
          message1: 'ได้รับคำร้องขอการถ่ายโอน โปรดรอการอนุมัติ',
          message2: 'หากมีคำถามใด ๆ โปรดติดต่อเราผ่าน URL ด้านล่าง',
        },
      },
      retrofitSendBacks: {
        submit:
          'ต้องการส่งคืนเครื่องควบคุมและคัดลอกการตั้งค่าไปยังเครื่องควบคุมเป้าหมายหรือไม่',
        deleteNtrip:
          'ต้องการเปลี่ยนเครื่องควบคุมที่ส่งคืนให้เป็นค่าเริ่มต้นหรือไม่',
        unableRegion:
          'ส่งเครื่องควบคุมนี้ไปต่างประเทศแล้ว หากเครื่องควบคุมนี้ถูกต้องแล้ว โปรดรีเซ็ต “ภูมิภาค” ให้เป็น ไม่มี',
        unableCorporation: {
          message1: 'ไม่สามารถส่งคืนเครื่องควบคุมได้',
          message2: 'หากคิดว่าเป็นข้อผิดพลาด โปรดติดต่อผู้จำหน่ายของคุณ',
        },
        retrofitRentaled: 'เครื่องควบคุมถูกมอบหมายแล้ว ไม่สามารถส่งคืนได้',
        tiedToSite: 'เครื่องนี้กำลังเชื่อมโยงกับไซต์งาน ไม่สามารถส่งคืนได้',
      },
      retrofitAddToSiteAction: {
        title: 'ข้อมูลเสริม',
        message:
          'มีเครื่องที่กำลังรอการอนุมัติ ทำให้ไม่สามารถเชื่อมโยงกับไซต์งานได้',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'ข้อมูลเสริม',
        message: 'กำลังถ่ายโอนเครื่อง ไม่สามารถเชื่อมโยงกับไซต์งานได้',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message: 'กำลังส่งการตั้งค่า Ntrip ที่เลือกไปยังเครื่องอื่น',
        },
        unableTransferInfo: {
          message: 'สำหรับการถ่ายโอน โปรดยกเลิกการตั้งค่าดังต่อไปนี้',
          reasonSiteId: 'ไซต์งาน',
          reasonRentaled: 'การมอบหมาย',
          reasonLicense: 'ใบอนุญาต',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'บริษัทที่ได้รับมอบหมายกำลังมอบหมายเครื่องให้บริษัทอื่น วันที่เริ่มต้นการมอบหมายแก่บริษัทอื่นคือวันที่ %{date}',
          message2: 'โปรดเลือกวันที่สิ้นสุดหลังวันที่ %{date}',
        },
        confirmTieUpJobsite: {
          message:
            'เครื่องนี้เชื่อมโยงกับไซต์งานในบริษัทที่มอบหมาย ต้องการปิดการมอบหมายนี้หรือไม่',
        },
        confirmDoubleRental: {
          message:
            'บริษัทที่ได้รับมอบหมายกำลังมอบหมายเครื่องให้บริษัทอื่น \nบริษัทที่ได้รับมอบหมายกำลังมอบหมายเครื่องให้บริษัทอื่น การตั้งวันที่สิ้นสุดจะเป็นการปิดการมอบหมายให้บริษัทอื่นไปด้วย ต้องการตั้งค่าวันที่สิ้นสุดหรือไม่',
        },
        unapprovedSave: {
          message: 'การมอบหมายนี้ยังไม่ได้รับการอนุมัติ ไม่สามารถบันทึกได้',
        },
        unapprovedDelete: {
          title: '',
          message: 'ต้องการลบการมอบหมายนี้หรือไม่',
        },
        approvedDelete: {
          title: '',
          message: 'การมอบหมายนี้ได้รับการอนุมัติแล้ว ไม่สามารถลบได้',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message: 'เครื่องนี้กำลังทำงานที่ได้รับมอบหมาย ไม่สามารถถ่ายโอนได้',
        },
        approvedSave: {
          message: 'เครื่องนี้มีใบอนุญาต ไม่สามารถถ่ายโอนได้',
        },
        unapprovedDelete: {
          title: '',
          message: 'ต้องการการถ่ายโอนนี้หรือไม่',
        },
        approvedDelete: {
          title: '',
          message: 'การถ่ายโอนนี้ได้รับการอนุมัติแล้ว ไม่สามารถลบได้',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'แขนต่อ',
        },
      },
      projectFileUpload: {
        message:
          'ไม่สามารถลงทะเบียนไฟล์จุดควบคุม (.gc3/.cal) ได้มากกว่าหนึ่งไฟล์',
      },
      projectEdit: {
        confirm: {
          message:
            'หลังอัปเดตเสร็จแล้ว โครงการจะลบงานที่มีอยู่ ต้องการอัปเดตหรือไม่',
        },
      },
      projectConversionStatus: {
        title: 'สถานะการแปลง',
        button: 'ตกลง',
      },
      taskConversionStatus: {
        title: 'สถานะการแปลง',
        button: 'ตกลง',
      },
      taskList: {
        undeletable: {
          message: 'ไม่สามารถลบงานที่เลือกได้เนื่องจากกำลังใช้งาน',
        },
        unableToCreate: {
          message: 'ไม่มีข้อมูลการออกแบบที่ลงทะเบียนในโครงการนี้',
        },
        unableToDelete: {
          message1: 'ไม่สามารถลบงานนี้ได้เนื่องจากโครงการและงานมีสถานะดังนี้',
          message2: 'โครงการ: กำลังแปลง',
          message3: 'งาน   : ข้อผิดพลาด',
        },
        unableToUpdate: {
          message1:
            'ไม่สามารถแก้ไขงานนี้ได้เนื่องจากโครงการและงานไม่ได้มีสถานะดังนี้',
          message2: 'โครงการ: [แปลงแล้ว]',
          message3: 'งาน   : [กำลังถ่ายโอน][ถ่ายโอนแล้ว][ถ่ายโอนล้มเหลว]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'งาน',
        },
        unableToDelete: {
          message: 'ไม่สามารถลบงานที่เลือกได้เนื่องจากกำลังใช้งาน',
        },
      },
      taskMap: {
        crossingMessage: 'พื้นที่งานคาบเกี่ยวกัน',
        sameValueMessage: 'กรอกพิกัดซ้ำ',
        areaZeroMessage: 'ไม่มีพื้นที่สำหรับตั้งพื้นที่งาน',
        noPolygonMessage: 'ไม่สามารถลงทะเบียนงานได้เนื่องจากยังไม่ได้ลงทะเบียน',
      },
      compactionMaterials: {
        add: {
          title: 'เพิ่มวัสดุ',
        },
        update: {
          title: 'อัปเดตวัสดุ',
        },
      },
      weathers: {
        add: {
          title: 'เพิ่มสภาพอากาศ',
        },
        update: {
          title: 'อัปเดตสภาพอากาศ',
        },
      },
      operators: {
        add: {
          title: 'เพิ่มผู้ปฏิบัติงาน',
        },
        update: {
          title: 'อัปเดตผู้ปฏิบัติงาน',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'แก้ไขพื้นที่งานบดอัด',
        },
        undeletable: {
          message:
            'เลเยอร์การบดอัดที่อยู่ในพื้นที่ก่อสร้างกำลังถูกใช้งาน ไม่สามารถลบได้',
        },
      },
      compactionLayers: {
        undeletable: {
          message: 'เลเยอร์การบดอัดกำลังถูกใช้งาน ไม่สามารถลบได้',
        },
        deleteConfirm: {
          messageTargetItem: 'เลเยอร์การบดอัด',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message: 'กำลังส่งการตั้งค่า Ntrip อื่นไปยังเครื่องที่เลือก',
        },
        confirmDelete: {
          message: 'ต้องการลบการตั้งค่า Ntrip นี้หรือไม่',
        },
        confirmDeleteWithMachine: {
          message: 'กำลังเชื่อมโยงเครื่อง ต้องการลบการตั้งค่า Ntrip นี้หรือไม่',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'การแจ้งเตือน',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'ตั้งใบอนุญาตสำหรับเครื่องต่อไปนี้',
        },
        changeDisable: {
          message: 'โปรดเปลี่ยน ID ใบอนุญาตของเครื่องก่อสร้างให้ถูกต้อง',
        },
        changeTemporary: {
          message:
            'ตั้งใบอนุญาตสำหรับเครื่องต่อไปนี้ ใบอนุญาตชั่วคราวที่ตั้งไว้ปัจจุบันจะไม่สามารถใช้งานได้',
        },
        errorAdd: {
          message:
            'ไม่สามารถเปลี่ยนใบอนุญาตของเครื่องได้เนื่องจากตั้งใบอนุญาตแบบ All แล้ว',
        },
        errorSameType: {
          message:
            'ไม่สามารถเปลี่ยนใบอนุญาตของเครื่องได้เนื่องจากตั้งใบอนุญาตประเภทเดียวกันแล้ว',
        },
        errorUnlimited: {
          message:
            'เครื่องตั้งใบอนุญาตแบบ Unlimited แล้ว ไม่สามารถตั้งใบอนุญาตอื่นได้',
        },
        addAll: {
          message:
            'เครื่องต่อไปนี้ตั้งใบอนุญาตแล้ว ต้องการตั้งค่าใบอนุญาตแบบ All หรือไม่',
        },
        compare: {
          message: 'เปลี่ยนใบอนุญาตสำหรับเครื่องต่อไปนี้',
        },
        warning: {
          message: '%{retrofit} มีใบอนุญาตของ %{id} ต้องการถอดใบอนุญาตหรือไม่',
        },
        unableTransfer: {
          message: 'เครื่องกำลังถูกถ่ายโอน ทำให้ไม่สามารถเชื่อมโยงใบอนุญาตได้',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: 'ข้อผิดพลาดระบบ มีข้อมูลที่วิเคราะห์ภาพล้มเหลว',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1: 'ได้รับคำร้องขอการลงทะเบียน โปรดรอจนกว่าจะได้รับการอนุมัติ',
          message2: 'หากมีคำถามใด ๆ โปรดติดต่อเราผ่าน URL ด้านล่าง',
        },
        corporationIdCheck: {
          message1:
            'เครื่องควบคุมลงทะเบียนไปแล้ว โปรดตรวจสอบโดยการค้นหาในเครื่อง',
          message2:
            'หากไม่พบเครื่องควบคุมเป้าหมายในรายการ โปรดติดต่อเราผ่าน URL ด้านล่าง',
        },
        earthbrainIdCheck: {
          message1: 'เราขออภัยในความไม่สะดวก',
          message2: 'โปรดติดต่อเราผ่าน URL ด้านล่างพร้อมข้อมูลต่อไปนี้',
          message3: '- หมายเลขซีเรียลเครื่องควบคุม',
          message4: '- ชื่อเครื่องที่จะลงทะเบียน (ไม่จำเป็น)',
          message5: '- ID บริษัทที่จะลงทะเบียน',
        },
        invalidSerialNumber: {
          message1: 'เครื่องควบคุมถูกใช้โดยบริษัทอื่นแล้ว',
          message2: 'หากต้องการเปลี่ยนเพื่อวัตถุประสงค์อื่น ',
          message3: 'โปรดติดต่อเราผ่าน URL ด้านล่างพร้อมข้อมูลต่อไปนี้',
          message4: '- หมายเลขซีเรียลเครื่องควบคุม',
          message5: '- ชื่อเครื่องที่จะลงทะเบียน (ไม่จำเป็น)',
          message6: '- ID บริษัทที่จะลงทะเบียน',
          message7: '- เหตุผลของการเปลี่ยนแปลง',
        },
        lendingRetrofits: {
          message1: 'มอบหมายเครื่องควบคุมแล้ว',
          message2: 'โปรดดำเนินการใหม่หลังสิ้นสุดการมอบหมายจากบริษัทผู้มอบหมาย',
          message3: 'หากมีคำถามใด ๆ โปรดติดต่อเราผ่าน URL ด้านล่าง',
        },
        siteAttached: {
          message1: 'เครื่องควบคุมกำลังเชื่อมโยงกับไซต์งาน',
          message2: 'โปรดทำใหม่หลังถอดเครื่องออกจากไซต์งาน',
          message3: 'หากมีคำถามใด ๆ โปรดติดต่อเราผ่าน URL ด้านล่าง',
        },
        serialNumberExistError: {
          message1:
            'หมายเลขนี้เป็นหมายเลขซีเรียลของเครื่องควบคุมที่ไม่อยู่ในระบบ โปรดตรวจสอบให้แน่ใจว่ากรอกหมายเลขซีเรียลเครื่องควบคุมถูกต้อง',
          message2: 'หากยังคงมีปัญหา โปรดติดต่อเราผ่าน URL ด้านล่าง',
        },
        ntripShortage: {
          message1: 'เราไม่สามารถออก ID ผู้ใช้และรหัสผ่าน Ntrip ของคุณได้',
          message2: 'โปรดติดต่อเราทาง URL ด้านล่าง',
        },
        serialnumberCheck: {
          message: 'โปรดตรวจสอบผลิตภัณฑ์ที่มีหมายเลขซีเรียลดังต่อไปนี้',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'ต้องใช้อีเมล',
        },
        pendingApprovals: {
          message1:
            'ได้รับคำร้องขอการลงทะเบียนแล้ว โปรดรอจนกว่าจะได้รับการอนุมัติ',
          message2: 'หากมีคำถามใด ๆ โปรดติดต่อเราผ่าน URL ด้านล่าง',
        },
        corporationIdCheck: {
          message1:
            'เครื่องควบคุมลงทะเบียนไปแล้ว โปรดตรวจสอบโดยการค้นหาในเครื่อง',
          message2:
            'หากไม่พบเครื่องควบคุมเป้าหมายในรายการ โปรดติดต่อเราผ่าน URL ด้านล่าง',
        },
        earthbrainIdCheck: {
          message1: 'เราขออภัยในความไม่สะดวก',
          message2: 'โปรดติดต่อเราผ่าน URL ด้านล่างพร้อมข้อมูลต่อไปนี้',
          message3: '- หมายเลขซีเรียลเครื่องควบคุม',
          message4: '- ชื่อเครื่องที่จะลงทะเบียน (ไม่จำเป็น)',
          message5: '- ID บริษัทที่จะลงทะเบียน',
        },
        invalidSerialNumber: {
          message1: 'เครื่องควบคุมถูกใช้โดยบริษัทอื่นแล้ว',
          message2: 'หากต้องการเปลี่ยนเพื่อวัตถุประสงค์อื่น ',
          message3: 'โปรดติดต่อเราผ่าน URL ด้านล่างพร้อมข้อมูลต่อไปนี้',
          message4: '- หมายเลขซีเรียลเครื่องควบคุม',
          message5: '- ชื่อเครื่องที่จะลงทะเบียน (ไม่จำเป็น)',
          message6: '- ID บริษัทที่จะลงทะเบียน',
          message7: '- เหตุผลของการเปลี่ยนแปลง',
        },
        lendingRetrofits: {
          message1: 'มอบหมายเครื่องควบคุมแล้ว',
          message2: 'โปรดดำเนินการใหม่หลังสิ้นสุดการมอบหมายจากบริษัทผู้มอบหมาย',
          message3: 'หากมีคำถามใด ๆ โปรดติดต่อเราผ่าน URL ด้านล่าง',
        },
        siteAttached: {
          message1: 'เครื่องควบคุมกำลังเชื่อมโยงกับไซต์งาน',
          message2: 'โปรดทำใหม่หลังถอดเครื่องออกจากไซต์งาน',
          message3: 'หากมีคำถามใด ๆ โปรดติดต่อเราผ่าน URL ด้านล่าง',
        },
        serialNumberExistError: {
          message1:
            'หมายเลขนี้เป็นหมายเลขซีเรียลของเครื่องควบคุมที่ไม่อยู่ในระบบ โปรดตรวจสอบให้แน่ใจว่ากรอกหมายเลขซีเรียลเครื่องควบคุมถูกต้อง',
          message2: 'หากยังคงมีปัญหา โปรดติดต่อเราผ่าน URL ด้านล่าง',
        },
        ntripShortage: {
          message1: 'เราไม่สามารถออก ID ผู้ใช้และรหัสผ่าน Ntrip ของคุณได้',
          message2: 'โปรดติดต่อเราทาง URL ด้านล่าง',
        },
        serialnumberCheck: {
          message: 'โปรดตรวจสอบผลิตภัณฑ์ที่มีหมายเลขซีเรียลดังต่อไปนี้',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'เครื่องควบคุมเป็นของบริษัทของคุณอยู่แล้ว',
          message2: 'โปรดติดต่อบริษัทที่สมัคร',
        },
        userCorporation: {
          message1:
            'ไม่สามารถอนุมัติคำร้องขอนี้ได้เนื่องจากคุณได้ให้ยืมเครื่องควบคุมนี้ไปแล้ว',
          message2: 'โปรดติดต่อบริษัทที่สมัคร',
        },
      },
      licensePurchaseNotice: {
        button: 'ตกลง',
        message1: `มีเครื่องที่กำลังใช้ในไซต์งานโดยไม่มีใบอนุญาต
      ต้องใช้ใบอนุญาตสำหรับการใช้ในไซต์งาน
      หากยังไม่ได้ซื้อใบอนุญาต โปรดซื้อใบอนุญาตจาก Smart Construction Marketplace`,
        message2: `
      หากเช่าจากบริษัทอื่น โปรดร้องขอใบอนุญาตสำหรับเครื่องที่ได้เช่ามา
      
      หากมีคำถามใด ๆ โปรดตรวจสอบกับศูนย์ให้ความช่วยเหลือดังต่อไปนี้`,
      },
    },
    tootip: {
      retrofitShareFileList: 'บันทึกแอปพลิเคชัน',
      retrofitConfigList: 'ข้อมูลการกำหนดค่า',
      retrofitKitInfoList: 'ข้อมูลเครื่องควบคุม',
      retrofitAccuraciesList: 'ผลการตรวจสอบความแม่นยำ',
      retrofitCalibaList: 'ข้อมูลการสอบเทียบ',
      retrofitBasicSettingList: 'การตั้งค่าพื้นฐาน',
      retrofitErrorList: 'รายละเอียดข้อผิดพลาด',
      retrofitTopographicSurveyList: 'การสำรวจภูมิประเทศ',
    },
    actions: {
      ok: 'ตกลง',
      add: 'เพิ่ม',
      create: 'การลงทะเบียน',
      yes: 'ใช่',
      no: 'ไม่ใช่',
      confirmation: 'ยืนยัน',
      save: 'บันทึก',
      cancel: 'ยกเลิก',
      required: 'จำเป็น',
      addMachineToSite: 'เพิ่มเครื่องในไซต์งาน',
      addBucketToSite: 'เพิ่ม',
      deleteFromSite: 'ถอดจากไซต์งาน',
      removeLicense: 'ถอดจากเครื่อง',
      lendMachine: 'การมอบหมาย',
      transferMachine: 'การถ่ายโอน',
      remoteSupport: 'การสนับสนุนทางไกล',
      lendBucket: 'แชร์',
      approval: 'อนุมัติ',
      connect: 'เชื่อมต่อ',
      creationInstructions: 'ดึงข้อมูลผลการก่อสร้าง',
      csvDownload: 'ดาวน์โหลด',
      retrofitBundleCreate: 'การลงทะเบียนชุด',
      termsAccept: 'ตกลง',
      termsDeny: 'ปฏิเสธ',
      bulkRegistration: 'การอัปเดตแบบชุด',
      bulkRegistrationOK: 'ตกลง',
      compactionItems: 'รายการบดอัด',
      addLanguage: 'เพิ่มภาษา',
    },
    message: {
      error: 'มีข้อผิดพลาดขึ้น',
      confirm: 'ตกลงหรือไม่',
      fileUpload: 'ลากและวางไฟล์ที่นี่',
      success: 'การลงทะเบียนเสร็จสิ้น',
      approved: 'การอนุมัติเสร็จสิ้น',
      deleteConfirmTitle: '',
      deleteConfirmContent: 'ต้องการลบ %{title} หรือไม่',
      bulkDeleteConfirmContent: 'ต้องการลบ %{title} หรือไม่',
      disconnectFromSite: 'ต้องการถอดจากไซต์งานหรือไม่',
      saveConfirmContent: 'ยืนยัน',
      removeLicense: 'ต้องการถอดจากเครื่องหรือไม่',
      task: {
        crossingMessage: 'พื้นที่งานคาบเกี่ยวกัน',
        sameValueMessage: 'กรอกพิกัดซ้ำ',
        areaZeroMessage: 'ไม่มีพื้นที่สำหรับตั้งพื้นที่งาน',
        invalidPolygonMessage: 'กรอกพิกัดซ้ำ',
      },
      geofence: {
        crossingMessage: 'พื้นที่รั้วภูมิศาสตร์คาบเกี่ยวกัน',
        sameValueMessage: 'กรอกพิกัดซ้ำ',
        areaZeroMessage: 'ไม่มีพื้นที่สำหรับตั้งพื้นที่รั้วภูมิศาสตร์',
      },
      licenseError: 'คุณไม่มีใบอนุญาตสำหรับ Smart Construction Pilot (เว็บแอป)',
      retrofitsManage: {
        serialNumberMatchError: 'หมายเลขซีเรียลไม่ตรงกัน',
        serialNumberInputError: 'ไม่พบหมายเลขซีเรียล',
        invalidAccess: 'คุณไม่ได้รับอนุญาตให้ดูหน้านี้ กลับไปหน้าหลัก',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'หมายเลขซีเรียลไม่ตรงกัน',
        serialNumberInputError: 'ไม่พบหมายเลขซีเรียล',
        retrofitRentaled:
          'ไม่สามารถอัปเดตข้อมูลบริษัทขณะเครื่องควบคุมถูกมอบหมาย',
        invalidCorporation:
          'เนื่องจากบริษัทของคุณไม่ใช่ผู้แทนจำหน่าย คุณไม่สามารถดำเนินการลงทะเบียนแบบพร็อกซี่สำหรับเครื่องควบคุมได้',
      },
    },
    validation: {
      startDate: 'วันที่เริ่มต้น',
      endDate: 'วันที่สิ้นสุด',
      invalidDateFormat: 'รูปแบบวันที่ไม่ถูกต้อง',
      invalidDateTimeFormat: 'รูปแบบวันที่และเวลาไม่ถูกต้อง',
      duplicateProject: 'ชื่อไฟล์ซ้ำ',
      incorrectBeforeDate: '%{target} ต้องตั้งวันที่ก่อน %{before}',
      incorrectAfterDate: '%{target} ต้องตั้งวันที่หลัง %{after}',
      incorrectPlanStartDate:
        'วันที่เริ่มต้นในกำหนดการจะต้องอยู่ก่อนวันที่สิ้นสุดในกำหนดการ',
      incorrectPlanEndDate:
        'วันที่สิ้นสุดในกำหนดการจะต้องอยู่หลังวันที่เริ่มต้นในกำหนดการ',
      incorrectActualStartDate: 'วันที่เริ่มต้นจะต้องอยู่ก่อนวันที่สิ้นสุด',
      incorrectActualEndDate: 'วันที่สิ้นสุดจะต้องอยู่หลังวันที่เริ่มต้น',
      withActualStartDate: 'โปรดกรอกวันที่เริ่มต้นด้วย',
      inputAvailableDate: 'วันที่ที่สามารถกรอกได้คือตั้งแต่ %{date} เป็นต้นไป',
      registrationRequired: 'โปรดเลือกบริษัทที่มอบหมายหรือที่อยู่อีเมล',
      bothInput: 'คุณกรอกได้เฉพาะบริษัทที่มอบหมายหรือที่อยู่อีเมล',
      supportMode: 'โปรดกรอก ID บริษัทหรือ ID ไซต์งาน',
      invalidBkFile: 'ไม่ใช่ไฟล์ BK3',
      invalidPdfFile: 'ไม่ใช่ไฟล์ PDF',
      duplicatedBucketName: 'ชื่อไฟล์บุ้งกี๋ซ้ำ',
      duplicatedTaskName: 'ชื่อไฟล์งานซ้ำ',
      duplicateGeofenceName: 'ชื่อรั้วภูมิศาสตร์ซ้ำ',
      duplicatedCompactionWorkAreaName: 'มีชื่อพื้นที่งานการบดอัดแล้ว',
      duplicatedCompactionLayerName: 'มีชื่อเลเยอร์การบดอัดแล้ว',
      duplicated: {
        compactionMaterials: {
          message: 'มีชื่อวัสดุแล้ว',
        },
        weathers: {
          message: 'มีชื่อสภาพอากาศแล้ว',
        },
        operators: {
          message: 'มีชื่อผู้ปฏิบัติงานแล้ว',
        },
      },
      containInvalidString: 'มีอักขระที่ไม่ถูกต้อง (ไม่สามารถใช้ %{msg})',
      validHalfWidthAlphanumeric:
        'อนุญาตเฉพาะอักขระตัวอักษรกับตัวเลขแบบครึ่งความกว้าง',
      validNumberFormat: 'ไม่ใช่ตัวเลข',
      validIntegerFormat: 'ไม่ใช่จำนวนเต็ม',
      validIntegerRangeFormat: 'อนุญาตอินพุตตั้งแต่ 0 ถึง 2147483647',
      validDuplicateLanguage: 'เลือกภาษาซ้ำกัน',
      validBeforeDate: '%{target} ต้องตั้งวันที่และเวลาก่อน %{before}',
      validAfterDate: '%{target} ต้องตั้งวันที่และเวลาหลัง %{after}',
    },
  },
  resources: {
    sites: {
      name: 'ไซต์งาน',
      fields: {
        name: 'ไซต์งาน',
        corporationName: 'ชื่อบริษัท',
        status: 'สถานะ',
        workPeriodStartDate: 'วันที่เริ่มต้น',
        workPeriodEndDate: 'วันที่สิ้นสุด',
      },
      status: {
        completed: 'เสร็จสิ้น',
        waiting: 'ก่อนเริ่มการก่อสร้าง',
        working: 'กำลังก่อสร้าง',
      },
    },
    retrofits: {
      name: 'เครื่องจักร',
      fields: {
        information: 'ข้อมูลเครื่อง',
        rental: 'การมอบหมาย',
        sendBack: 'ส่งกลับ',
        corporationId: 'บริษัทที่ใช้งาน',
        siteId: 'ไซต์งาน',
        uuid: 'UUID',
        isError: 'สถานะ',
        isOnline: 'ออนไลน์',
        lastOnlineTime: 'การเชื่อมต่อล่าสุด',
        basicInfoManufacturer: 'ผู้ผลิต',
        basicInfoModel: 'รุ่น',
        basicInfoProductNumber: 'หมายเลขผลิตภัณฑ์',
        basicInfoSerialNumber: 'หมายเลขซีเรียล',
        controllerManufacturer: 'ผู้ผลิต',
        controllerModel: 'รุ่น',
        controllerFirmwareVersion: 'รุ่นเฟิร์มแวร์',
        gnssReceiverMainManufacturer: 'ผู้ผลิต',
        gnssReceiverMainModel: 'รุ่น',
        gnssReceiverMainFirmwareVersion: 'รุ่นเฟิร์มแวร์',
        gnssReceiverSubManufacturer: 'ผู้ผลิต',
        gnssReceiverSubModel: 'รุ่น',
        gnssReceiverSubFirmwareVersion: 'รุ่นเฟิร์มแวร์',
        machineInfoCompanyName: 'ผู้ผลิต',
        machineInfoMachineType: 'ประเภทเครื่องจักร',
        machineInfoMachineName: 'ชื่อเครื่องจักร',
        machineInfoMachineId: 'ID เครื่องจักร',
        rentalStatus: 'การมอบหมาย',
        lastUpdated: 'อัปเดตล่าสุด',
      },
      isError: {
        true: 'ข้อผิดพลาด',
        false: 'ปกติ',
      },
      isOnline: {
        true: 'ออนไลน์',
        false: 'ออฟไลน์',
      },
      machineInfoMachineType: {
        excavator: 'รถขุด',
        roadroller: 'รถบดถนน',
        dozer: 'รถดันดิน',
        rigiddump: 'รถบรรทุกเทท้ายแบบแข็ง',
        wheelloader: 'รถตัก',
        grader: 'รถเกลี่ยดิน',
        automobile: 'ยานยนต์',
      },
      licenseMachineType: {
        excavator: 'รถขุด',
        roadroller: 'รถบดถนน',
        dozer: 'รถดันดิน',
        rigiddump: 'รถบรรทุกเทท้ายแบบแข็ง',
        wheelloader: 'รถตัก',
        grader: 'รถเกลี่ยดิน',
        automobile: 'ยานยนต์',
        excavator_license: 'รถขุด : %{licenseGrantInfo}',
        roadroller_license: 'รถบดถนน : %{licenseGrantInfo}',
        dozer_license: 'รถดันดิน : %{licenseGrantInfo}',
        rigiddump_license: 'รถบรรทุกเทท้ายแบบแข็ง : %{licenseGrantInfo}',
        wheelloader_license: 'รถตัก : %{licenseGrantInfo}',
        grader_license: 'รถเกลี่ยดิน : %{licenseGrantInfo}',
        automobile_license: 'ยานยนต์ : %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'กำลังถ่ายโอน',
        sent: 'ถ่ายโอนแล้ว',
        failtosend: 'ถ่ายโอนล้มเหลว',
      },
      rentalStatus: {
        approved: 'อนุมัติ',
        unapproved: 'ไม่อนุมัติ',
        returned: 'คืนแล้ว',
      },
    },
    retrofitRentals: {
      name: 'การมอบหมาย',
      fields: {
        id: 'ID',
        retrofitId: 'ID เครื่องจักร',
        fromCorporationId: 'ID บริษัทที่มอบหมาย',
        fromCorporationName: 'บริษัทที่มอบหมาย',
        toCorporationId: 'บริษัทที่มอบหมาย',
        toCorporationName: 'บริษัทที่ใช้งาน',
        retrofitRentalId: 'ID การมอบหมาย',
        isApproved: 'สถานะ',
        isReturned: 'คืนแล้ว',
        planStartDate: 'วันที่เริ่มต้นในกำหนดการ',
        planEndDate: 'วันที่สิ้นสุดในกำหนดการ',
        actualStartDate: 'วันที่เริ่มต้น',
        actualEndDate: 'วันที่สิ้นสุด',
        rentalUsers: 'อีเมล',
        email: 'อีเมล',
        rentalStatus: 'สถานะ',
      },
      isApproved: {
        true: 'อนุมัติ',
        false: 'ไม่อนุมัติ',
      },
      rentalStatus: {
        approved: 'อนุมัติ',
        unapproved: 'ไม่อนุมัติ',
        returned: 'คืนแล้ว',
      },
    },
    pendingRentals: {
      name: 'กำลังรอการอนุมัติ',
      fields: {
        fromCorporationName: 'บริษัทที่มอบหมาย',
        planStartDate: 'วันที่เริ่มต้นในกำหนดการ',
        planEndDate: 'วันที่สิ้นสุดในกำหนดการ',
      },
    },
    pendingTransfers: {
      name: 'กำลังรอการอนุมัติ',
      fields: {
        fromCorporationName: 'กำลังถ่ายโอนบริษัท',
        planDate: 'วันที่ถ่ายโอนในกำหนดการ',
        lastUpdated: 'อัปเดตล่าสุด',
      },
    },
    pendingAlternateRegists: {
      name: 'กำลังรอการอนุมัติ',
      fields: {
        fromCorporationName: 'เจ้าของปัจจุบัน',
        lastUpdated: 'อัปเดตล่าสุด',
      },
    },
    assignments: {
      name: 'การถ่ายโอน',
      fields: {
        id: 'ID',
        retrofitId: 'ID เครื่องจักร',
        fromCorporationId: 'กำลังถ่ายโอน ID บริษัท',
        fromCorporationName: 'กำลังถ่ายโอนบริษัท',
        toCorporationId: 'ถ่ายโอนบริษัทแล้ว',
        toCorporationName: 'บริษัท',
        isApproved: 'สถานะ',
        planDate: 'วันที่ถ่ายโอนในกำหนดการ',
        actualDate: 'วันที่ถ่ายโอน',
        transferUsers: 'อีเมล',
        email: 'อีเมล',
        lastUpdated: 'อัปเดตล่าสุด',
      },
      isApproved: {
        true: 'อนุมัติ',
        false: 'ไม่อนุมัติ',
      },
    },
    buckets: {
      name: 'บุ้งกี๋',
      fields: {
        companyName: 'ชื่อบริษัท',
        siteId: 'ไซต์งาน',
        name: 'ชื่อ',
        corporationId: 'บริษัทที่ใช้งาน',
        bucketType: 'ประเภท',
        bk3File: 'ไฟล์ BK3',
        'bk3File.title': 'ไฟล์ BK3',
        gltfFile: 'ไฟล์บุ้งกี๋',
        uploadedBy: 'ลงทะเบียนโดย',
        updatedBy: 'อัปเดตโดย',
        fileStatus: 'สถานะ',
        lastUpdated: 'อัปเดตล่าสุด',
        uploadBk: 'อัปโหลด BK3',
      },
      bucketType: {
        slope: 'ทางลาดชัน',
        standard: 'มาตรฐาน',
        tilt: 'เอียง',
      },
      uploadedBy: {
        admin: 'แอดมิน',
        tablet: 'แท็บเล็ต',
      },
      fileStatus: {
        converting: 'กำลังแปลง',
        completed: 'เสร็จสิ้น',
        error: 'ข้อผิดพลาด',
      },
    },
    projects: {
      name: 'โครงการ',
      fields: {
        name: 'ชื่อโครงการ',
        coordinate: 'ระบบพิกัด',
        version: 'เวอร์ชัน',
        status: 'สถานะ',
        lastUpdated: 'อัปเดตล่าสุด',
        regionId: 'ภูมิภาค',
        projectionId: 'เส้นโครงแผนที่',
        geoidId: 'จีออยด์',
        datumId: 'พื้นหลักฐาน',
        file: 'ไฟล์',
        retrofitId: 'เครื่องเป้าหมาย',
      },
      status: {
        converted: 'เสร็จสิ้น',
        waitforconvert: 'กำลังแปลง',
        error: 'ข้อผิดพลาด',
      },
    },
    extensionarms: {
      name: 'แขนต่อ',
      fields: {
        extensionarmType: 'ประเภท',
        name: 'ชื่อ',
        corporationId: 'บริษัทที่ใช้งาน',
        corporationName: 'บริษัทที่ใช้งาน',
        siteId: 'ไซต์งาน',
        siteName: 'ไซต์งาน',
        lastUpdated: 'อัปเดตล่าสุด',
        extensionarmFile: 'ไฟล์แขนต่อ',
      },
      extensionarmType: {
        basic: 'แขนต่อ',
        a: 'ประเภท A',
        b: 'ประเภท B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'แชร์ให้',
        name: 'ชื่อ',
        extensionarmType: 'ประเภท',
        extensionarmFile: {
          title: 'ไฟล์แขนต่อ',
        },
      },
    },
    corporations: {
      name: 'บริษัท',
      fields: {
        name: 'บริษัทที่ใช้งาน',
      },
    },
    retrofitShareFiles: {
      name: 'บันทึกแอปพลิเคชัน',
      fields: {
        name: 'ชื่อไฟล์',
        fileTime: 'วันที่และเวลาที่ได้รับ',
        url: 'ดาวน์โหลด',
      },
    },
    retrofitConfigs: {
      name: 'ข้อมูลการกำหนดค่า',
      fields: {
        timeStamp: 'วันที่และเวลาที่ได้รับ',
        url: 'ดาวน์โหลด',
      },
    },
    retrofitKitInfos: {
      name: 'ข้อมูลเครื่องควบคุม',
      fields: {
        timeStamp: 'วันที่และเวลาที่ได้รับ',
        url: 'ดาวน์โหลด',
      },
    },
    retrofitAccuracies: {
      name: 'ผลการตรวจสอบความแม่นยำ',
      fields: {
        checkDateTime: 'วันที่และเวลาที่ยืนยัน',
        surveyPoint: 'จุดที่วัด',
        positionN: 'ค่าที่วัดได้ : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'ค่าออฟเซ็ต : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'ชื่อจุดอ้างอิง',
        referencePointN: 'จุดอ้างอิง : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'หน่วยความยาว',
      },
    },
    retrofitCalibs: {
      name: 'ข้อมูลการสอบเทียบ',
      fields: {
        timeStamp: 'วันที่และเวลาที่ได้รับ',
        url: 'ดาวน์โหลด',
        detailInfo: {
          createDateTime: 'สร้างวันที่และเวลา',
          updateDateTime: 'อัปเดตวันที่และเวลา',
          machineInfo: {
            companyName: 'ผู้ผลิต',
            machineType: 'ประเภทเครื่องจักร',
            machineName: 'ชื่อเครื่องจักร',
            machineId: 'ID เครื่องจักร',
            drumConfiguration: 'การกำหนดค่าดรัม',
            guidanceMode: 'โหมดนำทาง',
          },
          machineGeometryInfo: {
            boomLength: 'ความยาวบูม',
            secondBoomLength: 'ความยาวบูมที่ 2',
            armLength: 'ความยาวแขน',
            armTopPinToBucketSideLinkPinLength:
              'ระยะระหว่างส่วนบนแขนกับก้านต่อข้างบุ้งกี๋',
            boomTopPinToBucketSideLinkPinLength:
              'ระยะระหว่างส่วนบนบูมกับก้านต่อข้างบุ้งกี๋',
            bucketLink1Length: 'ระยะระหว่างข้างบุ้งกี๋กับกระบอกสูบบุ้งกี๋',
            bucketLink2Length: 'ระยะระหว่างกระบอกสูบบุ้งกี๋กับก้านต่อบุ้งกี๋',
            bodyCenterToBoomFootPin: {
              x: 'ระยะแกน X ของสลักยึดบูมกับกึ่งกลางตัวเครื่อง',
              y: 'ระยะแกน Y ของสลักยึดบูมกับกึ่งกลางตัวเครื่อง',
              z: 'ระยะแกน Z ของสลักยึดบูมกับกึ่งกลางตัวเครื่อง',
            },
            drumWidth: 'ความกว้างดรัม',
            length: 'ความยาว',
            shoeInterval: 'ระยะห่างแผ่นแทรค',
            bladeWidthForDozer: 'ความกว้างใบมีดสำหรับรถดันดิน',
            distanceFromBodyToBlade: 'ระยะจากตัวเครื่องถึงใบมีด',
            frontToMainGnssAntenna: 'ด้านหน้าไปเสาอากาศ GNSS หลัก',
            frontToSubGnssAntenna: 'ด้านหน้าไปเสาอากาศ GNSS รอง',
            centerToMainGnssAntenna: 'จุดกึ่งกลางไปเสาอากาศ GNSS หลัก',
            centerToSubGnssAntenna: 'จุดกึ่งกลางไปเสาอากาศ GNSS รอง',
            groundToMainGnssAntenna: 'สายกราวด์ไปเสาอากาศ GNSS หลัก',
            groundToSubGnssAntenna: 'สายกราวด์ไปเสาอากาศ GNSS รอง',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'มุมออฟเซ็ตของ IMU บูม',
            secondBoomImuOffsetAngle: 'มุมออฟเซ็ตของ IMU บูมที่ 2',
            armImuOffsetAngle: 'มุมออฟเซ็ตของ IMU แขน',
            bucketImuOffsetAngle: 'มุมออฟเซ็ตของ IMU บุ้งกี๋',
            bodyImuOffsetRollAngle: 'มุมโรลออฟเซ็ตของ IMU ตัวเครื่อง ',
            bodyImuOffsetPitchAngle: 'มุมพิชออฟเซ็ตของ IMU ตัวเครื่อง',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'ระยะแกน X ระหว่าง GNSS หลักกับจุดกึ่งกลางตัวเครื่อง',
                y: 'ระยะแกน Y ระหว่าง GNSS หลักกับจุดกึ่งกลางตัวเครื่อง',
                z: 'ระยะแกน Z ระหว่าง GNSS หลักกับจุดกึ่งกลางตัวเครื่อง',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'ระยะแกน X ระหว่าง GNSS รองกับจุดกึ่งกลางตัวเครื่อง',
                y: 'ระยะแกน Y ระหว่าง GNSS รองกับจุดกึ่งกลางตัวเครื่อง',
                z: 'ระยะแกน Z ระหว่าง GNSS รองกับจุดกึ่งกลางตัวเครื่อง',
              },
            },
          },
          extendedModel: {
            swingBoom: 'รุ่นบูมสวิง',
            twoPieceBoom: 'รุ่นบูม 2 ชิ้น',
            minimalSwingRadius: 'รุ่นบูมออฟเซ็ต',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'ความสูงเครื่อง',
            },
            bodyImu1: {
              roll: 'โรล IMU1 ตัวเครื่อง',
              pitch: 'พิช IMU1 ตัวเครื่อง',
              yaw: 'ยอว์ IMU1 ตัวเครื่อง',
            },
            bodyImu2: {
              roll: 'โรล IMU2 ตัวเครื่อง',
              pitch: 'พิช IMU2 ตัวเครื่อง',
              yaw: 'ยอว์ IMU2 ตัวเครื่อง',
            },
            frontLeft: {
              x: 'หน้าซ้าย X',
              y: 'หน้าซ้าย Y',
              z: 'หน้าซ้าย Z',
            },
            frontRight: {
              x: 'หน้าขวา X',
              y: 'หน้าขวา Y',
              z: 'หน้าขวา Z',
            },
            frontGroundingEnd: {
              x: 'ปลายสายกราวด์หน้า X',
              y: 'ปลายสายกราวด์หน้า Y',
              z: 'ปลายสายกราวด์หน้า Z',
            },
            rearGroundingEnd: {
              x: 'ปลายสายกราวด์หลัง X',
              y: 'ปลายสายกราวด์หลัง Y',
              z: 'ปลายสายกราวด์หลัง Z',
            },
            mainGnssAntenna: {
              x: 'เสาอากาศ GNSS หลัก X',
              y: 'เสาอากาศ GNSS หลัก Y',
              z: 'เสาอากาศ GNSS หลัก Z',
            },
            subGnssAntenna: {
              x: 'เสาอากาศ GNSS รอง X',
              y: 'เสาอากาศ GNSS รอง Y',
              z: 'เสาอากาศ GNSS รอง Z',
            },
            prismPoleOffset: 'ออฟเซ็ตเสา Prism',
            antennaElectricalCenterHeight: 'ความสูงตู้ไฟฟ้าเสาอากาศ',
          },
        },
      },
    },
    retrofitBasicSettings: {
      name: 'การตั้งค่าพื้นฐาน',
      fields: {
        timeStamp: 'วันที่และเวลาที่ได้รับ',
        url: 'ดาวน์โหลด',
      },
    },
    retrofitErrors: {
      name: 'ข้อมูลข้อผิดพลาด',
      fields: {
        notifiedTime: 'วันที่และเวลาที่เกิดขึ้น',
        errorCodes: 'รหัสข้อผิดพลาด : รายละเอียด',
      },
    },
    retrofitToposurveys: {
      name: 'รายการการสำรวจภูมิประเทศ',
      fields: {
        surveyDate: 'วันที่และเวลาที่สำรวจ',
        projectName: 'ชื่อโครงการ',
        layerName: 'เลเยอร์',
        pointName: 'ชื่อจุด',
        surveyPoint: 'จุดที่วัด',
        n: 'ค่าที่วัดได้ : N',
        e: 'E',
        z: 'Z',
        unitLength: 'หน่วยความยาว',
      },
    },
    asbuilts: {
      name: 'ผลการก่อสร้าง',
      fields: {
        execStartDate: 'เริ่มต้นการปฏิบัติการ',
        execEndDate: 'สิ้นสุดการปฏิบัติการ',
        startPeriod: 'เวลาเริ่มต้นที่ได้รับ',
        endPeriod: 'เวลาสิ้นสุดที่ได้รับ',
        execStatus: 'สถานะ',
        url: '',
        csvType: 'รูปแบบข้อมูล',
        createUser: 'สร้างผู้ใช้',
      },
      execStatus: {
        complete: 'เสร็จสิ้น',
        inprogress: 'กำลังประมวลผล',
        error: 'ข้อผิดพลาด',
        completewithoutdata: 'เสร็จสิ้น (ไม่มีข้อมูล)',
      },
    },
    retrofitBasicInfos: {
      name: 'ข้อมูลพื้นฐาน',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'ผู้แทนจำหน่าย',
        retrofitRegionId: 'ภูมิภาค',
        corporationId: 'ID บริษัท',
        corporationName: 'ชื่อบริษัท',
        basicInfoManufacturer: 'ผู้ผลิต',
        basicInfoModel: 'รุ่น',
        basicInfoProductNumber: 'หมายเลขผลิตภัณฑ์',
        basicInfoSerialNumber: 'หมายเลขซีเรียล',
        lastUpdated: 'อัปเดตล่าสุด',
        description: 'คำอธิบาย',
        ownerId: 'ID บริษัท',
        ownerName: 'ชื่อบริษัท',
      },
      tag: {
        retrofitBasicInfosDetail: 'ข้อมูลพื้นฐาน',
        sendBack: 'ส่งกลับ',
      },
      sendBack: {
        fields: {
          site: 'ไซต์งาน',
          corporation: 'บริษัทที่ใช้งาน',
          ntripSettingInfo: 'Ntrip ID',
          toRetrofit: 'เครื่องควบคุมทางเลือก',
          notificationEmailAddress: 'อีเมล',
        },
      },
    },
    retrofitBulkActions: {
      name: 'การลงทะเบียนชุด',
      fields: {
        uploadFileName: 'ชื่อไฟล์',
        execStartDate: 'วันที่และเวลาเริ่มต้นการปฏิบัติการ',
        execEndDate: 'วันที่และเวลาสิ้นสุดการปฏิบัติการ',
        execStatus: 'สถานะ',
        uploadFile: 'ไฟล์ที่ลงทะเบียนแล้ว',
        resultFile: 'ผลการลงทะเบียน',
      },
      execStatus: {
        complete: 'เสร็จสิ้น',
        inprogress: 'กำลังประมวลผล',
        error: 'ข้อผิดพลาด',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'AdminCompanyBulkAction',
      fields: {
        uploadFileName: 'ชื่อไฟล์',
        execStartDate: 'วันที่และเวลาเริ่มต้นการปฏิบัติการ',
        execEndDate: 'วันที่และเวลาสิ้นสุดการปฏิบัติการ',
        execStatus: 'สถานะ',
        uploadFile: 'ไฟล์ที่ลงทะเบียนแล้ว',
        resultFile: 'ผลการลงทะเบียน',
      },
      execStatus: {
        complete: 'เสร็จสิ้น',
        inprogress: 'กำลังประมวลผล',
        error: 'ข้อผิดพลาด',
      },
    },
    tasks: {
      name: 'งาน',
      fields: {
        name: 'ชื่อ',
        status: 'สถานะ',
        description: 'คำอธิบาย',
        projectSourceLayerId: 'เลเยอร์',
        retrofitIds: 'เครื่องเป้าหมาย',
        expiredDate: 'วันที่หมดอายุ ',
        createDate: 'ข้อมูลวันที่หมดอายุ',
        rectangle: 'ขอบเขตที่รองรับ',
        lastUpdated: 'อัปเดตล่าสุด',
      },
      status: {
        error: 'ข้อผิดพลาด',
        converting: 'กำลังแปลง',
        sending: 'กำลังถ่ายโอน',
        sent: 'ถ่ายโอนแล้ว',
        failtosend: 'ถ่ายโอนล้มเหลว',
      },
    },
    compactionMaterials: {
      name: 'วัสดุ',
      fields: {
        name: 'ชื่อ',
      },
    },
    weathers: {
      name: 'สภาพอากาศ',
      fields: {
        name: 'ชื่อ',
      },
    },
    operators: {
      name: 'ผู้ปฏิบัติงาน',
      fields: {
        name: 'ชื่อ',
      },
    },
    compactionWorkAreas: {
      name: 'พื้นที่งานบดอัด',
      fields: {
        name: 'ชื่อพื้นที่งาน',
        blockSize: 'ขนาดบล็อก',
        description: 'คำอธิบาย',
      },
    },
    compactionLayers: {
      name: 'เลเยอร์การบดอัด',
      fields: {
        name: 'ชื่อ',
        thickness: 'ความหนา',
        passCount: 'จำนวนครั้งที่ผ่าน',
        compactionMaterialId: 'วัสดุ',
        inUse: 'สถานะ',
        description: 'คำอธิบาย',
      },
      inUse: {
        true: 'กำลังใช้งาน',
        false: 'ไม่ได้ใช้งาน',
      },
    },
    gnssSettings: {
      name: 'การตั้งค่า Ntrip',
      fields: {
        registCorporationName: 'ลงทะเบียนโดย',
        ownerCorporationId: 'ID บริษัท',
        ownerCorporationName: 'ชื่อบริษัท',
        name: 'ชื่อ',
        url: 'URL เซิร์ฟเวอร์',
        portnum: 'พอร์ต',
        userId: 'ID',
        password: 'รหัสผ่าน',
        retrofitId: 'เครื่องเป้าหมาย',
        status: 'สถานะ',
        updateDate: 'อัปเดตล่าสุด',
      },
      status: {
        waitforsend: 'กำลังแปลง',
        sending: 'กำลังถ่ายโอน',
        sent: 'ถ่ายโอนแล้ว',
        failtosend: 'ถ่ายโอนล้มเหลว',
      },
    },
    supportMode: {
      name: 'โหมดสนับสนุน',
      fields: {
        enabled: 'เปิดใช้งานโหมดสนับสนุน',
        corporationId: 'ID บริษัท',
        corporationName: 'ชื่อบริษัท',
        siteId: 'ID ไซต์งาน',
        siteName: 'ไซต์งาน',
        latitude: 'ละติจูด',
        longitude: 'ลองจิจูด',
        workPeriodStartDate: 'ช่วงเวลาก่อสร้างจริง (วันที่เริ่มต้น)',
        workPeriodEndDate: 'ช่วงเวลาก่อสร้างจริง (วันที่สิ้นสุด)',
        photoUrl: 'URL ภาพไซต์งาน',
        description: 'คำอธิบาย',
      },
    },
    userNotices: {
      name: 'การแจ้งเตือน',
      noticeType: {
        emergencyimportant: 'ฉุกเฉิน/สำคัญ',
        emergency: 'ฉุกเฉิน',
        important: 'สำคัญ',
      },
      fields: {
        noticeType: 'ความสำคัญ',
        noticeTypes: 'ความสำคัญ',
        noticeCategory: 'ประเภทการแจ้งเตือน',
        title: 'หัวข้อ',
        content: 'เนื้อหา',
        publicationStartDate: 'วันที่และเวลาเริ่มต้น (UTC)',
        publicationEndDate: 'วันที่และเวลาสิ้นสุด (UTC)',
        file: 'ไฟล์แนบ',
        language: 'ภาษา',
        emergency: 'ฉุกเฉิน',
        important: 'สำคัญ',
      },
    },
    firmwares: {
      name: 'เฟิร์มแวร์',
      fields: {
        version: 'เวอร์ชัน',
        compatibilityLevel: 'ระดับความเข้ากันได้',
        firmwareFile: 'ไฟล์เฟิร์มแวร์',
        firmwareFileCheckSum: 'ผลรวมตรวจสอบของเฟิร์มแวร์',
        firstInitializationFile: 'ไฟล์ตั้งค่าเริ่มต้น 1',
        firstInitializationFileCheckSum: 'ผลรวมตรวจสอบของไฟล์ตั้งค่าเริ่มต้น 1',
        secondInitializationFile: 'ไฟล์ตั้งค่าเริ่มต้น 2',
        secondInitializationFileCheckSum:
          'ผลรวมตรวจสอบของไฟล์ตั้งค่าเริ่มต้น 2',
      },
    },
    bucketShares: {
      name: 'การแชร์ข้อมูลบุ้งกี๋',
      fields: {
        fromBucketId: 'จาก ID บุ้งกี๋',
        corporationId: 'ID บริษัท',
        name: 'ชื่อ',
      },
    },
    extensionarmShare: {
      name: 'การแชร์ข้อมูลแขนต่อ',
      fields: {
        name: 'ชื่อ',
      },
    },
    retrofitCorporationLicenses: {
      name: 'ใบอนุญาต',
      fields: {
        licenseId: 'ID ใบอนุญาต',
        licenseType: 'ประเภท',
        issuedAt: 'วันที่ออก',
        expiresAt: 'วันที่หมดอายุ ',
        retrofitId: 'เครื่องจักร',
        comment: 'คำอธิบาย',
        licenseStatus: 'สถานะ',
        licensePermission: 'ใบอนุญาต',
        productType: 'ชื่อผลิตภัณฑ์',
      },
      licenseStatus: {
        unlicense: 'ไม่ได้จัดสรร',
        disable: 'ไม่ถูกต้อง',
        enable: 'ถูกต้อง',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'ชื่อบริษัท',
        namePlates: 'รูปภาพ',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'หน่วยความยาว',
      },
    },
    geofences: {
      name: 'รั้วภูมิศาสตร์',
      fields: {
        type: 'ประเภท',
        name: 'ชื่อ',
        dimension: 'ขนาด',
        trigger: 'ตัวกระตุ้น',
        valid: 'ถูกต้อง',
        alertType: 'ประเภทการแจ้งเตือน',
        startDate: 'วันที่เริ่มต้น',
        endDate: 'วันที่สิ้นสุด',
        transparency: 'โปร่งใส',
        elevation: 'ระดับความสูง',
        height: 'ความสูง',
        radius: 'รัศมี',
        lastUpdated: 'อัปเดตล่าสุด',
        points: 'ขอบเขตที่รองรับ',
        thickness: 'ความหนา',
      },
      type: {
        line: 'เส้น',
        polygon: 'หลายเหลี่ยม',
        circle: 'วงกลม',
        point: 'จุด',
        wall: 'กำแพง',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'ถูกต้อง',
        false: 'ไม่ถูกต้อง',
      },
      alertType: {
        attention: 'Attention',
        notice: 'Notice',
        warning: 'Warning',
        caution: 'Caution',
        danger: 'Danger',
      },
      transparency: {
        true: 'โปร่งใส',
        false: 'ทึบแสง',
      },
    },
    geofenceAlerts: {
      name: 'การแจ้งเตือน',
      fields: {
        timeStamp: 'วันที่และเวลาการตรวจจับ',
        geofenceName: 'ชื่อรั้วภูมิศาสตร์',
        alertType: 'ประเภทการแจ้งเตือน',
        collisionPoint: 'จุดชน',
        retrofit: {
          machineInfoMachineName: 'เครื่องจักร',
        },
      },
      alertType: {
        attention: 'Attention',
        notice: 'Notice',
        warning: 'Warning',
        caution: 'Caution',
        danger: 'Danger',
      },
    },
    ntrips: {
      name: 'การบริหารข้อมูล Ntrip',
      fields: {
        name: 'ID ผู้ใช้',
        ntripPass: 'รหัสผ่าน',
        serial: 'หมายเลขซีเรียล',
        customerCorporationId: 'ID บริษัท',
        customerCorporationName: 'ชื่อบริษัท',
        ntrip: 'สถานะ',
        numberOfExchanges: 'จำนวนการแลกเปลี่ยน',
        id: 'ID',
        licenceId: 'ID ใบอนุญาต',
        dateOfRegistration: 'วันที่ลงทะเบียน',
        billingToCustomers: 'เรียกเก็บเงินลูกค้า',
        paymentToKomatsu: 'ชำระเงินแก่ Komatsu',
        applicantId: 'ID ผู้สมัคร',
        applicant: 'ผู้สมัคร',
        requestCorporationId: 'ID บริษัทที่ร้องขอ',
        requestCorporationName: 'ชื่อบริษัทที่ร้องขอ',
        isUsed: 'ถูกใช้แล้ว',
      },
      ntrip: {
        publish: 'ชำระเงินแล้ว',
        stop: 'หยุด',
        delete: 'ลบแล้ว',
      },
    },
    retrofitAlternateRegists: {
      name: 'การลงทะเบียนพร็อกซี่เครื่องควบคุม',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'fromCompanyId',
        fromCorporationName: 'การลงทะเบียนพร็อกซี่เครื่องควบคุม',
        toCorporationId: 'ผู้ใช้',
        toCorporationName: 'ชื่อบริษัท',
        isApproved: 'สถานะ',
        createDate: 'วันที่สมัคร',
        actualDate: 'วันที่อนุมัติ',
        transferUsers: 'อีเมล',
        email: 'อีเมล',
        lastUpdated: 'อัปเดตล่าสุด',
      },
      isApproved: {
        true: 'อนุมัติ',
        false: 'ไม่อนุมัติ',
      },
    },
    pendingApprovals: {
      name: 'กำลังรอการอนุมัติ',
      fields: {
        approvalType: 'ประเภท',
        requestedBy: 'บริษัทที่สมัคร',
        lastUpdated: 'อัปเดตล่าสุด',
      },
      approvalType: {
        assignment: 'การมอบหมาย',
        transfer: 'การถ่ายโอน',
        agent: 'การลงทะเบียนพร็อกซี่เครื่องควบคุม',
      },
    },
    retrofitSendbacks: {
      name: 'ส่งกลับ',
      fields: {
        fromRetrofitId: 'From Retrofit',
        toRetrofitId: 'เครื่องควบคุมทางเลือก',
        notificationEmailAddress: 'อีเมล',
        siteId: 'ไซต์งาน',
        corporationId: 'ID บริษัท',
        corporationName: 'บริษัทที่ใช้งาน',
        ntripId: 'ข้อมูล Ntrip',
        isComplete: 'ส่งคืนเสร็จสิ้น',
      },
    },
  },
};

export default message;
