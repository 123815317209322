// for ja
import raMessage from '@bicstone/ra-language-japanese';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: 'パスワードを表示しない',
        toggle_hidden: 'パスワードを表示する',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: '0',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: '%{name}を削除しますか？',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: '承認待ち',
    usermenu: {
      userNotice: 'お知らせ',
      terms: '利用規約',
      manual: 'マニュアル',
      downloads: 'ダウンロード',
    },
    breadcrumbs: {
      details: '詳細',
      retrofitEdit: '詳細',
      taskCreate: 'タスク作成',
      taskEdit: 'タスク詳細',
      geofenceCreate: '作成',
      geofenceEdit: '編集',
      geofenceAlertList: 'ジオフェンス通知',
      geofenceAlertShow: '詳細',
      compactionWorkAreaCreate: '転圧施工箇所作成',
      compactionWorkAreaShow: '転圧施工箇所',
      compactionWorkAreaEdit: '転圧施工箇所更新',
      compactionLayerCreate: '転圧レイヤー作成',
      compactionLayerEdit: '転圧レイヤー編集',
      supportMode: 'サポートモード',
      retrofitManage: 'コントローラ登録',
      retrofitManageAdmin: 'コントローラ登録（管理者）',
      retrofitNamePlateFiles: 'コントローラ登録',
      retrofitAlternateRegistCreate: 'コントローラ代理登録',
      retrofitAlternateRegistEdit: '詳細',
      retrofitManageAdminBulkActionCreate: '登録',
      terms: '利用規約',
      downloads: 'ダウンロード',
    },
    pages: {
      siteList: '現場一覧',
      siteRetrofitCreate: '現場に建機を追加',
      siteRetrofitEdit: '',
      siteBucketCreate: '現場にバケットを追加',
      siteBucketEdit: 'バケット詳細',
      siteProjectEdit: 'プロジェクト詳細',
      siteProjectCreate: 'プロジェクトファイル登録',
      siteExtensionarmCreate: '現場にエクステンションアームを追加',
      taskCreate: 'タスク作成',
      taskEdit: 'タスク詳細',
      geofenceAlertList: 'ジオフェンス通知',
      geofenceAlertShow: 'ジオフェンス通知詳細',
      geofenceCreate: 'ジオフェンス作成',
      geofenceEdit: 'ジオフェンス編集',
      compactionWorkAreaCreate: '転圧施工箇所作成',
      compactionLayerCreate: '転圧レイヤー作成',
      compactionLayerEdit: '転圧レイヤー編集',
      siteFileEdit: '詳細',
      siteFileCreate: '現場にファイルを追加',
      retrofitList: '建機一覧',
      retrofitEdit: '',
      retrofitRentalCreate: '貸与登録',
      retrofitTransferCreate: '譲渡登録',
      retrofitSendBack: 'センドバック登録',
      bucketList: 'バケット一覧',
      bucketEdit: 'バケット詳細',
      bucketCreate: 'バケット登録',
      bucketRentalCreate: 'バケット共有',
      extensionarmList: 'エクステンションアーム一覧',
      extensionarmEdit: 'エクステンションアーム詳細',
      extensionarmShare: 'エクステンションアーム共有',
      retrofitBulkActionList: '一括登録一覧',
      retrofitBulkActionCreate: '一括登録',
      retrofitBasicInfoList: 'コントローラ一覧',
      retrofitBasicInfoCreate: 'コントローラ登録',
      retrofitBasicInfoEdit: 'コントローラ更新',
      gnssSettingList: 'Ntrip設定一覧',
      gnssSettingCreate: 'Ntrip設定作成',
      gnssSettingAdminCreate: 'Ntrip設定作成',
      gnssSettingOwnerCreate: 'Ntrip設定作成',
      gnssSettingEdit: 'Ntrip設定編集',
      gnssSettingAdminEdit: 'Ntrip設定編集',
      gnssSettingOwnerEdit: 'Ntrip設定編集',
      approvalList: '建機一覧',
      retrofitRentalEdit: '貸与詳細',
      retrofitTransferEdit: '譲渡詳細',
      asbuiltsCreate: '施工実績取得',
      asbuiltsShow: '施工実績詳細',
      retrofitConfigList: '設定情報',
      retrofitKitInfoList: 'コントローラ情報',
      retrofitAccuraciesList: '刃先位置測定結果',
      retrofitAccuraciesShow: '詳細',
      retrofitCalibaList: 'キャリブレーション情報',
      retrofitCalibInfo: '車体キャリブレーション情報詳細',
      retrofitBasicSettingList: '基本設定情報',
      retrofitErrorList: 'エラー情報',
      retrofitErrorShow: '詳細',
      retrofitTopographicSurveyList: '地形計測ポイント一覧',
      retrofitTopographicSurveyShow: '詳細',
      retrofitShareFileList: 'アプリケーションログ',
      retrofitCorporationLicenseList: 'ライセンス一覧',
      retrofitCorporationLicenseEdit: 'ライセンス詳細',
      CompactionLayerList: '転圧レイヤ',
      userNoticeList: 'お知らせ一覧',
      userNoticeCreate: 'お知らせ登録',
      userNoticeEdit: 'お知らせ更新',
      userNoticeDrawer: 'お知らせ',
      retrofitAlternateRegists: 'コントローラ代理登録一覧',
      firmwareList: 'ファームウェア一覧',
      firmwareShow: 'ファームウェア詳細',
      firmwareCreate: 'ファームウェア登録',
      retrofitManage: 'テキスト',
      retrofitNamePlateFiles: '写真',
      retrofitManageAdminBulkAction: '一括登録',
      retrofitManageAdminBulkActionCreate: '一括登録',
      retrofitAlternateRegistCreate: 'コントローラ代理登録',
      retrofitAlternateRegistEdit: 'コントローラ代理登録詳細',
      retrofitAlternateRegistCreateText: 'テキスト',
      retrofitAlternateRegistCreatePhoto: '写真',
      ntripList: 'Ntrip情報',
      ntripEdit: 'Ntrip情報管理',
    },
    label: {
      login: 'ログイン',
      selected: '選択 (%{length}件)',
      listNoResults: 'データが見つかりませんでした',
      maintenance: {
        title: 'ただいまメンテナンス中です。',
        message: `ただいまメンテナンスのため一時サービスを停止しております。
        大変ご不便をおかけいたしますが、今しばらくお待ち下さい。`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'アップロードするファイル（%{fileTypes}）をドロップ、または選択して下さい。',
        maxFileNum: '一度に登録できるコントローラは５台までです。',
      },
      nosetting: '設定なし',
      siteRetrofitList: {
        remoteSupport: 'リモートサポート',
      },
      retrofitEdit: {
        breadcrumbs: '詳細',
        belongsDetails: '',
        ntripSettingInfo: 'Ntrip設定情報',
        machineryDetails: '建機',
        retrofitDetails: 'コントローラ',
        basicInfo: '基本情報',
        controller: 'コントローラ',
        reciverMain: 'メインGNSS受信機',
        reciverSub: 'サブGNSS受信機',
        retrofitstatus: 'コントローラステータス',
        license: 'ライセンス',
        licenseId: 'ライセンスID',
        expiredDate: '失効日',
      },
      retrofitRental: {
        rentalDetails: '貸与詳細',
        rentalInfo: '貸与詳細',
        lending: '貸与',
        return: '返却',
        lendingDestination: '貸与先',
        lendingCompany: '企業名',
        lendingEmail: 'メールアドレス',
        companyName: '企業名',
      },
      retorfitTransfer: {
        tab: '譲渡',
        transferInfo: '譲渡詳細',
        transferButton: '譲渡',
        transferEditButton: '譲渡編集',
      },
      siteConfigureEdit: {
        onButton: 'ON',
        offButton: 'OFF',
      },
      approvals: {
        rentalTab: '貸与',
        transferTab: '譲渡',
      },
      buckets: {
        bucketTab: 'バケット',
        extensionarmTab: 'エクステンションアーム',
        extensionarmFile: 'エクステンションアームファイル',
        shareTo: '共有先',
      },
      bucketRental: {
        corporation: '共有先',
        bucketType: 'タイプ',
        bk3File: 'BK3ファイル',
      },
      retrofitBasic: {
        basicInfoTab: '基本情報',
        bulkActionTab: '一括登録',
        ntripTab: 'Ntrip情報管理',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'センドバック',
        initialize: '初期化',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: '一括更新',
        retrofitDistributor: '販売元',
        retrofitRegion: '地域',
        corporationId: '企業ID',
        corporationName: '企業名',
      },
      retrofitBasicInfoEdit: {
        corporationId: '利用中企業ID',
        corporationName: '利用中企業',
      },
      gnssSettingsEdit: {
        ownerCorporationName: '企業名',
      },
      terms: {
        title: '利用規約',
        message: '下記のリンクから利用規約を確認することができます。',
        confirmMessage1:
          'Smart Construction Pilotの利用にあたり、下記の文書への同意が必要です。',
        confirmMessage2:
          '同意いただけない場合は、Smart Construction Pilotはご利用いただけません。',
        select: '地域を選択して下さい。',
      },
      downloads: {
        title: 'Project File Converter',
        message:
          '設計ファイルからプロジェクトファイルを作成するスタンドアロンアプリケーションです。',
        button: 'ダウンロード',
      },
      retrofitBasicInfoCreate: {
        uuid: '自動採番',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'ダウンロード',
        resultFileDownload: 'ダウンロード',
      },
      tasks: {
        coordinate: '座標 (単位：%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'ブロックサイズ (%{unit})',
      },
      compactionLayers: {
        thickness: '厚さ (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: '詳細',
        rentalDetail: '貸与詳細',
      },
      pendingTransfers: {
        breadcrumbs: '詳細',
        transferDetail: '譲渡詳細',
      },
      pendingAlternateRegists: {
        breadcrumbs: '詳細',
        alternateRegistDetail: 'コントローラ代理登録詳細',
      },
      supportMode: {
        retrofit: '建機',
        optional: 'オプション',
      },
      retrofitList: {
        assignment: '貸与',
        license: 'ライセンス',
      },
      userNotices: {
        languageDetails: 'お知らせ内容',
        language: '言語',
        languageEn: '英語',
        languageJa: '日本語',
        languageFr: 'フランス語',
        languageDe: 'ドイツ語',
        languageEs: 'スペイン語',
        Information: 'お知らせ',
        Maintenance: 'メンテナンス',
        emergency: '緊急',
        important: '重要',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'コントローラ登録',
        corporationName: '企業名',
        basicInfoSerialNumber: 'シリアル番号',
        confirmInput: 'シリアル番号の再入力',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'シリアル番号',
        status: 'ステータス',
        imageFile: '画像',
        message1:
          'コントローラのシリアル番号またはQRコードが識別できるように写真を撮影して下さい。',
        message2: 'ファイルサイズは %{mbyte}MB以下にして下さい。',
        errormessage: '一度に登録できるコントローラは５台までです。',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: '有効',
          disable: '無効',
          unLicense: '未付与',
        },
        machine: '建機',
        before: '変更前',
        after: '変更後',
      },
      geofences: {
        project: 'プロジェクト',
        projectSourceLayer: 'レイヤ',
        rgb: '色(RGB)',
        select: '選択ジオフェンス',
        coordinates: '座標',
        coordinatesLabel: '座標 (N,E)',
        unitLength: '単位：%{unit}',
      },
      geofenceDialog: {
        coordinate: '座標 (単位：%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: '半径',
      },
      retrofitAlternateRegists: {
        menuTitle: 'コントローラ代理登録',
        corporationName: '企業名',
        email: 'メールアドレス',
        basicInfoSerialNumber: 'シリアル番号',
        confirmInput: 'シリアル番号の再入力',
      },
      ntripList: {
        unusedTotal: '未使用合計',
      },
      calibrationDetail: {
        breadcrumbs: '詳細',
        machineInfo: 'マシン情報',
        machineGeometryInfo: 'マシンジオメトリ情報',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'IMUマウント情報',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'メインGNSSマウント情報',
        bodyCenterToGnssSub: 'サブGNSSマウント情報',
        extendedModel: '拡張モデル',
        measuredValues: 'キャリブレーション情報',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'データ変換エラー',
        system_localization_quantity_error: `ローカライゼーション情報を含むファイルは複数登録できません。
      ローカライゼーション情報を含むファイルは１つのみ登録して下さい。`,
        system_detect_0byte_file_error: `通信エラーのため、サーバへのファイル登録に失敗しました。
      再度ファイルの登録を実施して下さい。`,
        system_batch_error: 'システムエラー (P001)',
        system_parameter_error: 'システムエラー (P002)',
        system_parameterfile_notfound: 'システムエラー (P003)',
        system_exception: 'システムエラー (P004)',
        system_logfile_error: 'システムエラー (P005)',
        system_parameterfile_parameter_error: 'システムエラー (P006)',
        desing_localization_error: `ローカライゼーション情報がありません。
      以下のいずれか一つのファイルを登録して下さい。
      
      ・GC3ファイル
      ・コントロールポイントを含むTP3ファイル
      ・CALファイル`,
        desing_no_support_dxf_error:
          'DXFファイルのバージョンがAutoCAD2000よりも古いため、読み込むことができませんでした。',
        desing_tile_error: 'プロジェクトファイルの作成に失敗しました。',
        desing_outline_error: '外周線の作成に失敗しました。',
        desing_area_length_error:
          'プロジェクトの領域が非常に大きいため処理を中止しました。設計データが正しいか、CADデータの縮尺が正しいかを確認して下さい。',
        desing_layer_name_duplication_error: `レイヤ名が重複するプロジェクトデータは登録できません。
      設計レイヤ名を変更するか、同一の設計レイヤ名を持つファイルが１つのみとなるように登録して下さい。`,
        targetFiles: '[ファイル名]',
        targetLayers: '[レイヤ]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'なし',
        system_batch_error: 'システムエラー (T001)',
        system_parameter_error: 'システムエラー (T002)',
        system_parameterfile_notfound: 'システムエラー (T003)',
        system_exception: 'システムエラー (T004)',
        system_logfile_error: 'システムエラー (T005)',
        system_basefile_notfound: 'システムエラー (T006)',
        system_prjfile_multi: 'システムエラー (T007)',
        system_basefile_read_error: 'システムエラー (T008)',
        system_target_layer_notfound: 'システムエラー (T009)',
        system_target_layer_duplication: '同一のレイヤ名が存在します。',
        system_taskarea_error: 'タスク領域が不正です。',
        system_taskarea_design_notfound:
          'タスク領域にプロジェクトファイル用のデータ（TIN，ラインワーク，ポイント）がありません。',
        system_tile_error: 'プロジェクトファイルの作成に失敗しました。',
        system_outline_error: '外周線の作成に失敗しました',
        system_parameterfile_error: 'システムエラー',
      },
      retrofitError: {
        1000: 'コントローラ送信UDPデータ　未検出',
        2000: '車体IMU ノード不在',
        2001: '車体IMU　ハード異常',
        2002: '車体IMU　アルゴリズム異常',
        2003: '車体IMU　データ異常',
        2004: '車体IMU　初期化異常',
        2100: 'ブームIMU　ノード不在',
        2101: 'ブームIMU　ハード異常',
        2102: 'ブームIMU　アルゴリズム異常',
        2103: 'ブームIMU　データ異常',
        2104: 'ブームIMU　初期化異常',
        2200: 'アームIMU　ノード不在',
        2201: 'アームIMU　ハード異常',
        2202: 'アームIMU　アルゴリズム異常',
        2203: 'アームIMU　データ異常',
        2204: 'アームIMU　初期化異常',
        2300: 'バケットIMU　ノード不在',
        2301: 'バケットIMU　ハード異常',
        2302: 'バケットIMU　アルゴリズム異常',
        2303: 'バケットIMU　データ異常',
        2304: 'バケットIMU　初期化異常',
        2400: 'チルトバケットIMU　ノード不在',
        2401: 'チルトバケットIMU　ハード異常',
        2402: 'チルトバケットIMU　アルゴリズム異常',
        2403: 'チルトバケットIMU　データ異常',
        2404: 'チルトバケットIMU　初期化異常',
        2500: '2ndブームIMU　ノード不在',
        2501: '2ndブームIMU　ハード異常',
        2502: '2ndブームIMU　アルゴリズム異常',
        2503: '2ndブームIMU　データ異常',
        2504: '2ndブームIMU　初期化異常',
        2600: 'スイングセンサ ノード不在',
        2601: 'スイングセンサ システムエラー',
        2602: 'スイングセンサ ハードウェアエラー',
        2700: 'オフセットセンサ ノード不在',
        2701: 'オフセットセンサ システムエラー',
        2702: 'オフセットセンサ ハードウェアエラー',
        3000: 'ヘッド側圧力センサ　ノード不在',
        3100: 'ボトム側圧力センサ　ノード不在',
        4000: 'GNSSアンテナ　未検出',
        5000: 'VRS補正信号　未検出',
        5001: '無線機信号　未検出',
      },
    },
    dialog: {
      menu: {
        confirmManualLink: '別サイトへ移動します。よろしいですか？',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'バケット',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: '企業IDおよび企業名の入力が必要です。',
        },
        save: {
          message1: '貸与中の建機を含む為、',
          message2: '企業ID/企業名は変更できません。',
          message3: '',
          message4: '以下のシリアル番号を確認して下さい。',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'コントローラ',
        },
        undeletable: {
          message: '該当のコントローラは、使用中のため削除できません。',
        },
      },
      Ntrip: {
        save: {
          stop: 'Ntrip設定を停止しますか？',
          delete: 'Ntrip設定を削除しますか？',
        },
      },
      siteRetrofitList: {
        title: '補足情報',
        message: '工期が終了しています。',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: '共通設定',
          siteSetting: '現場設定',
        },
        mapBackgroundColor: '地図背景色',
        message:
          'ジオフェンス接触検知・アラート通知機能は使用する環境や条件等により機能が正しく動作しないことがあります。ジオフェンス接触検知・アラート通知機能を過信せず、機能および使用条件を理解した上で使用して下さい。',
      },
      geofenceList: {
        confirmDelete: {
          message: 'このジオフェンスを削除しますか？',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            '現場に紐付いている建機は貸与できません。現場への紐付けを解除して下さい。',
          message2: '現場への紐付けを解除して下さい。',
        },
        sameOwner: {
          message: '建機の所有者であるため、建機を貸与できません。',
        },
        unableTransfer: {
          message: '譲渡承認待ちの建機のため貸与登録できません。',
        },
        unableAlternateRegist: {
          message:
            'コントローラ代理登録の承認待ちの建機のため貸与登録できません。',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          '現場に紐付いている建機は譲渡できません。現場への紐付けを解除して下さい。',
        messageRentaled: '建機が貸与中のため譲渡できません。',
        messageLicense: '建機にライセンスが紐づいているため譲渡できません。',
        pendingApprovals: {
          message1: '譲渡申請は受付済です。承認されるまでお待ち下さい。',
          message2: 'ご不明点があれば下記のURLよりご連絡下さい。',
        },
      },
      retrofitSendBacks: {
        submit:
          'コントローラをセンドバックして、対象のコントローラへ設定を引き継ぎますか？',
        deleteNtrip: '返送されたコントローラを初期化しますか？',
        unableRegion:
          '海外に出荷されているコントローラです。こちらのコントローラが正しい返送品の場合は「地域」を未設定に変更して下さい。',
        unableCorporation: {
          message1: '対象のコントローラはセンドバックできません。',
          message2: '販売元へご連絡下さい。',
        },
        retrofitRentaled: '建機が貸与中のためセンドバックできません。',
        tiedToSite: '建機が現場に紐づいているためセンドバックできません。',
      },
      retrofitAddToSiteAction: {
        title: '補足情報',
        message: '承認待ち建機があるため、現場と紐付けは出来ません。',
      },
      retrofitAddToSiteActionTransfer: {
        title: '補足情報',
        message: '譲渡中のため、現場と紐付けは出来ません。',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message: '選択したNtrip設定は別の建機に送信中です。',
        },
        unableTransferInfo: {
          message: '譲渡するためには、以下の設定を解除して下さい。',
          reasonSiteId: '現場',
          reasonRentaled: '貸与',
          reasonLicense: 'ライセンス',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            '貸与先が、別の企業へ貸与中です。別の企業への貸与開始日が %{date} となっています。',
          message2: '終了日は %{date} 以降を選択して下さい。',
        },
        confirmTieUpJobsite: {
          message:
            '貸与先で、対象の建機が現場に紐付いています。この貸与を終了しますか？',
        },
        confirmDoubleRental: {
          message:
            '貸与先が、別の企業へ貸与中です。終了日を確定すると、別の企業への貸与も終了します。終了日を確定しますか？',
        },
        unapprovedSave: {
          message: 'この貸与は未承認のため、保存はできません。',
        },
        unapprovedDelete: {
          title: '',
          message: 'この貸与を削除しますか？',
        },
        approvedDelete: {
          title: '',
          message: 'この貸与は承認済のため、削除はできません。',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message: '建機が貸与中のため譲渡できません。',
        },
        approvedSave: {
          message: '建機にライセンスが紐づいているため譲渡できません。',
        },
        unapprovedDelete: {
          title: '',
          message: 'この譲渡を削除しますか？',
        },
        approvedDelete: {
          title: '',
          message: 'この譲渡は承認済のため、削除はできません。',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'エクステンションアーム',
        },
      },
      projectFileUpload: {
        message: '基準点ファイル(.gc3/.cal)は複数登録することができません。',
      },
      projectEdit: {
        confirm: {
          message:
            'プロジェクトを更新すると既存のタスクは削除されます。更新しますか？',
        },
      },
      projectConversionStatus: {
        title: '変換ステータス',
        button: 'OK',
      },
      taskConversionStatus: {
        title: '変換ステータス',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message: '選択されたタスクは利用中のため、削除することが出来ません。',
        },
        unableToCreate: {
          message: 'プロジェクトに設計データが登録されていません。',
        },
        unableToDelete: {
          message1:
            'プロジェクトのステータス、タスクのステータスが以下の為、タスクを削除することはできません。',
          message2: 'プロジェクト：変換中',
          message3: 'タスク　　　：エラー',
        },
        unableToUpdate: {
          message1:
            'プロジェクトのステータス、タスクのステータスが以下でない為、タスクを編集することはできません。',
          message2: 'プロジェクト：「完了」',
          message3: 'タスク　　　：「送信中」「送信完了」「送信失敗」',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'タスク',
        },
        unableToDelete: {
          message: '選択されたタスクは利用中のため、削除することが出来ません。',
        },
      },
      taskMap: {
        crossingMessage: 'タスク領域が交差しています。',
        sameValueMessage: '同一座標が入力されています。',
        areaZeroMessage: 'タスク領域の面積がありません。',
        noPolygonMessage: 'タスクが未登録のため登録できません。',
      },
      compactionMaterials: {
        add: {
          title: '転圧材料登録',
        },
        update: {
          title: '転圧材料更新',
        },
      },
      weathers: {
        add: {
          title: '天気登録',
        },
        update: {
          title: '天気更新',
        },
      },
      operators: {
        add: {
          title: 'オペレーター登録',
        },
        update: {
          title: 'オペレーター更新',
        },
      },
      compactionWorkAreas: {
        update: {
          message: '転圧施工箇所編集',
        },
        undeletable: {
          message:
            '施工領域に含まれる転圧レイヤが使用中のため、削除することはできません',
        },
      },
      compactionLayers: {
        undeletable: {
          message: '転圧レイヤは使用中のため、削除することはできません',
        },
        deleteConfirm: {
          messageTargetItem: '転圧レイヤ',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message: '選択した建機へは別のNtrip設定を送信中です。',
        },
        confirmDelete: {
          message: 'Ntrip設定を削除しますか？',
        },
        confirmDeleteWithMachine: {
          message: '建機が紐付けされています。Ntrip設定を削除しますか？',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'お知らせ',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: '以下の建機にライセンスを設定します。',
        },
        changeDisable: {
          message:
            '以下の建機のライセンスIDを無効なライセンスから有効なライセンスへ変更します。',
        },
        changeTemporary: {
          message:
            '以下の建機にライセンスを設定します。現在設定されている一時ライセンス（Temporary）は無効となります。',
        },
        errorAdd: {
          message:
            '既にAllライセンスが設定されているため、建機のライセンスを変更できません。',
        },
        errorSameType: {
          message:
            '既に同一種類のライセンスが設定されているため、建機のライセンスを変更できません。',
        },
        errorUnlimited: {
          message:
            'Unlimitedライセンスのため他のライセンスの紐付けはできません。',
        },
        addAll: {
          message:
            '以下の建機には既に設定済みのライセンスがありますが、Allライセンスを追加で設定しますか？',
        },
        compare: {
          message: '以下の建機のライセンスを変更します。',
        },
        warning: {
          message:
            '%{retrofit} には、 %{id}のライセンスが設定されています。解除しますか？',
        },
        unableTransfer: {
          message: '譲渡中のためライセンスの紐付けはできません。',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: 'システムエラー。画像の解析に失敗したデータがあります。',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1: '変更申請は受付済です。承認されるまでお待ち下さい。',
          message2: 'ご不明点があれば下記のURLよりご連絡下さい。',
        },
        corporationIdCheck: {
          message1:
            '申請いただいた内容で登録済です。建機一覧で検索して確認して下さい。',
          message2:
            '検索しても対象のコントローラが見つからない場合、補正情報のIDとPassの確認をしたい場合などは、下記のURLよりご連絡下さい。',
        },
        earthbrainIdCheck: {
          message1: 'お手数をおかけしますが、',
          message2: '以下の内容を下記URLよりご連絡下さい。',
          message3: '・コントローラのシリアル番号',
          message4: '・登録機械名（任意）',
          message5: '・登録したい企業ID',
        },
        invalidSerialNumber: {
          message1: '対象のコントローラは既に別の企業で利用中です。',
          message2: 'その他理由で変更したい場合、',
          message3: '以下の内容を下記URLよりご連絡下さい。',
          message4: '・コントローラのシリアル番号',
          message5: '・登録機械名（任意）',
          message6: '・登録したい企業ID',
          message7: '・変更理由',
        },
        lendingRetrofits: {
          message1: '対象のコントローラは貸与中です。',
          message2: '貸与元にて貸与を終了してから、再申請をして下さい。',
          message3: 'ご不明点があれば下記のURLよりご連絡下さい。',
        },
        siteAttached: {
          message1: '対象のコントローラは現場利用中です。',
          message2:
            '現在利用中の企業にて現場との紐付けを解除してから、再申請をして下さい。',
          message3: 'ご不明点があれば下記のURLよりご連絡下さい。',
        },
        serialNumberExistError: {
          message1:
            '存在しないコントローラのシリアル番号です。入力したコントローラのシリアル番号に間違いがないか確認して下さい。',
          message2: '問題が解決できない場合は、下記のURLよりご連絡下さい。',
        },
        ntripShortage: {
          message1: 'NtripのユーザID、パスワードを発行できませんでした。',
          message2: 'お手数をおかけしますが、下記のURLよりお問い合わせ下さい。',
        },
        serialnumberCheck: {
          message: '下記のシリアル番号の製品をご確認下さい。',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: '利用中企業変更',
        },
        saveValidation: {
          message: 'メールアドレスの入力が必要です。',
        },
        pendingApprovals: {
          message1: '変更申請は受付済です。承認されるまでお待ち下さい。',
          message2: 'ご不明点があれば下記のURLよりご連絡下さい。',
        },
        corporationIdCheck: {
          message1:
            '申請いただいた内容で登録済です。建機一覧で検索して確認して下さい。',
          message2:
            '検索しても対象のコントローラが見つからない場合、補正情報のIDとPassの確認をしたい場合などは、下記のURLよりご連絡下さい。',
        },
        earthbrainIdCheck: {
          message1: 'お手数をおかけしますが、',
          message2: '以下の内容を下記URLよりご連絡下さい。',
          message3: '・コントローラのシリアル番号',
          message4: '・登録機械名（任意）',
          message5: '・登録したい企業ID',
        },
        invalidSerialNumber: {
          message1: '対象のコントローラは既に別の企業で利用中です。',
          message2: 'その他理由で変更したい場合、',
          message3: '以下の内容を下記URLよりご連絡下さい。',
          message4: '・コントローラのシリアル番号',
          message5: '・登録機械名（任意）',
          message6: '・登録したい企業ID',
          message7: '・変更理由',
        },
        lendingRetrofits: {
          message1: '対象のコントローラは貸与中です。',
          message2: '貸与元にて貸与を終了してから、再申請をして下さい。',
          message3: 'ご不明点があれば下記のURLよりご連絡下さい。',
        },
        siteAttached: {
          message1: '対象のコントローラは現場利用中です。',
          message2:
            '現在利用中の企業にて現場との紐付けを解除してから、再申請をして下さい。',
          message3: 'ご不明点があれば下記のURLよりご連絡下さい。',
        },
        serialNumberExistError: {
          message1:
            '存在しないコントローラのシリアル番号です。入力したコントローラのシリアル番号に間違いがないか確認して下さい。',
          message2: '問題が解決できない場合は、下記のURLよりご連絡下さい。',
        },
        ntripShortage: {
          message1: 'NtripのユーザID、パスワードを発行できませんでした。',
          message2: 'お手数をおかけしますが、下記のURLよりお問い合わせ下さい。',
        },
        serialnumberCheck: {
          message: '下記のシリアル番号の製品をご確認下さい。',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'このコントローラは既にあなたの企業の所有になっています。',
          message2: '申請元の企業へご連絡下さい。',
        },
        userCorporation: {
          message1: '既に貸与中のコントローラのため承認することはできません。',
          message2: '申請元の企業へご連絡下さい。',
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `ライセンスが付与されていない、現場利用中の建機があります。
        現場でのご利用には、建機へのライセンス付与が必要となります。
        ライセンスを未購入の場合は、Smart Constructionマーケットプレイスでご購入して下さい。`,
        message2: `
        他社からレンタルをしてご利用している場合は、レンタル元に建機へのライセンス付与をご依頼下さい。

        ご不明点がございましたら、下記サポートサイトをご確認下さい。`,
      },
    },
    tootip: {
      retrofitShareFileList: 'アプリケーションログ',
      retrofitConfigList: '設定情報',
      retrofitKitInfoList: 'コントローラ情報',
      retrofitAccuraciesList: '刃先位置測定結果',
      retrofitCalibaList: 'キャリブレーション情報',
      retrofitBasicSettingList: '基本設定',
      retrofitErrorList: 'エラー詳細',
      retrofitTopographicSurveyList: '地形計測',
    },
    actions: {
      ok: 'OK',
      add: '追加',
      create: '登録',
      yes: 'はい',
      no: 'いいえ',
      confirmation: '確認',
      save: '保存',
      cancel: 'キャンセル',
      required: '必須',
      addMachineToSite: '現場に建機を追加',
      addBucketToSite: '追加',
      deleteFromSite: '現場との紐付け解除',
      removeLicense: '建機との紐付け解除',
      lendMachine: '貸与',
      transferMachine: '譲渡',
      remoteSupport: 'リモートサポート',
      lendBucket: '共有',
      approval: '承認',
      connect: '接続',
      creationInstructions: '施工実績取得',
      csvDownload: 'ダウンロード',
      retrofitBundleCreate: '一括登録',
      termsAccept: '同意する',
      termsDeny: '同意しない',
      bulkRegistration: '一括更新',
      bulkRegistrationOK: 'OK',
      compactionItems: '転圧マスタ',
      addLanguage: '言語追加',
    },
    message: {
      error: 'エラーが発生しました',
      confirm: 'よろしいですか？',
      fileUpload: 'ファイルをドラッグアンドドロップして下さい。',
      success: '登録しました',
      approved: '承認しました',
      deleteConfirmTitle: '',
      deleteConfirmContent: '%{title} を削除しますか？',
      bulkDeleteConfirmContent: '%{title} を削除しますか？',
      disconnectFromSite: '現場との紐付けを解除しますか？',
      saveConfirmContent: '確認',
      removeLicense: '建機との紐付けを解除しますか？',
      task: {
        crossingMessage: 'タスク領域が交差しています。',
        sameValueMessage: '同一座標が入力されています。',
        areaZeroMessage: 'タスク領域の面積がありません。',
        invalidPolygonMessage: '同一座標が入力されています。',
      },
      geofence: {
        crossingMessage: 'ジオフェンス領域が交差しています。',
        sameValueMessage: '同一座標が入力されています。',
        areaZeroMessage: 'ジオフェンス領域の面積がありません。',
      },
      licenseError:
        'Smart Construction Pilot（Webアプリ）のライセンスがありません。',
      retrofitsManage: {
        serialNumberMatchError: 'シリアル番号が一致しません。',
        serialNumberInputError: 'シリアル番号が入力されていません。',
        invalidAccess: '表示する権限がないページです、トップページに戻ります。',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'シリアル番号が一致しません。',
        serialNumberInputError: 'シリアル番号が入力されていません。',
        retrofitRentaled: '貸与中は企業情報の更新できません。',
        invalidCorporation:
          '代理店でないためコントローラ代理登録はできません。',
      },
    },
    validation: {
      startDate: '開始日',
      endDate: '終了日',
      invalidDateFormat: '日付のフォーマットが不正です。',
      invalidDateTimeFormat: '日時のフォーマットが不正です。',
      duplicateProject: 'プロジェクト名が重複しています。',
      incorrectBeforeDate: '%{target}は%{before}より前の日付を設定して下さい。',
      incorrectAfterDate: '%{target}は%{after}より後の日付を設定して下さい。',
      incorrectPlanStartDate:
        '開始予定日は終了予定日より前の日付を設定して下さい。',
      incorrectPlanEndDate:
        '終了予定日は開始予定日より後の日付を設定して下さい。',
      incorrectActualStartDate: '開始日は終了日より前の日付を設定して下さい。',
      incorrectActualEndDate: '終了日は開始日より後の日付を設定して下さい。',
      withActualStartDate: '開始日も入力して下さい。',
      inputAvailableDate: '入力可能な日付は %{date} ～です。',
      registrationRequired:
        '貸与先かメールアドレスのいずれかを選択して下さい。',
      bothInput: '貸与先とメールアドレスはどちらか一方しか入力できません。',
      supportMode: '企業IDか現場IDのどちらかを入力して下さい。',
      invalidBkFile: 'BK3ファイルではありません。',
      invalidPdfFile: 'PDFファイルではありません。',
      duplicatedBucketName: 'バケット名が重複しています。',
      duplicatedTaskName: 'タスク名が重複しています。',
      duplicateGeofenceName: 'ジオフェンス名が重複しています。',
      duplicatedCompactionWorkAreaName: '転圧施工領域名が重複しています',
      duplicatedCompactionLayerName: '転圧レイヤ名が重複しています',
      duplicated: {
        compactionMaterials: {
          message: '転圧材料名が重複しています',
        },
        weathers: {
          message: '天気名が重複しています',
        },
        operators: {
          message: 'オペレータ名が重複しています',
        },
      },
      containInvalidString:
        '利用できない文字が含まれています。（ %{msg}は使用できません）',
      validHalfWidthAlphanumeric: '半角英数字のみ入力可能です。',
      validNumberFormat: '数値ではありません。',
      validIntegerFormat: '整数ではありません。',
      validIntegerRangeFormat: '0 ~ 2147483647 の数値が入力可能です。',
      validDuplicateLanguage: '同じ言語が選択されています。',
      validBeforeDate: '%{target}は%{before}より前の日時を設定して下さい。',
      validAfterDate: '%{target}は%{after}より後の日時を設定して下さい。',
    },
  },
  resources: {
    sites: {
      name: '現場',
      fields: {
        name: '現場',
        corporationName: '企業名',
        status: 'ステータス',
        workPeriodStartDate: '開始日',
        workPeriodEndDate: '終了日',
      },
      status: {
        completed: '完工',
        waiting: '工事開始前',
        working: '施工中',
      },
    },
    retrofits: {
      name: '建機',
      fields: {
        information: '建機情報',
        rental: '貸与',
        sendBack: 'センドバック',
        corporationId: '利用中企業',
        siteId: '現場',
        uuid: 'UUID',
        isError: 'ステータス',
        isOnline: 'オンライン',
        lastOnlineTime: '最終接続日時',
        basicInfoManufacturer: '製造元',
        basicInfoModel: 'モデル',
        basicInfoProductNumber: '製造No.',
        basicInfoSerialNumber: 'シリアルNo.',
        controllerManufacturer: '製造元',
        controllerModel: 'モデル',
        controllerFirmwareVersion: 'ファームウェアVer.',
        gnssReceiverMainManufacturer: '製造元',
        gnssReceiverMainModel: 'モデル',
        gnssReceiverMainFirmwareVersion: 'ファームウェアVer.',
        gnssReceiverSubManufacturer: '製造元',
        gnssReceiverSubModel: 'モデル',
        gnssReceiverSubFirmwareVersion: 'ファームウェアVer.',
        machineInfoCompanyName: 'メーカ',
        machineInfoMachineType: 'マシン\nタイプ',
        machineInfoMachineName: 'マシン名',
        machineInfoMachineId: 'マシンID',
        rentalStatus: '貸与',
        lastUpdated: '最終更新日時',
      },
      isError: {
        true: 'エラー',
        false: '正常',
      },
      isOnline: {
        true: 'オンライン',
        false: 'オフライン',
      },
      machineInfoMachineType: {
        excavator: 'ショベル',
        roadroller: '転圧機械',
        dozer: 'ブルドーザ',
        rigiddump: 'ダンプ',
        wheelloader: 'ホイールローダ',
        grader: 'グレーダ',
        automobile: '自動車',
      },
      licenseMachineType: {
        excavator: 'ショベル',
        roadroller: '転圧機械',
        dozer: 'ブルドーザ',
        rigiddump: 'ダンプ',
        wheelloader: 'ホイールローダ',
        grader: 'グレーダ',
        automobile: '自動車',
        excavator_license: 'ショベル : %{licenseGrantInfo}',
        roadroller_license: '転圧機械 : %{licenseGrantInfo}',
        dozer_license: 'ブルドーザ : %{licenseGrantInfo}',
        rigiddump_license: 'ダンプ : %{licenseGrantInfo}',
        wheelloader_license: 'ホイールローダ : %{licenseGrantInfo}',
        grader_license: 'グレーダ : %{licenseGrantInfo}',
        automobile_license: '自動車 : %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: '送信中',
        sent: '送信完了',
        failtosend: '送信失敗',
      },
      rentalStatus: {
        approved: '承認済',
        unapproved: '未承認',
        returned: '返却済',
      },
    },
    retrofitRentals: {
      name: '貸与',
      fields: {
        id: 'ID',
        retrofitId: '建機ID',
        fromCorporationId: '貸与元企業ID',
        fromCorporationName: '貸与元企業',
        toCorporationId: '貸与先',
        toCorporationName: '利用企業',
        retrofitRentalId: '貸与ID',
        isApproved: 'ステータス',
        isReturned: '返却済',
        planStartDate: '開始予定日',
        planEndDate: '終了予定日',
        actualStartDate: '開始日',
        actualEndDate: '終了日',
        rentalUsers: 'メールアドレス',
        email: 'メールアドレス',
        rentalStatus: 'ステータス',
      },
      isApproved: {
        true: '承認済',
        false: '未承認',
      },
      rentalStatus: {
        approved: '承認済',
        unapproved: '未承認',
        returned: '返却済',
      },
    },
    pendingRentals: {
      name: '承認待ち',
      fields: {
        fromCorporationName: '貸与元企業',
        planStartDate: '開始予定日',
        planEndDate: '終了予定日',
      },
    },
    pendingTransfers: {
      name: '承認待ち',
      fields: {
        fromCorporationName: '譲渡元企業',
        planDate: '譲渡予定日',
        lastUpdated: '最終更新日',
      },
    },
    pendingAlternateRegists: {
      name: '承認待ち',
      fields: {
        fromCorporationName: '現在の所有者',
        lastUpdated: '最終更新日',
      },
    },
    assignments: {
      name: '譲渡',
      fields: {
        id: 'ID',
        retrofitId: '建機ID',
        fromCorporationId: '譲渡元企業ID',
        fromCorporationName: '譲渡元企業',
        toCorporationId: '譲渡先企業',
        toCorporationName: '企業',
        isApproved: 'ステータス',
        planDate: '譲渡予定日',
        actualDate: '譲渡日',
        transferUsers: 'メールアドレス',
        email: 'メールアドレス',
        lastUpdated: '最終更新日',
      },
      isApproved: {
        true: '承認済',
        false: '未承認',
      },
    },
    buckets: {
      name: 'バケット',
      fields: {
        companyName: '企業名',
        siteId: '現場',
        name: '名称',
        corporationId: '利用企業',
        bucketType: '種別',
        bk3File: 'BK3ファイル',
        'bk3File.title': 'BK3ファイル',
        gltfFile: 'バケットファイル',
        uploadedBy: '登録者',
        updatedBy: '更新者',
        fileStatus: 'ステータス',
        lastUpdated: '最終更新日時',
        uploadBk: 'BK3ファイル',
      },
      bucketType: {
        slope: '法面',
        standard: '標準',
        tilt: 'チルト',
      },
      uploadedBy: {
        admin: '管理者',
        tablet: 'タブレット',
      },
      fileStatus: {
        converting: '変換中',
        completed: '完了',
        error: 'エラー',
      },
    },
    projects: {
      name: 'プロジェクト',
      fields: {
        name: 'プロジェクト名',
        coordinate: '座標系',
        version: 'バージョン',
        status: 'ステータス',
        lastUpdated: '最終更新日',
        regionId: '地域',
        projectionId: 'プロジェクション',
        geoidId: 'ジオイド',
        datumId: '測地系',
        file: 'ファイル',
        retrofitId: '対象建機',
      },
      status: {
        converted: '完了',
        waitforconvert: '変換中',
        error: 'エラー',
      },
    },
    extensionarms: {
      name: 'エクステンションアーム',
      fields: {
        extensionarmType: 'タイプ',
        name: '名称',
        corporationId: '利用企業',
        corporationName: '利用企業',
        siteId: '現場',
        siteName: '現場',
        lastUpdated: '最終更新日時',
        extensionarmFile: 'エクステンションアームファイル',
      },
      extensionarmType: {
        basic: 'エクステンションアーム',
        a: 'Aタイプ',
        b: 'Bタイプ',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: '共有先',
        name: '名称',
        extensionarmType: 'タイプ',
        extensionarmFile: {
          title: 'エクステンションアームファイル',
        },
      },
    },
    corporations: {
      name: '企業',
      fields: {
        name: '利用企業',
      },
    },
    retrofitShareFiles: {
      name: 'アプリケーションログ',
      fields: {
        name: 'ファイル名',
        fileTime: '受信日時',
        url: 'ダウンロード',
      },
    },
    retrofitConfigs: {
      name: '設定情報',
      fields: {
        timeStamp: '受信日時',
        url: 'ダウンロード',
      },
    },
    retrofitKitInfos: {
      name: 'コントローラ情報',
      fields: {
        timeStamp: '受信日時',
        url: 'ダウンロード',
      },
    },
    retrofitCalibs: {
      name: 'キャリブレーション情報',
      fields: {
        timeStamp: '受信日時',
        url: 'ダウンロード',
        detailInfo: {
          createDateTime: '作成日時',
          updateDateTime: '更新日時',
          machineInfo: {
            companyName: 'メーカ',
            machineType: 'マシンタイプ',
            machineName: 'マシン名',
            machineId: 'マシンID',
            drumConfiguration: 'マシン構成',
            guidanceMode: 'ガイダンスモード',
          },
          machineGeometryInfo: {
            boomLength: 'ブーム長さ',
            secondBoomLength: '2ndブーム長さ',
            armLength: 'アーム長さ',
            armTopPinToBucketSideLinkPinLength:
              'アームトップ・バケットサイドリンク間距離',
            boomTopPinToBucketSideLinkPinLength:
              'ブームトップ・バケットサイドリンク間距離',
            bucketLink1Length: 'バケットサイド・バケットシリンダ間距離',
            bucketLink2Length: 'バケットシリンダ・バケットリンク間距離',
            bodyCenterToBoomFootPin: {
              x: '車体中心・ブームフートピン間距離x',
              y: '車体中心・ブームフートピン間距離y',
              z: '車体中心・ブームフートピン間距離z',
            },
            drumWidth: '履帯/車輪/ブレードの外幅',
            length: 'シューの長さ/前輪接地面から後輪接地面までの長さ',
            shoeInterval: '履帯/車輪の内幅',
            bladeWidthForDozer: 'ブレードの外幅',
            distanceFromBodyToBlade: '車体からブレードまでの距離',
            frontToMainGnssAntenna:
              '履帯前方/前輪接地端部またはブレード下端部を結んだ直線の中心からメインGNSS電気位相中心までの距離 X',
            frontToSubGnssAntenna:
              '履帯前方/前輪接地端部またはブレード下端部を結んだ直線の中心からサブGNSS電気位相中心までの距離 X',
            centerToMainGnssAntenna:
              '軸中心からメインGNSS電気位相中心までの距離Y',
            centerToSubGnssAntenna: '軸中心からサブGNSS電気位相中心までの距離Y',
            groundToMainGnssAntenna:
              '地面からメインGNSS電気位相中心までの距離Z',
            groundToSubGnssAntenna: '地面からサブGNSS電気位相中心までの距離Z',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'ブームIMUオフセット角',
            secondBoomImuOffsetAngle: '2ndブームIMUオフセット角',
            armImuOffsetAngle: 'アームIMUオフセット角',
            bucketImuOffsetAngle: 'バケットIMUオフセット角',
            bodyImuOffsetRollAngle: 'ボディIMUオフセットロール角',
            bodyImuOffsetPitchAngle: 'ボディIMUオフセットピッチ角',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: '車体中心・メインGNSS間距離x',
                y: '車体中心・メインGNSS間距離y',
                z: '車体中心・メインGNSS間距離z',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: '車体中心・サブGNSS間距離x',
                y: '車体中心・サブGNSS間距離y',
                z: '車体中心・サブGNSS間距離z',
              },
            },
          },
          extendedModel: {
            swingBoom: 'スイングブーム機種',
            twoPieceBoom: 'ツーピースブーム機種',
            minimalSwingRadius: 'オフセットブーム機種',
          },
          measuredValues: {
            otherLength: {
              machineHeight: '車体の高さ',
            },
            bodyImu1: {
              roll: 'IMUの計測値のRollの値',
              pitch: 'IMUの計測値のPitchの値',
              yaw: 'IMUの計測値のYawの値',
            },
            bodyImu2: {
              roll: 'IMUの計測値のRollの値（180°回転）',
              pitch: 'IMUの計測値のPitchの値（180°回転）',
              yaw: 'IMUの計測値のYawの値（180°回転）',
            },
            frontLeft: {
              x: '機械前方左側接地部の計測値X',
              y: '機械前方左側接地部の計測値Y',
              z: '機械前方左側接地部の計測値Z',
            },
            frontRight: {
              x: '機械前方右側接地部の計測値X',
              y: '機械前方右側接地部の計測値Y',
              z: '機械前方右側接地部の計測値Z',
            },
            frontGroundingEnd: {
              x: '履帯前方/前輪接地端部の計測値X',
              y: '履帯前方/前輪接地端部の計測値Y',
              z: '履帯前方/前輪接地端部の計測値Z',
            },
            rearGroundingEnd: {
              x: '履帯後方/後輪接地端部の計測値X',
              y: '履帯後方/後輪接地端部の計測値Y',
              z: '履帯後方/後輪接地端部の計測値Z',
            },
            mainGnssAntenna: {
              x: 'メインGNSSアンテナの計測値X',
              y: 'メインGNSSアンテナの計測値Y',
              z: 'メインGNSSアンテナの計測値Z',
            },
            subGnssAntenna: {
              x: 'サブGNSSアンテナの計測値X',
              y: 'サブGNSSアンテナの計測値Y',
              z: 'サブGNSSアンテナの計測値Z',
            },
            prismPoleOffset: 'プリズムポール高さ',
            antennaElectricalCenterHeight: 'アンテナ電気中心からの高さ',
          },
        },
      },
    },
    retrofitAccuracies: {
      name: '刃先位置測定結果',
      fields: {
        checkDateTime: '確認日時',
        surveyPoint: '計測箇所',
        positionN: '計測座標 : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'オフセット : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: '基準点名',
        referencePointN: '基準点座標 : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: '長さの単位',
      },
    },
    retrofitBasicSettings: {
      name: '基本設定',
      fields: {
        timeStamp: '受信日時',
        url: 'ダウンロード',
      },
    },
    retrofitErrors: {
      name: 'エラー情報',
      fields: {
        notifiedTime: '発生日時',
        errorCodes: 'エラーコード : 詳細',
      },
    },
    retrofitToposurveys: {
      name: '地形計測ポイント一覧',
      fields: {
        surveyDate: '計測日時',
        projectName: 'プロジェクト名',
        layerName: 'レイヤ',
        pointName: '点名',
        surveyPoint: '計測箇所',
        n: '計測座標 : N',
        e: 'E',
        z: 'Z',
        unitLength: '長さの単位',
      },
    },
    asbuilts: {
      name: '施工実績',
      fields: {
        execStartDate: '実行開始日時',
        execEndDate: '実行終了日時',
        startPeriod: '取得期間（開始）',
        endPeriod: '取得期間（終了）',
        execStatus: 'ステータス',
        url: '',
        csvType: 'データフォーマット',
        createUser: '作成者',
      },
      execStatus: {
        complete: '完了',
        inprogress: '処理中',
        error: 'エラー',
        completewithoutdata: '完了(データなし)',
      },
    },
    retrofitBasicInfos: {
      name: '基本情報',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: '販売元',
        retrofitRegionId: '地域',
        corporationId: '企業ID',
        corporationName: '企業名',
        basicInfoManufacturer: '製造元',
        basicInfoModel: 'モデル',
        basicInfoProductNumber: '製造No.',
        basicInfoSerialNumber: 'シリアルNo.',
        lastUpdated: '最終更新日時',
        description: '備考',
        ownerId: '企業ID',
        ownerName: '企業名',
      },
      tag: {
        retrofitBasicInfosDetail: '基本情報',
        sendBack: 'センドバック',
      },
      sendBack: {
        fields: {
          site: '現場',
          corporation: '利用中企業',
          ntripSettingInfo: 'Ntrip ID',
          toRetrofit: '代替コントローラ',
          notificationEmailAddress: 'メールアドレス',
        },
      },
    },
    retrofitBulkActions: {
      name: '一括登録',
      fields: {
        uploadFileName: 'ファイル名',
        execStartDate: '実行開始日時',
        execEndDate: '実行終了日時',
        execStatus: 'ステータス',
        uploadFile: '登録ファイル',
        resultFile: '登録結果',
      },
      execStatus: {
        complete: '完了',
        inprogress: '処理中',
        error: 'エラー',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'コントローラ登録（管理者）一括登録',
      fields: {
        uploadFileName: 'ファイル名',
        execStartDate: '実行開始日時',
        execEndDate: '実行終了日時',
        execStatus: 'ステータス',
        uploadFile: '登録ファイル',
        resultFile: '登録結果',
      },
      execStatus: {
        complete: '完了',
        inprogress: '処理中',
        error: 'エラー',
      },
    },
    tasks: {
      name: 'タスク',
      fields: {
        name: '名称',
        status: 'ステータス',
        description: '備考',
        projectSourceLayerId: 'レイヤ',
        retrofitIds: '対象建機',
        expiredDate: '失効日',
        createDate: '失効日',
        rectangle: '座標選択範囲',
        lastUpdated: '最終更新日',
      },
      status: {
        error: 'エラー',
        converting: '変換中',
        sending: '送信中',
        sent: '送信完了',
        failtosend: '送信失敗',
      },
    },
    compactionMaterials: {
      name: '転圧材料',
      fields: {
        name: '名称',
      },
    },
    weathers: {
      name: '天気',
      fields: {
        name: '名称',
      },
    },
    operators: {
      name: 'オペレータ',
      fields: {
        name: '名称',
      },
    },
    compactionWorkAreas: {
      name: '転圧施工領域一覧 ',
      fields: {
        name: '転圧施工領域名',
        blockSize: 'ブロックサイズ',
        description: '備考',
      },
    },
    compactionLayers: {
      name: '転圧レイヤ',
      fields: {
        name: 'レイヤ名',
        thickness: '厚さ',
        passCount: '回数',
        compactionMaterialId: '材料',
        inUse: 'ステータス',
        description: '備考',
      },
      inUse: {
        true: '利用中',
        false: '未使用',
      },
    },
    gnssSettings: {
      name: 'Ntrip設定',
      fields: {
        registCorporationName: '登録企業',
        ownerCorporationId: '企業ID',
        ownerCorporationName: '企業名',
        name: '名称',
        url: 'サーバURL',
        portnum: 'ポート番号',
        userId: 'ID',
        password: 'パスワード',
        retrofitId: '対象建機',
        status: 'ステータス',
        updateDate: '最終更新日時',
      },
      status: {
        waitforsend: '変換中',
        sending: '送信中',
        sent: '送信完了',
        failtosend: '送信失敗',
      },
    },
    supportMode: {
      name: 'サポートモード',
      fields: {
        enabled: 'サポートモードを有効にする',
        corporationId: '企業ID',
        corporationName: '企業名',
        siteId: '現場ID',
        siteName: '現場',
        latitude: '緯度',
        longitude: '経度',
        workPeriodStartDate: '実績工期(開始日)',
        workPeriodEndDate: '実績工期(終了日)',
        photoUrl: '現場画像URL',
        description: '備考',
      },
    },
    userNotices: {
      name: 'お知らせ',
      noticeType: {
        emergencyimportant: '緊急・重要',
        emergency: '緊急',
        important: '重要',
      },
      fields: {
        noticeType: '重要度',
        noticeTypes: '重要度',
        noticeCategory: '通知種別',
        title: 'タイトル',
        content: '内容',
        publicationStartDate: '開始日時(UTC)',
        publicationEndDate: '終了日時(UTC)',
        file: '添付ファイル',
        language: '言語',
        emergency: '緊急',
        important: '重要',
      },
    },
    firmwares: {
      name: 'ファームウェア',
      fields: {
        version: 'バージョン',
        compatibilityLevel: '互換性レベル',
        firmwareFile: 'ファームウェアファイル',
        firmwareFileCheckSum: 'ファームウェアファイル(md5)',
        firstInitializationFile: '初期化ファイル１',
        firstInitializationFileCheckSum: '初期化ファイル１(md5)',
        secondInitializationFile: '初期化ファイル２',
        secondInitializationFileCheckSum: '初期化ファイル２(md5)',
      },
    },
    bucketShares: {
      name: 'バケット共有',
      fields: {
        fromBucketId: 'コピー元バケットID',
        corporationId: '企業ID',
        name: '名称',
      },
    },
    extensionarmShare: {
      name: 'エクステンションアーム共有',
      fields: {
        name: '名称',
      },
    },
    retrofitCorporationLicenses: {
      name: 'ライセンス',
      fields: {
        licenseId: 'ライセンスID',
        licenseType: 'タイプ',
        issuedAt: '発行日',
        expiresAt: '失効日',
        retrofitId: '建機',
        comment: '備考',
        licenseStatus: 'ステータス',
        licensePermission: 'ライセンス',
        productType: 'プロダクト名',
      },
      licenseStatus: {
        unlicense: '未付与',
        disable: '無効',
        enable: '有効',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: '企業名',
        namePlates: '写真',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: '長さの単位',
      },
    },
    geofences: {
      name: 'ジオフェンス',
      fields: {
        type: 'タイプ',
        name: '名称',
        dimension: '次元',
        trigger: 'トリガー',
        valid: '有効',
        alertType: 'アラートタイプ',
        startDate: '開始日',
        endDate: '終了日',
        transparency: '透過',
        elevation: '標高',
        height: '高さ',
        radius: '半径',
        lastUpdated: '最終更新日時',
        points: '座標選択範囲',
        thickness: '厚さ',
      },
      type: {
        line: '線',
        polygon: 'Polygon',
        circle: '円',
        point: '点',
        wall: '壁',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: '有効',
        false: '無効',
      },
      alertType: {
        attention: 'Attention',
        notice: 'Notice',
        warning: 'Warning',
        caution: 'Caution',
        danger: 'Danger',
      },
      transparency: {
        true: '透過',
        false: '非透過',
      },
    },
    geofenceAlerts: {
      name: '通知',
      fields: {
        timeStamp: '検出日時',
        geofenceName: 'ジオフェンス名',
        alertType: 'アラートタイプ',
        collisionPoint: '接触箇所',
        retrofit: {
          machineInfoMachineName: '建機',
        },
      },
      alertType: {
        attention: 'Attention',
        notice: 'Notice',
        warning: 'Warning',
        caution: 'Caution',
        danger: 'Danger',
      },
    },
    ntrips: {
      name: 'Ntrip情報管理',
      fields: {
        name: 'ユーザID',
        ntripPass: 'パスワード',
        serial: 'シリアルNo.',
        customerCorporationId: '企業ID',
        customerCorporationName: '企業名',
        ntrip: 'ステータス',
        numberOfExchanges: '交換回数',
        id: 'カスタムオブジェクトID',
        licenceId: 'ライセンスID',
        dateOfRegistration: 'データ登録日時',
        billingToCustomers: '顧客への請求',
        paymentToKomatsu: 'コマツへの支払い',
        applicantId: '申請者ID',
        applicant: '申請者名',
        requestCorporationId: 'LANDLOG企業ID',
        requestCorporationName: 'LANDLOG企業名',
        isUsed: '使用状態',
      },
      ntrip: {
        publish: '払出済',
        stop: '停止',
        delete: '削除',
      },
    },
    retrofitAlternateRegists: {
      name: 'コントローラ代理登録',
      fields: {
        id: 'ID',
        retrofitId: '建機ID',
        fromCorporationId: '変更元企業ID',
        fromCorporationName: 'コントローラ代理登録',
        toCorporationId: '変更先',
        toCorporationName: '企業名',
        isApproved: 'ステータス',
        createDate: '申請日',
        actualDate: '承認日',
        transferUsers: 'メールアドレス',
        email: 'メールアドレス',
        lastUpdated: '最終更新日',
      },
      isApproved: {
        true: '承認済',
        false: '未承認',
      },
    },
    pendingApprovals: {
      name: '承認待ち',
      fields: {
        approvalType: 'タイプ',
        requestedBy: '申請元',
        lastUpdated: '最終更新日',
      },
      approvalType: {
        assignment: '貸与',
        transfer: '譲渡',
        agent: 'コントローラ代理登録',
      },
    },
    retrofitSendbacks: {
      name: 'センドバック',
      fields: {
        fromRetrofitId: 'From Retrofit',
        toRetrofitId: '代替コントローラ',
        notificationEmailAddress: 'メールアドレス',
        siteId: '現場',
        corporationId: '企業ID',
        corporationName: '利用中企業',
        ntripId: 'Ntrip情報',
        isComplete: '返送品処理中',
      },
    },
  },
};

export default message;
