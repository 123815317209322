// for pt
// ポルトガル語
import raMessage from 'ra-language-portuguese';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Não encontrado',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Deseja excluir %{name}?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'Aguardando Aprovação',
    usermenu: {
      userNotice: 'Notificação',
      terms: 'TERMOS DE UTILIZAÇÃO',
      manual: 'Manual',
      downloads: 'Download',
    },
    breadcrumbs: {
      details: 'Detalhes',
      retrofitEdit: 'Detalhes',
      taskCreate: 'Criar Tarefa',
      taskEdit: 'Detalhes da Tarefa',
      geofenceCreate: 'Criar',
      geofenceEdit: 'Editar',
      geofenceAlertList: 'Alerta de Cerca Virtual',
      geofenceAlertShow: 'Detalhes',
      compactionWorkAreaCreate: 'Adicionar Áreas de Trabalho de Compactação',
      compactionWorkAreaShow: 'Áreas de Trabalho de Compactação',
      compactionWorkAreaEdit: 'Editar Áreas de Trabalho de Compactação',
      compactionLayerCreate: 'Criar Camadas de Compactação',
      compactionLayerEdit: 'Editar Camadas de Compactação',
      supportMode: 'Modo de Suporte',
      retrofitManage: 'Registro do Controlador',
      retrofitManageAdmin: 'Registro do Controlador (Admin)',
      retrofitNamePlateFiles: 'Registro do Controlador',
      retrofitAlternateRegistCreate: 'Registro de Proxy do Controlador',
      retrofitAlternateRegistEdit: 'Detalhes',
      retrofitManageAdminBulkActionCreate: 'Registro',
      terms: 'TERMOS DE UTILIZAÇÃO',
      downloads: 'Download',
    },
    pages: {
      siteList: 'Lista de Locais de Trabalho',
      siteRetrofitCreate: 'Adicionar Máquina ao Local de Trabalho',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Adicionar Caçamba ao Local de Trabalho',
      siteBucketEdit: 'Detalhes da Balde',
      siteProjectEdit: 'Detalhes do Projeto',
      siteProjectCreate: 'Registro de Arquivo do Projeto',
      siteExtensionarmCreate:
        'Adicionar Braço de Extensão ao Local de Trabalho',
      taskCreate: 'Criar Tarefa',
      taskEdit: 'Detalhes da Tarefa',
      geofenceAlertList: 'Alerta de Cerca Virtual',
      geofenceAlertShow: 'Detalhes do Alerta de Cerca Virtual',
      geofenceCreate: 'Criar Cerca Virtual',
      geofenceEdit: 'Editar Cerca Virtual',
      compactionWorkAreaCreate: 'Adicionar Áreas de Trabalho de Compactação',
      compactionLayerCreate: 'Criar Camadas de Compactação',
      compactionLayerEdit: 'Editar Camadas de Compactação',
      siteFileEdit: 'Detalhes',
      siteFileCreate: 'Adicionar Arquivo ao Local de Trabalho',
      retrofitList: 'Lista de Máquinas',
      retrofitEdit: '',
      retrofitRentalCreate: 'Registro de Atribuição',
      retrofitTransferCreate: 'Registro de Transferência',
      retrofitSendBack: 'Registro de Envio de Volta',
      bucketList: 'Lista de Balde',
      bucketEdit: 'Detalhes da Balde',
      bucketCreate: 'Balde de Caçamba',
      bucketRentalCreate: 'Compartilhamento de Balde',
      extensionarmList: 'Lista de Braço Extensor',
      extensionarmEdit: 'Detalhes do Braço Extensor',
      extensionarmShare: 'Compartilhamento do Braço Extensor',
      retrofitBulkActionList: 'Lista de Registro em Massa',
      retrofitBulkActionCreate: 'Registro em Massa',
      retrofitBasicInfoList: 'Lista de Controladores',
      retrofitBasicInfoCreate: 'Registro do Controlador',
      retrofitBasicInfoEdit: 'Atualização do Controlador',
      gnssSettingList: 'Lista de Configurações Ntrip',
      gnssSettingCreate: 'Criar Configuração Ntrip',
      gnssSettingAdminCreate: 'Criar Configuração Ntrip',
      gnssSettingOwnerCreate: 'Criar Configuração Ntrip',
      gnssSettingEdit: 'Editar Configuração Ntrip',
      gnssSettingAdminEdit: 'Editar Configuração Ntrip',
      gnssSettingOwnerEdit: 'Editar Configuração Ntrip',
      approvalList: 'Lista de Máquinas',
      retrofitRentalEdit: 'Detalhes da Atribuição',
      retrofitTransferEdit: 'Detalhes da Transferência',
      asbuiltsCreate: 'Resultado da Construção Adquirida',
      asbuiltsShow: 'Histórico de Construção',
      retrofitConfigList: 'Informações de Configuração',
      retrofitKitInfoList: 'Informações do Controlador',
      retrofitAccuraciesList: 'Resultado da Verificação de Precisão',
      retrofitAccuraciesShow: 'Detalhes',
      retrofitCalibaList: 'Informações de Calibração',
      retrofitCalibInfo: 'Detalhes da informação de calibração da máquina',
      retrofitBasicSettingList: 'Informações da Configuração Básica',
      retrofitErrorList: 'Informações de Erro',
      retrofitErrorShow: 'Detalhes',
      retrofitTopographicSurveyList: 'Lista de levantamentos-topo.',
      retrofitTopographicSurveyShow: 'Detalhes do Levantamento Topográfico',
      retrofitShareFileList: 'Registro de Aplicação',
      retrofitCorporationLicenseList: 'Lista de Licenças',
      retrofitCorporationLicenseEdit: 'Detalhes da Licença',
      CompactionLayerList: 'Camadas de Compactação',
      userNoticeList: 'Lista de Notificações',
      userNoticeCreate: 'Adicionar Notificação',
      userNoticeEdit: 'Atualizar Notificação',
      userNoticeDrawer: 'Notificação',
      retrofitAlternateRegists: 'Lista de Registro de Proxy do Controlador',
      firmwareList: 'Lista de Firmwares',
      firmwareShow: 'Detalhes do Firmware',
      firmwareCreate: 'Adicionar Firmware',
      retrofitManage: 'Texto',
      retrofitNamePlateFiles: 'Foto',
      retrofitManageAdminBulkAction: 'Registro em Massa',
      retrofitManageAdminBulkActionCreate: 'Registro em Massa',
      retrofitAlternateRegistCreate: 'Registro de Proxy do Controlador',
      retrofitAlternateRegistEdit:
        'Detalhes do Registro de Proxy do Controlador',
      retrofitAlternateRegistCreateText: 'Texto',
      retrofitAlternateRegistCreatePhoto: 'Foto',
      ntripList: 'Informações do Ntrip',
      ntripEdit: 'Gestão de Ntrip',
    },
    label: {
      login: 'Login',
      selected: 'Selecionado (%{length} casos)',
      listNoResults: 'Dados não encontrados',
      maintenance: {
        title: 'Atualmente em manutenção.',
        message: `Temporariamente suspendemos os serviços devido a manutenção.
      Pedimos desculpas pelo inconveniente, por favor, aguarde um momento a mais.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Solte ou selecione o(s) arquivo(s) (%{fileTypes}) para enviar.',
        maxFileNum:
          'O número máximo de controladores que podem ser registrados de uma só vez é cinco.',
      },
      nosetting: 'Sem Configuração',
      siteRetrofitList: {
        remoteSupport: 'Suporte Remoto',
      },
      retrofitEdit: {
        breadcrumbs: 'Detalhes',
        belongsDetails: '',
        ntripSettingInfo: 'Informações da Configuração Ntrip',
        machineryDetails: 'Máquina',
        retrofitDetails: 'Controlador',
        basicInfo: 'Informações Básicas',
        controller: 'Controlador',
        reciverMain: 'Recetor GNSS principal',
        reciverSub: 'Subrecetor GNSS',
        retrofitstatus: 'Estado do controlador',
        license: 'Licença',
        licenseId: 'ID da Licença',
        expiredDate: 'Data de Expiração',
      },
      retrofitRental: {
        rentalDetails: 'Detalhes da Atribuição',
        rentalInfo: 'Detalhes da Atribuição',
        lending: 'Atribuição',
        return: 'Retorno',
        lendingDestination: 'Empresa Designada',
        lendingCompany: 'Nome da Empresa',
        lendingEmail: 'E-mail',
        companyName: 'Nome da Empresa',
      },
      retorfitTransfer: {
        tab: 'Transferência',
        transferInfo: 'Detalhes da Transferência',
        transferButton: 'Transferência',
        transferEditButton: 'Editar Transferência',
      },
      siteConfigureEdit: {
        onButton: 'LIGADO',
        offButton: 'DESLIGADO',
      },
      approvals: {
        rentalTab: 'Atribuição',
        transferTab: 'Transferência',
      },
      buckets: {
        bucketTab: 'Balde',
        extensionarmTab: 'Braço Extensor',
        extensionarmFile: 'Arquivo do Braço Extensor',
        shareTo: 'Compartilhar Com',
      },
      bucketRental: {
        corporation: 'Compartilhar Com',
        bucketType: 'Tipo',
        bk3File: 'Arquivo BK3',
      },
      retrofitBasic: {
        basicInfoTab: 'Informações Básicas',
        bulkActionTab: 'Registro em Massa',
        ntripTab: 'Gestão de Ntrip',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Enviar de Volta',
        initialize: 'Inicialização',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Atualização em Massa',
        retrofitDistributor: 'Distribuidor',
        retrofitRegion: 'Região',
        corporationId: 'ID da Empresa',
        corporationName: 'Nome da Empresa',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'ID da Empresa em Uso',
        corporationName: 'Empresa em Uso',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Nome da Empresa',
      },
      terms: {
        title: 'TERMOS DE UTILIZAÇÃO',
        message: 'Clique no link abaixo para revisar os Termos de Uso.',
        confirmMessage1:
          'Para usar o Smart Construction Pilot, você deve concordar com o(s) seguinte(s) documento(s).',
        confirmMessage2:
          'Se você não concorda, não poderá usar o Smart Construction Pilot.',
        select: 'Por favor, selecione sua região.',
      },
      downloads: {
        title: 'Conversor de Arquivo do Projeto',
        message:
          'Este é um aplicativo autônomo que cria um arquivo de projeto a partir de arquivos de design.',
        button: 'Download',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Numeração Automática',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Download',
        resultFileDownload: 'Download',
      },
      tasks: {
        coordinate: 'Coordenadas (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Tamanho do Bloco (%{unit})',
      },
      compactionLayers: {
        thickness: 'Espessura (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Detalhes',
        rentalDetail: 'Detalhes da Atribuição',
      },
      pendingTransfers: {
        breadcrumbs: 'Detalhes',
        transferDetail: 'Detalhes da Transferência',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Detalhes',
        alternateRegistDetail: 'Detalhes do Registro de Proxy do Controlador',
      },
      supportMode: {
        retrofit: 'Máquina',
        optional: 'Opcional',
      },
      retrofitList: {
        assignment: 'Atribuição',
        license: 'Licença',
      },
      userNotices: {
        languageDetails: 'Conteúdo da Notificação',
        language: 'Idioma',
        languageEn: 'Inglês',
        languageJa: 'Japonês',
        languageFr: 'Francês',
        languageDe: 'Alemão',
        languageEs: 'Espanhol',
        Information: 'Notificação',
        Maintenance: 'Manutenção',
        emergency: 'Emergência',
        important: 'Importante',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Registro do Controlador',
        corporationName: 'Nome da Empresa',
        basicInfoSerialNumber: 'Número de Série',
        confirmInput: 'Reinsira o Número de Série',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Número de Série',
        status: 'Estado',
        imageFile: 'Imagem',
        message1:
          'Por favor, tire uma foto do número de série do controlador ou do código QR que esteja claro e sem desfoque.',
        message2: 'As imagens devem ter menos de %{mbyte}MB de tamanho.',
        errormessage:
          'O número máximo de controladores que podem ser registrados de uma só vez é cinco.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Válido',
          disable: 'Inválido',
          unLicense: 'Não Alocado',
        },
        machine: 'Máquina',
        before: 'Antes',
        after: 'Após',
      },
      geofences: {
        project: 'Projeto',
        projectSourceLayer: 'Camada',
        rgb: 'Cor (RGB)',
        select: 'Seleção de cerca virtual',
        coordinates: 'Coordenadas',
        coordinatesLabel: 'Coordenadas (N,E)',
        unitLength: 'Unidade:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Coordenadas (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Raio',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Registro de Proxy do Controlador',
        corporationName: 'Nome da Empresa',
        email: 'E-mail',
        basicInfoSerialNumber: 'Número de Série',
        confirmInput: 'Reinsira o Número de Série',
      },
      ntripList: {
        unusedTotal: 'Total Não Utilizado',
      },
      calibrationDetail: {
        breadcrumbs: 'Detalhes',
        machineInfo: 'Informações da máquina',
        machineGeometryInfo: 'Informações de geometria da máquina',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'Informações do suporte da IMU',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Informações do suporte da principal GNSS',
        bodyCenterToGnssSub: 'Informações do suporte da secundário GNSS',
        extendedModel: 'Modelo expandido',
        measuredValues: 'Informações de calibração',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Erro de conversão de dados',
        system_localization_quantity_error: `Não é possível registar vários ficheiros com informações de localização.
      Registe apenas um ficheiro que contenha informações de localização.`,
        system_detect_0byte_file_error: `O registo do ficheiro no servidor falhou devido a um erro de comunicação.
      Volte a registar o ficheiro.`,
        system_batch_error: 'Erro do Sistema (P001)',
        system_parameter_error: 'Erro do Sistema (P002)',
        system_parameterfile_notfound: 'Erro do Sistema (P003)',
        system_exception: 'Erro do Sistema (P004)',
        system_logfile_error: 'Erro do Sistema (P005)',
        system_parameterfile_parameter_error: 'Erro do Sistema (P006)',
        desing_localization_error: `Não há informações de localização disponíveis.
      Por favor, registre um dos seguintes arquivos:
     
      ・Arquivo GC3
      ・Arquivo TP3 contendo pontos de controle
      ・Arquivo CAL`,
        desing_no_support_dxf_error:
          'O(s) arquivo(s) DXF não puderam ser lidos porque sua versão é mais antiga que o AutoCAD2000.',
        desing_tile_error: 'Falha ao criar o arquivo do projeto.',
        desing_outline_error: 'Falha na criação da linha de limite externo.',
        desing_area_length_error:
          'O processo foi abortado porque a área do projeto é muito grande. Por favor, verifique se os dados de design e a escala dos dados CAD estão corretos.',
        desing_layer_name_duplication_error: `Os dados do projeto com nomes duplicados de camadas não podem ser registrados.
      Por favor, altere o nome da camada de design ou registre para que haja apenas um arquivo com o mesmo nome de camada de design.`,
        targetFiles: '[Nome do Arquivo]',
        targetLayers: '[Camada]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Nenhum',
        system_batch_error: 'Erro do Sistema (T001)',
        system_parameter_error: 'Erro do Sistema (T002)',
        system_parameterfile_notfound: 'Erro do Sistema (T003)',
        system_exception: 'Erro do Sistema (T004)',
        system_logfile_error: 'Erro do Sistema (T005)',
        system_basefile_notfound: 'Erro do Sistema (T006)',
        system_prjfile_multi: 'Erro do Sistema (T007)',
        system_basefile_read_error: 'Erro do Sistema (T008)',
        system_target_layer_notfound: 'Erro do Sistema (T009)',
        system_target_layer_duplication: 'O mesmo nome de camada já existe.',
        system_taskarea_error: 'Área de tarefa inválida.',
        system_taskarea_design_notfound:
          'Não há dados para o arquivo do projeto dentro da área da tarefa (TIN, linhas, pontos).',
        system_tile_error: 'Falha ao criar o arquivo do projeto.',
        system_outline_error: 'Falha na criação da linha de limite externo',
        system_parameterfile_error: 'Erro do Sistema',
      },
      retrofitError: {
        1000: 'Dados UDP do controlador não detetados',
        2000: 'IMU do corpo não detetada',
        2001: 'Erro no hardware da IMU da carroçaria',
        2002: 'Erro no algoritmo da IMU da carroçaria',
        2003: 'Erro nos dados da IMU da carroçaria',
        2004: 'Erro na inicialização da IMU da carroçaria',
        2100: 'IMU da lança não detetada',
        2101: 'Erro no hardware da IMU da lança',
        2102: 'Erro no algoritmo da IMU da lança',
        2103: 'Erro nos dados da IMU da lança',
        2104: 'Erro na inicialização da IMU da lança',
        2200: 'IMU do braço não detetada',
        2201: 'Erro no hardware da IMU do braço',
        2202: 'Erro no algoritmo da IMU do braço',
        2203: 'Erro nos dados da IMU do braço',
        2204: 'Erro na inicialização da IMU do braço',
        2300: 'IMU do balde não detetada',
        2301: 'Erro no hardware da IMU do balde',
        2302: 'Erro no algoritmo da IMU do balde',
        2303: 'Erro nos dados da IMU do balde',
        2304: 'Erro na inicialização da IMU do balde',
        2400: 'IMU do balde inclinável não detetada',
        2401: 'Erro no hardware da IMU do balde inclinável',
        2402: 'Erro no algoritmo da IMU do balde inclinável',
        2403: 'Erro nos dados da IMU do balde inclinável',
        2404: 'Erro na inicialização da IMU do balde inclinável',
        2500: 'IMU da 2.ª lança não detetada',
        2501: 'Erro no hardware da IMU da 2.ª lança',
        2502: 'Erro no algoritmo da IMU da 2.ª lança',
        2503: 'Erro nos dados da IMU da 2.ª lança',
        2504: 'Erro na inicialização da IMU da 2.ª lança',
        2600: 'Sensor de rotação não detetado',
        2601: 'Erro no sistema do sensor da rotação',
        2602: 'Erro de hardware do sensor de rotação',
        2700: 'Sensor de desvio não detetado',
        2701: 'Erro no sistema do sensor de desvio',
        2702: 'Erro no hardware do sensor de desvio',
        3000: 'Sensor de pressão do lado da cabeça não detetado',
        3100: 'Sensor de pressão do lado inferior não detetado',
        4000: 'Antena GNSS não detetada',
        5000: 'Informações de Correção não detectadas',
        5001: 'Sinal do recetor de rádio não detetado',
      },
    },
    dialog: {
      menu: {
        confirmManualLink: 'Você será redirecionado para outro site. Está bem?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Balde',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'O ID da Empresa e o Nome da Empresa são necessários.',
        },
        save: {
          message1: 'O ID/Nome da Empresa não pode ser alterado porque inclui',
          message2: 'máquina(s) atribuída(s) a outra Empresa.',
          message3: '',
          message4: 'Por favor, verifique o(s) número(s) de série abaixo.',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Controlador',
        },
        undeletable: {
          message: 'O controlador está em uso e não pode ser excluído.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Deseja parar esta Configuração Ntrip?',
          delete: 'Deseja excluir a Configuração Ntrip?',
        },
      },
      siteRetrofitList: {
        title: 'Informação Suplementar',
        message: 'O período de construção terminou.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Configuração Comum',
          siteSetting: 'Configurações do Local de Trabalho',
        },
        mapBackgroundColor: 'Cor de Fundo do Mapa',
        message:
          'A função de deteção de contactos/notificação de alertas da geocerca pode não funcionar devidamente dependendo do ambiente e das condições de utilização. Não utilize a função com excesso de confiança e certifique-se de que compreende a função e as condições de utilização.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Deseja excluir esta cerca virtual?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'A máquina está vinculada a um local de trabalho e não pode ser atribuída. Por favor, desconecte a máquina do local de trabalho.',
          message2: 'Por favor, desconecte a máquina do local de trabalho.',
        },
        sameOwner: {
          message:
            'A máquina não pode ser atribuída porque sua empresa é a proprietária.',
        },
        unableTransfer: {
          message:
            'A máquina está aguardando aprovação de transferência e não pode ser registrada para atribuição.',
        },
        unableAlternateRegist: {
          message:
            'A máquina está aguardando aprovação de Registro de Proxy do Controlador e não pode ser registrada para atribuição.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'A máquina está vinculada a um local de trabalho e não pode ser transferida. Por favor, desconecte a máquina do local de trabalho.',
        messageRentaled: 'A máquina está atribuída e não pode ser transferida.',
        messageLicense: 'A máquina tem uma licença e não pode ser transferida.',
        pendingApprovals: {
          message1:
            'A solicitação de transferência foi recebida. Por favor, aguarde a aprovação.',
          message2:
            'Se você tiver alguma dúvida, entre em contato conosco pelo URL abaixo.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Deseja devolver o controlador e herdar as configurações para o controlador de destino?',
        deleteNtrip: 'Deseja inicializar o controlador devolvido?',
        unableRegion:
          'Este controlador foi enviado para o exterior. Se este controlador estiver correto, por favor, redefina a "Região" para nulo.',
        unableCorporation: {
          message1: 'O controlador não pode ser devolvido.',
          message2:
            'Se você acredita que isso é um erro, entre em contato com o seu revendedor.',
        },
        retrofitRentaled:
          'O controlador está atribuído e não pode ser devolvido.',
        tiedToSite:
          'A máquina está vinculada a um local de trabalho e não pode ser devolvida.',
      },
      retrofitAddToSiteAction: {
        title: 'Informação Suplementar',
        message:
          'Existe uma máquina aguardando aprovação, portanto, não pode ser vinculada ao local de trabalho.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Informação Suplementar',
        message:
          'A máquina está sendo transferida e não pode ser vinculada ao local de trabalho.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message:
            'A configuração Ntrip selecionada está sendo enviada para outra máquina.',
        },
        unableTransferInfo: {
          message:
            'Para transferir, por favor cancele as seguintes configurações.',
          reasonSiteId: 'Local de Trabalho',
          reasonRentaled: 'Atribuição',
          reasonLicense: 'Licença',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'A empresa designada está atualmente atribuindo a máquina a outra empresa. A data de início da atribuição para outra empresa é %{date}.',
          message2: 'Por favor, selecione uma data de término após %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            'A máquina está vinculada a um local de trabalho na empresa designada. Deseja encerrar esta atribuição?',
        },
        confirmDoubleRental: {
          message:
            'A empresa designada está atualmente atribuindo a máquina a outra empresa.\nA empresa designada está atualmente atribuindo a máquina a outra empresa. Definir uma data de término também encerra a atribuição para outra empresa. Deseja definir a data de término?',
        },
        unapprovedSave: {
          message: 'Esta atribuição não foi aprovada e não pode ser salva.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Deseja excluir esta atribuição?',
        },
        approvedDelete: {
          title: '',
          message: 'Esta atribuição foi aprovada e não pode ser excluída.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message:
            'A máquina está atualmente atribuída e não pode ser transferida.',
        },
        approvedSave: {
          message: 'A máquina tem uma licença e não pode ser transferida.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Deseja excluir esta transferência?',
        },
        approvedDelete: {
          title: '',
          message: 'Esta transferência foi aprovada e não pode ser excluída.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Braço Extensor',
        },
      },
      projectFileUpload: {
        message:
          'Não é possível registrar vários arquivos de ponto de controle (.gc3/.cal).',
      },
      projectEdit: {
        confirm: {
          message:
            'Atualizar o projeto irá excluir a(s) tarefa(s) existente(s). Deseja atualizar?',
        },
      },
      projectConversionStatus: {
        title: 'Status de Conversão',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Status de Conversão',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message:
            'A tarefa selecionada não pode ser excluída porque está em uso.',
        },
        unableToCreate: {
          message: 'Não há dados de design registrados no projeto.',
        },
        unableToDelete: {
          message1:
            'A tarefa não pode ser excluída porque o status do projeto e o status da tarefa são os seguintes.',
          message2: 'Projeto: Convertendo',
          message3: 'Tarefa: Erro',
        },
        unableToUpdate: {
          message1:
            'A tarefa não pode ser editada porque o status do projeto e o status da tarefa não são os seguintes.',
          message2: 'Projeto: [Convertido]',
          message3:
            'Tarefa: [Transferindo][Transferida][Falha na Transferência]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Tarefa',
        },
        unableToDelete: {
          message:
            'A tarefa selecionada não pode ser excluída porque está em uso.',
        },
      },
      taskMap: {
        crossingMessage: 'A área da tarefa está intersectada.',
        sameValueMessage: 'As mesmas coordenadas foram inseridas.',
        areaZeroMessage: 'Não há área para a área da tarefa.',
        noPolygonMessage:
          'A tarefa não pode ser registrada porque ainda não está registrada.',
      },
      compactionMaterials: {
        add: {
          title: 'Adicionar um Material',
        },
        update: {
          title: 'Atualizar um Material',
        },
      },
      weathers: {
        add: {
          title: 'Adicionar um Clima',
        },
        update: {
          title: 'Atualizar um Clima',
        },
      },
      operators: {
        add: {
          title: 'Adicionar um Operador',
        },
        update: {
          title: 'Atualizar um Operador',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Editar Áreas de Trabalho de Compactação',
        },
        undeletable: {
          message:
            'A camada de compactação incluída na área de construção está em uso e não pode ser excluída.',
        },
      },
      compactionLayers: {
        undeletable: {
          message:
            'A camada de compactação está em uso e não pode ser excluída.',
        },
        deleteConfirm: {
          messageTargetItem: 'Camada de Compactação',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message:
            'Outra Configuração Ntrip está sendo enviada para a máquina selecionada.',
        },
        confirmDelete: {
          message: 'Deseja excluir a Configuração Ntrip?',
        },
        confirmDeleteWithMachine: {
          message:
            'A máquina está atualmente vinculada. Deseja excluir a configuração Ntrip?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Notificação',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Defina a(s) licença(s) para a seguinte máquina.',
        },
        changeDisable: {
          message:
            'Por favor, altere o ID da licença da seguinte máquina de construção para válido.',
        },
        changeTemporary: {
          message:
            'Defina a(s) licença(s) para a seguinte máquina. A licença temporária atualmente definida será invalidada.',
        },
        errorAdd: {
          message:
            'A licença da máquina não pode ser alterada porque todas as licenças já foram definidas.',
        },
        errorSameType: {
          message:
            'A licença da máquina não pode ser alterada porque o mesmo tipo de licença já foi definido.',
        },
        errorUnlimited: {
          message:
            'A máquina já tem a licença ilimitada definida, outras licenças não podem ser definidas.',
        },
        addAll: {
          message:
            'A máquina a seguir já teve licença(s) configurada(s). Deseja definir Todas as licenças?',
        },
        compare: {
          message: 'Alterar a(s) licença(s) para a seguinte máquina.',
        },
        warning: {
          message:
            'O %{retrofit} tem licença de %{id}. Deseja desconectar a licença?',
        },
        unableTransfer: {
          message:
            'A máquina está sendo transferida, então a licença não pode ser vinculada.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial:
            'Erro do Sistema. Existem dados que não foram analisados na imagem.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            'A solicitação de registro foi recebida. Por favor, aguarde até que seja aprovada.',
          message2:
            'Se você tiver alguma dúvida, entre em contato conosco pelo URL abaixo.',
        },
        corporationIdCheck: {
          message1:
            'O controlador já foi registrado. Por favor, verifique procurando nas máquinas.',
          message2:
            'Se você não consegue encontrar o controlador de destino na lista, entre em contato conosco pelo URL abaixo.',
        },
        earthbrainIdCheck: {
          message1: 'Pedimos desculpas pelo inconveniente,',
          message2:
            'mas por favor entre em contato conosco pelo URL abaixo com as seguintes informações.',
          message3: '-Número de Série do Controlador',
          message4: '-Nome da máquina a ser registrada (opcional)',
          message5: '-ID da Empresa a ser registrada',
        },
        invalidSerialNumber: {
          message1: 'O controlador já está em uso por outra empresa.',
          message2: 'Se desejar alterá-lo por outros motivos,',
          message3:
            'Por favor, entre em contato conosco no URL abaixo com as seguintes informações.',
          message4: '-Número de Série do Controlador',
          message5: '-Nome da máquina a ser registrada (opcional)',
          message6: '-ID da Empresa a ser registrada',
          message7: '-Razão para a mudança',
        },
        lendingRetrofits: {
          message1: 'O controlador está atribuído.',
          message2:
            'Por favor, reaplique após o término da atribuição na empresa designadora.',
          message3:
            'Se você tiver alguma dúvida, entre em contato conosco pelo URL abaixo.',
        },
        siteAttached: {
          message1: 'O controlador está vinculado a um local de trabalho.',
          message2:
            'Por favor, reaplique após desconectar a máquina do local de trabalho.',
          message3:
            'Se você tiver alguma dúvida, entre em contato conosco pelo URL abaixo.',
        },
        serialNumberExistError: {
          message1:
            'Este é o número de série de um controlador que não existe. Por favor, certifique-se de que o número de série do controlador que você digitou está correto.',
          message2:
            'Se o problema persistir, entre em contato conosco pelo URL abaixo.',
        },
        ntripShortage: {
          message1: 'Não conseguimos emitir o seu ID de usuário e senha Ntrip.',
          message2: 'Por favor, entre em contato conosco no URL abaixo.',
        },
        serialnumberCheck: {
          message:
            'Por favor, verifique os produtos com o(s) seguinte(s) número(s) de série',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'Registros alternativos de retrofitagem',
        },
        saveValidation: {
          message: 'O e-mail é obrigatório.',
        },
        pendingApprovals: {
          message1:
            'Solicitação de registro recebida. Por favor, aguarde até que seja aprovada.',
          message2:
            'Se você tiver alguma dúvida, entre em contato conosco pelo URL abaixo.',
        },
        corporationIdCheck: {
          message1:
            'O controlador já foi registrado. Por favor, verifique procurando nas máquinas.',
          message2:
            'Se você não consegue encontrar o controlador de destino na lista, entre em contato conosco pelo URL abaixo.',
        },
        earthbrainIdCheck: {
          message1: 'Pedimos desculpas pelo inconveniente,',
          message2:
            'mas por favor entre em contato conosco pelo URL abaixo com as seguintes informações.',
          message3: '-Número de Série do Controlador',
          message4: '-Nome da máquina a ser registrada (opcional)',
          message5: '-ID da Empresa a ser registrada',
        },
        invalidSerialNumber: {
          message1: 'O controlador já está em uso por outra empresa.',
          message2: 'Se desejar alterá-lo por outros motivos,',
          message3:
            'Por favor, entre em contato conosco no URL abaixo com as seguintes informações.',
          message4: '-Número de Série do Controlador',
          message5: '-Nome da máquina a ser registrada (opcional)',
          message6: '-ID da Empresa a ser registrada',
          message7: '-Razão para a mudança',
        },
        lendingRetrofits: {
          message1: 'O controlador está atribuído.',
          message2:
            'Por favor, reaplique após o término da atribuição na empresa designadora.',
          message3:
            'Se você tiver alguma dúvida, entre em contato conosco pelo URL abaixo.',
        },
        siteAttached: {
          message1: 'O controlador está vinculado a um local de trabalho.',
          message2:
            'Por favor, reaplique após desconectar a máquina do local de trabalho.',
          message3:
            'Se você tiver alguma dúvida, entre em contato conosco pelo URL abaixo.',
        },
        serialNumberExistError: {
          message1:
            'Este é o número de série de um controlador que não existe. Por favor, certifique-se de que o número de série do controlador que você digitou está correto.',
          message2:
            'Se o problema persistir, entre em contato conosco pelo URL abaixo.',
        },
        ntripShortage: {
          message1: 'Não conseguimos emitir o seu ID de usuário e senha Ntrip.',
          message2: 'Por favor, entre em contato conosco no URL abaixo.',
        },
        serialnumberCheck: {
          message:
            'Por favor, verifique os produtos com o(s) seguinte(s) número(s) de série',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'Este controlador já pertence à sua empresa.',
          message2: 'Por favor, entre em contato com a empresa requerente.',
        },
        userCorporation: {
          message1:
            'Você não pode aprovar este pedido porque já está emprestando este controlador.',
          message2: 'Por favor, entre em contato com a empresa requerente.',
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `Existe uma máquina a ser utilizada no local sem uma licença. 
        É necessária uma licença para utilização no local. 
        Se não tiver adquirido uma licença, adquira uma no Smart Construction Marketplace.`,
        message2: `
        Se estiver a alugar a outra empresa, solicite uma licença para a máquina à fonte de aluguer. 
        
        Se tiver alguma dúvida, consulte o seguinte sítio de apoio.`,
      },
    },
    tootip: {
      retrofitShareFileList: 'Registro de Aplicação',
      retrofitConfigList: 'Informações de Configuração',
      retrofitKitInfoList: 'Informações do Controlador',
      retrofitAccuraciesList: 'Resultado da Verificação de Precisão',
      retrofitCalibaList: 'Informações de Calibração',
      retrofitBasicSettingList: 'Configuração Básica',
      retrofitErrorList: 'Detalhes do Erro',
      retrofitTopographicSurveyList: 'Levantamento Topográfico',
    },
    actions: {
      ok: 'OK',
      add: 'Adicionar',
      create: 'Registro',
      yes: 'Sim',
      no: 'Não',
      confirmation: 'Confirmar',
      save: 'Guardar',
      cancel: 'Cancelar',
      required: 'Obrigatório',
      addMachineToSite: 'Adicionar Máquina ao Local de Trabalho',
      addBucketToSite: 'Adicionar',
      deleteFromSite: 'Desconectar do Local de Trabalho',
      removeLicense: 'Desconectar da Máquina',
      lendMachine: 'Atribuição',
      transferMachine: 'Transferência',
      remoteSupport: 'Suporte Remoto',
      lendBucket: 'Compartilhar',
      approval: 'Aprovação',
      connect: 'Conectar',
      creationInstructions: 'Resultado da Construção Adquirida',
      csvDownload: 'Download',
      retrofitBundleCreate: 'Registro em Massa',
      termsAccept: 'Concordar',
      termsDeny: 'Discordar',
      bulkRegistration: 'Atualização em Massa',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Itens de Compactação',
      addLanguage: 'Adicionar Idioma',
    },
    message: {
      error: 'Ocorreu um erro',
      confirm: 'Está OK?',
      fileUpload: 'Arraste e solte os arquivos aqui.',
      success: 'O registro foi concluído',
      approved: 'Aprovação concluída',
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Deseja excluir %{title}?',
      bulkDeleteConfirmContent: 'Deseja excluir %{title}?',
      disconnectFromSite: 'Deseja desconectar do local de trabalho?',
      saveConfirmContent: 'Confirmar',
      removeLicense: 'Deseja desconectar da máquina?',
      task: {
        crossingMessage: 'A área da tarefa está intersectada.',
        sameValueMessage: 'As mesmas coordenadas foram inseridas.',
        areaZeroMessage: 'Não há área para a área da tarefa.',
        invalidPolygonMessage: 'As mesmas coordenadas foram inseridas.',
      },
      geofence: {
        crossingMessage: 'A área da cerca virtual está intersectada.',
        sameValueMessage: 'As mesmas coordenadas foram inseridas.',
        areaZeroMessage: 'Não há área para a área da cerca virtual.',
      },
      licenseError:
        'Você não tem uma licença para o Smart Construction Pilot (Aplicativo da Web).',
      retrofitsManage: {
        serialNumberMatchError: 'O número de série não corresponde.',
        serialNumberInputError: 'O número de série não existe.',
        invalidAccess:
          'Você não está autorizado a visualizar esta página, volte para a página inicial.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'O número de série não corresponde.',
        serialNumberInputError: 'O número de série não existe.',
        retrofitRentaled:
          'As informações da Empresa não podem ser atualizadas enquanto o controlador estiver em atribuição.',
        invalidCorporation:
          'Como sua empresa não é um distribuidor, você não pode fazer o registro proxy do controlador.',
      },
    },
    validation: {
      startDate: 'Data de Início',
      endDate: 'Data Final',
      invalidDateFormat: 'O formato de data é inválido.',
      invalidDateTimeFormat: 'O formato de data e hora é inválido.',
      duplicateProject: 'O nome do projeto está duplicado.',
      incorrectBeforeDate:
        '%{target} deve ser definido para uma data antes de %{before}.',
      incorrectAfterDate:
        '%{target} deve ser definido para uma data após %{after}.',
      incorrectPlanStartDate:
        'A data de início agendada deve ser antes da data de término agendada.',
      incorrectPlanEndDate:
        'A data de término agendada deve ser uma data após a data de início agendada.',
      incorrectActualStartDate:
        'A data de início deve ser uma data antes da data de término.',
      incorrectActualEndDate:
        'A data de término deve ser uma data após a data de início.',
      withActualStartDate: 'Por favor, insira também a data de início.',
      inputAvailableDate: 'A data que pode ser inserida é a partir de %{date}.',
      registrationRequired:
        'Por favor, selecione a empresa designada ou o endereço de e-mail.',
      bothInput:
        'Você só pode inserir a empresa atribuída ou o endereço de e-mail.',
      supportMode:
        'Por favor, insira um ID de Empresa ou ID de Local de Trabalho.',
      invalidBkFile: 'Não é um arquivo BK3.',
      invalidPdfFile: 'Não é um arquivo PDF.',
      duplicatedBucketName: 'O nome da balde está duplicado.',
      duplicatedTaskName: 'O nome da tarefa está duplicado.',
      duplicateGeofenceName: 'O nome da cerca virtual está duplicado.',
      duplicatedCompactionWorkAreaName:
        'Nome da área de trabalho de compactação duplicado',
      duplicatedCompactionLayerName: 'Nome da camada de compactação duplicado',
      duplicated: {
        compactionMaterials: {
          message: 'Nome do material duplicado',
        },
        weathers: {
          message: 'Nome do clima duplicado',
        },
        operators: {
          message: 'Nome do operador duplicado',
        },
      },
      containInvalidString:
        'Caracteres inválidos estão incluídos. (%{msg} não é permitido)',
      validHalfWidthAlphanumeric:
        'Apenas caracteres alfanuméricos de meia largura são permitidos.',
      validNumberFormat: 'Não é um número.',
      validIntegerFormat: 'Não é um número inteiro.',
      validIntegerRangeFormat: 'A entrada é permitida de 0 a 2147483647.',
      validDuplicateLanguage: 'O mesmo idioma está selecionado.',
      validBeforeDate:
        '%{target} deve ser definido para uma data e hora antes de %{before}.',
      validAfterDate:
        '%{target} deve ser definido para uma data e hora após %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Local de Trabalho',
      fields: {
        name: 'Local de Trabalho',
        corporationName: 'Nome da Empresa',
        status: 'Estado',
        workPeriodStartDate: 'Data de Início',
        workPeriodEndDate: 'Data Final',
      },
      status: {
        completed: 'Concluído',
        waiting: 'Antes do Início da Construção',
        working: 'Em Construção',
      },
    },
    retrofits: {
      name: 'Máquina',
      fields: {
        information: 'Informações da Máquina',
        rental: 'Atribuição',
        sendBack: 'Enviar de Volta',
        corporationId: 'Empresa em Uso',
        siteId: 'Local de Trabalho',
        uuid: 'UUID',
        isError: 'Estado',
        isOnline: 'Online',
        lastOnlineTime: 'Última Conexão',
        basicInfoManufacturer: 'Fabricante',
        basicInfoModel: 'Modelo',
        basicInfoProductNumber: 'Nº do Produto',
        basicInfoSerialNumber: 'Nº de Série',
        controllerManufacturer: 'Fabricante',
        controllerModel: 'Modelo',
        controllerFirmwareVersion: 'Ver. do Firmware',
        gnssReceiverMainManufacturer: 'Fabricante',
        gnssReceiverMainModel: 'Modelo',
        gnssReceiverMainFirmwareVersion: 'Ver. do Firmware',
        gnssReceiverSubManufacturer: 'Fabricante',
        gnssReceiverSubModel: 'Modelo',
        gnssReceiverSubFirmwareVersion: 'Ver. do Firmware',
        machineInfoCompanyName: 'Marcas',
        machineInfoMachineType: 'Tipo de Máquina',
        machineInfoMachineName: 'Nome da Máquina',
        machineInfoMachineId: 'ID da Máquina',
        rentalStatus: 'Atribuição',
        lastUpdated: 'Última Atualização',
      },
      isError: {
        true: 'Erro',
        false: 'Normal',
      },
      isOnline: {
        true: 'Online',
        false: 'Offline',
      },
      machineInfoMachineType: {
        excavator: 'Escavadeira',
        roadroller: 'Rolo Compactador',
        dozer: 'Trator de Esteira (Dozer)',
        rigiddump: 'Camionete Rígida',
        wheelloader: 'Carregadeira de Rodas',
        grader: 'Niveladora',
        automobile: 'Automóvel',
      },
      licenseMachineType: {
        excavator: 'Escavadeira',
        roadroller: 'Rolo Compactador',
        dozer: 'Trator de Esteira (Dozer)',
        rigiddump: 'Camionete Rígida',
        wheelloader: 'Carregadeira de Rodas',
        grader: 'Niveladora',
        automobile: 'Automóvel',
        excavator_license: 'Escavadeira : %{licenseGrantInfo}',
        roadroller_license: 'Rolo Compactador : %{licenseGrantInfo}',
        dozer_license: 'Trator de Esteira (Dozer) : %{licenseGrantInfo}',
        rigiddump_license: 'Camionete Rígida : %{licenseGrantInfo}',
        wheelloader_license: 'Carregadeira de Rodas: %{licenseGrantInfo}',
        grader_license: 'Niveladora : %{licenseGrantInfo}',
        automobile_license: 'Automóvel : %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Transferindo',
        sent: 'Transferido',
        failtosend: 'Transferência falhou',
      },
      rentalStatus: {
        approved: 'Aprovado',
        unapproved: 'Não Aprovado',
        returned: 'Retornado',
      },
    },
    retrofitRentals: {
      name: 'Atribuição',
      fields: {
        id: 'ID',
        retrofitId: 'ID da Máquina',
        fromCorporationId: 'ID da Empresa Designadora',
        fromCorporationName: 'Empresa Designadora',
        toCorporationId: 'Empresa Designada',
        toCorporationName: 'Empresa em Uso',
        retrofitRentalId: 'ID da Atribuição',
        isApproved: 'Estado',
        isReturned: 'Retornado',
        planStartDate: 'Data de Início Agendada',
        planEndDate: 'Data de Término Agendada',
        actualStartDate: 'Data de Início',
        actualEndDate: 'Data Final',
        rentalUsers: 'E-mail',
        email: 'E-mail',
        rentalStatus: 'Estado',
      },
      isApproved: {
        true: 'Aprovado',
        false: 'Não Aprovado',
      },
      rentalStatus: {
        approved: 'Aprovado',
        unapproved: 'Não Aprovado',
        returned: 'Retornado',
      },
    },
    pendingRentals: {
      name: 'Aguardando Aprovação',
      fields: {
        fromCorporationName: 'Empresa Designadora',
        planStartDate: 'Data de Início Agendada',
        planEndDate: 'Data de Término Agendada',
      },
    },
    pendingTransfers: {
      name: 'Aguardando Aprovação',
      fields: {
        fromCorporationName: 'Empresa Transferindo',
        planDate: 'Data de Transferência Agendada',
        lastUpdated: 'Última Atualização',
      },
    },
    pendingAlternateRegists: {
      name: 'Aguardando Aprovação',
      fields: {
        fromCorporationName: 'Proprietário Atual',
        lastUpdated: 'Última Atualização',
      },
    },
    assignments: {
      name: 'Transferência',
      fields: {
        id: 'ID',
        retrofitId: 'ID da Máquina',
        fromCorporationId: 'ID da Empresa Transferindo',
        fromCorporationName: 'Empresa Transferindo',
        toCorporationId: 'Empresa Transferida',
        toCorporationName: 'Empresa',
        isApproved: 'Estado',
        planDate: 'Data de Transferência Agendada',
        actualDate: 'Data de Transferência',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Última Atualização',
      },
      isApproved: {
        true: 'Aprovado',
        false: 'Não Aprovado',
      },
    },
    buckets: {
      name: 'Balde',
      fields: {
        companyName: 'Nome da Empresa',
        siteId: 'Local de Trabalho',
        name: 'Nome',
        corporationId: 'Empresa em Uso',
        bucketType: 'Tipo',
        bk3File: 'Arquivo BK3',
        'bk3File.title': 'Arquivo BK3',
        gltfFile: 'Ficheiro do balde',
        uploadedBy: 'Registrado Por',
        updatedBy: 'Atualizado Por',
        fileStatus: 'Estado',
        lastUpdated: 'Última Atualização',
        uploadBk: 'Enviar BK3',
      },
      bucketType: {
        slope: 'Inclinação',
        standard: 'Padrão',
        tilt: 'Inclinação',
      },
      uploadedBy: {
        admin: 'Administrador',
        tablet: 'Tablet',
      },
      fileStatus: {
        converting: 'Convertendo',
        completed: 'Concluído',
        error: 'Erro',
      },
    },
    projects: {
      name: 'Projeto',
      fields: {
        name: 'Nome do Projeto',
        coordinate: 'Sistema de Coordenadas',
        version: 'Versão',
        status: 'Estado',
        lastUpdated: 'Última Atualização',
        regionId: 'Região',
        projectionId: 'Projeção',
        geoidId: 'Geóide',
        datumId: 'Dado',
        file: 'Arquivo(s)',
        retrofitId: 'Máquina Alvo',
      },
      status: {
        converted: 'Concluído',
        waitforconvert: 'Convertendo',
        error: 'Erro',
      },
    },
    extensionarms: {
      name: 'Braço Extensor',
      fields: {
        extensionarmType: 'Tipo',
        name: 'Nome',
        corporationId: 'Empresa em Uso',
        corporationName: 'Empresa em Uso',
        siteId: 'Local de Trabalho',
        siteName: 'Local de Trabalho',
        lastUpdated: 'Última Atualização',
        extensionarmFile: 'Arquivo do Braço Extensor',
      },
      extensionarmType: {
        basic: 'Braço Extensor',
        a: 'Tipo A',
        b: 'Tipo B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Compartilhar Com',
        name: 'Nome',
        extensionarmType: 'Tipo',
        extensionarmFile: {
          title: 'Arquivo do Braço Extensor',
        },
      },
    },
    corporations: {
      name: 'Empresa',
      fields: {
        name: 'Empresa em Uso',
      },
    },
    retrofitShareFiles: {
      name: 'Registro de Aplicação',
      fields: {
        name: 'Nome do Arquivo',
        fileTime: 'Data e Hora de Recebimento',
        url: 'Download',
      },
    },
    retrofitConfigs: {
      name: 'Informações de Configuração',
      fields: {
        timeStamp: 'Data e Hora de Recebimento',
        url: 'Download',
      },
    },
    retrofitKitInfos: {
      name: 'Informações do Controlador',
      fields: {
        timeStamp: 'Data e Hora de Recebimento',
        url: 'Download',
      },
    },
    retrofitCalibs: {
      name: 'Informações de Calibração',
      fields: {
        timeStamp: 'Data e Hora de Recebimento',
        url: 'Download',
        detailInfo: {
          createDateTime: 'Criar data e hora',
          updateDateTime: 'Data e hora de atualização',
          machineInfo: {
            companyName: 'Marcas',
            machineType: 'Tipo de máquina',
            machineName: 'Nome da máquina',
            machineId: 'ID da Máquina',
            drumConfiguration: 'Configuração da máquina',
            guidanceMode: 'Modo de orientação',
          },
          machineGeometryInfo: {
            boomLength: 'Comprimento da lança',
            secondBoomLength: 'Comprimento da 2.ª lança',
            armLength: 'Comprimento do braço',
            armTopPinToBucketSideLinkPinLength:
              'Distância Braço ao Elo Lateral Caçamba',
            boomTopPinToBucketSideLinkPinLength:
              'Distância Lança ao Elo Lateral Caçamba',
            bucketLink1Length: 'Distância Lado Caçamba ao Cilindro',
            bucketLink2Length: 'Distância Cilindro ao Elo Caçamba',
            bodyCenterToBoomFootPin: {
              x: 'X Pino Pé Lança em relação ao Centro Corpo',
              y: 'Y Pino Pé Lança em relação ao Centro Corpo',
              z: 'Z Pino Pé Lança em relação ao Centro Corpo',
            },
            drumWidth: 'Largura da lâmina',
            length: 'Comprimento entre sapatas',
            shoeInterval: 'Comprimento do intervalo das sapatas',
            bladeWidthForDozer: 'Largura da lâmina para Dozer',
            distanceFromBodyToBlade: 'Distância do corpo à lâmina',
            frontToMainGnssAntenna:
              'Comprimento do centro de uma linha reta que liga a extremidade dianteira ligada à terra à antena principal X',
            frontToSubGnssAntenna:
              'Comprimento do centro de uma linha reta que liga a extremidade dianteira ligada à terra à frente da antena subgnss X',
            centerToMainGnssAntenna:
              'Y distância do centro ao centro da fase eléctrica do GNSS principal',
            centerToSubGnssAntenna:
              'Distância Y do centro ao centro da fase eléctrica do sub GNSS',
            groundToMainGnssAntenna:
              'Distância Z do solo ao centro de fase elétrico do GNSS principal',
            groundToSubGnssAntenna:
              'Distância Z do solo ao centro de fase elétrico do sub GNSS',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Ângulo de desvio da IMU da lança',
            secondBoomImuOffsetAngle: 'Ângulo de desvio da IMU da 2.ª lança',
            armImuOffsetAngle: 'Ângulo de desvio da IMU do braço',
            bucketImuOffsetAngle: 'Ângulo de desvio da IMU do balde',
            bodyImuOffsetRollAngle: 'Ângulo de rolamento da IMU do corpo',
            bodyImuOffsetPitchAngle: 'Ângulo de passo da IMU do corpo',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'Principal GNSS X em relação ao Centro do Corpo',
                y: 'Principal GNSS Y em relação ao Centro do Corpo',
                z: 'Principal GNSS Z em relação ao Centro do Corpo',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'Secundário GNSS X em relação ao Centro do Corpo',
                y: 'Secundário GNSS Y em relação ao Centro do Corpo',
                z: 'Secundário GNSS Z em relação ao Centro do Corpo',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Modelo da lança de rotação',
            twoPieceBoom: 'Modelo da lança de 2 peças',
            minimalSwingRadius: 'Modelo da lança de desvio',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Folga do contrapeso',
            },
            bodyImu1: {
              roll: 'Valor de medição da rotação do IMU',
              pitch: 'Inclinação do valor de medição do IMU',
              yaw: 'Yaw do valor medido do IMU',
            },
            bodyImu2: {
              roll: 'Valor de medição do rolo do IMU',
              pitch: 'Valor medido da inclinação do IMU',
              yaw: 'Valor de medição da guinada do IMU',
            },
            frontLeft: {
              x: 'Valor medido da frente esquerda X',
              y: 'Valor medido da frente esquerda Y',
              z: 'Valor medido da frente esquerda Z',
            },
            frontRight: {
              x: 'Valor medido da frente direita X',
              y: 'Valor medido da frente direita Y',
              z: 'Valor registado da frente direita Z',
            },
            frontGroundingEnd: {
              x: 'Valor registado da extremidade do contorno frontal X',
              y: 'Valor registado da extremidade do contorno frontal Y',
              z: 'Valor registado da extremidade de aterragem da frente Z',
            },
            rearGroundingEnd: {
              x: 'Valor registado da extremidade traseira X',
              y: 'Valor registado da extremidade traseira Y',
              z: 'Valor medido da extremidade de aterro traseira Z',
            },
            mainGnssAntenna: {
              x: 'Valor registado da antena GNSS principal X',
              y: 'Valor medido da antena GNSS principal Y',
              z: 'Valor registado da antena GNSS principal Z',
            },
            subGnssAntenna: {
              x: 'Valor medido da antena sub GNSS X',
              y: 'Valor medido da antena sub GNSS Y',
              z: 'Valor registado da antena sub GNSS Z',
            },
            prismPoleOffset: 'Altura do pólo do prisma',
            antennaElectricalCenterHeight:
              'Altura do centro elétrico da antena',
          },
        },
      },
    },
    retrofitAccuracies: {
      name: 'Resultado da Verificação de Precisão',
      fields: {
        checkDateTime: 'Data e Hora de Confirmação',
        surveyPoint: 'Ponto Medido',
        positionN: 'Valor Medido: N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Valor de Offset: N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Nome do Ponto de Referência',
        referencePointN: 'Ponto de Referência: N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Unidade de Comprimento',
      },
    },
    retrofitBasicSettings: {
      name: 'Configuração Básica',
      fields: {
        timeStamp: 'Data e Hora de Recebimento',
        url: 'Download',
      },
    },
    retrofitErrors: {
      name: 'Informações de Erro',
      fields: {
        notifiedTime: 'Data e Hora de Ocorrência',
        errorCodes: 'Código de Erro: Detalhes',
      },
    },
    retrofitToposurveys: {
      name: 'Lista de levantamentos-topo.',
      fields: {
        surveyDate: 'Data e Hora do Levantamento',
        projectName: 'Nome do Projeto',
        layerName: 'Camada',
        pointName: 'Nome do Ponto',
        surveyPoint: 'Ponto Medido',
        n: 'Valor Medido: N',
        e: 'E',
        z: 'Z',
        unitLength: 'Unidade de Comprimento',
      },
    },
    asbuilts: {
      name: 'Resultados da Obra',
      fields: {
        execStartDate: 'Início da Execução',
        execEndDate: 'Fim da Execução',
        startPeriod: 'Período de Aquisição De',
        endPeriod: 'Período de Aquisição Para',
        execStatus: 'Estado',
        url: '',
        csvType: 'Formato de Dados',
        createUser: 'Criar Usuário',
      },
      execStatus: {
        complete: 'Concluído',
        inprogress: 'Processando',
        error: 'Erro',
        completewithoutdata: 'Concluído (Sem Dados)',
      },
    },
    retrofitBasicInfos: {
      name: 'Informações Básicas',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Distribuidor',
        retrofitRegionId: 'Região',
        corporationId: 'ID da Empresa',
        corporationName: 'Nome da Empresa',
        basicInfoManufacturer: 'Fabricante',
        basicInfoModel: 'Modelo',
        basicInfoProductNumber: 'Nº do Produto',
        basicInfoSerialNumber: 'Nº de Série',
        lastUpdated: 'Última Atualização',
        description: 'Descrição',
        ownerId: 'ID da Empresa',
        ownerName: 'Nome da Empresa',
      },
      tag: {
        retrofitBasicInfosDetail: 'Informações Básicas',
        sendBack: 'Enviar de Volta',
      },
      sendBack: {
        fields: {
          site: 'Local de Trabalho',
          corporation: 'Empresa em Uso',
          ntripSettingInfo: 'ID do Ntrip',
          toRetrofit: 'Controlador Alternativo',
          notificationEmailAddress: 'E-mail',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Registro em Massa',
      fields: {
        uploadFileName: 'Nome do Arquivo',
        execStartDate: 'Data e Hora de Início da Execução',
        execEndDate: 'Data e Hora de Término da Execução',
        execStatus: 'Estado',
        uploadFile: 'Arquivo Registrado',
        resultFile: 'Resultados do Registro',
      },
      execStatus: {
        complete: 'Concluído',
        inprogress: 'Processando',
        error: 'Erro',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'Ação em Massa da Empresa Administradora',
      fields: {
        uploadFileName: 'Nome do Arquivo',
        execStartDate: 'Data e Hora de Início da Execução',
        execEndDate: 'Data e Hora de Término da Execução',
        execStatus: 'Estado',
        uploadFile: 'Arquivo Registrado',
        resultFile: 'Resultados do Registro',
      },
      execStatus: {
        complete: 'Concluído',
        inprogress: 'Processando',
        error: 'Erro',
      },
    },
    tasks: {
      name: 'Tarefa',
      fields: {
        name: 'Nome',
        status: 'Estado',
        description: 'Descrição',
        projectSourceLayerId: 'Camada',
        retrofitIds: 'Máquina Alvo',
        expiredDate: 'Data de Expiração',
        createDate: 'Data de Expiração',
        rectangle: 'Alcance Suportado',
        lastUpdated: 'Última Atualização',
      },
      status: {
        error: 'Erro',
        converting: 'Convertendo',
        sending: 'Transferindo',
        sent: 'Transferido',
        failtosend: 'Transferência falhou',
      },
    },
    compactionMaterials: {
      name: 'Materiais',
      fields: {
        name: 'Nome',
      },
    },
    weathers: {
      name: 'Condições Meteorológicas',
      fields: {
        name: 'Nome',
      },
    },
    operators: {
      name: 'Operadores',
      fields: {
        name: 'Nome',
      },
    },
    compactionWorkAreas: {
      name: 'Áreas de Trabalho de Compactação',
      fields: {
        name: 'Nome das Áreas de Trabalho',
        blockSize: 'Tamanho do Bloco',
        description: 'Descrição',
      },
    },
    compactionLayers: {
      name: 'Camadas de Compactação',
      fields: {
        name: 'Nome',
        thickness: 'Espessura',
        passCount: 'Contagem de Passagens',
        compactionMaterialId: 'Material',
        inUse: 'Estado',
        description: 'Descrição',
      },
      inUse: {
        true: 'em Uso',
        false: 'Não utilizado',
      },
    },
    gnssSettings: {
      name: 'Configuração Ntrip',
      fields: {
        registCorporationName: 'Registrado Por',
        ownerCorporationId: 'ID da Empresa',
        ownerCorporationName: 'Nome da Empresa',
        name: 'Nome',
        url: 'URL do Servidor',
        portnum: 'Bocal',
        userId: 'ID',
        password: 'Palavra-passe',
        retrofitId: 'Máquina Alvo',
        status: 'Estado',
        updateDate: 'Última Atualização',
      },
      status: {
        waitforsend: 'Convertendo',
        sending: 'Transferindo',
        sent: 'Transferido',
        failtosend: 'Transferência falhou',
      },
    },
    supportMode: {
      name: 'Modo de Suporte',
      fields: {
        enabled: 'Ativar Modo de Suporte',
        corporationId: 'ID da Empresa',
        corporationName: 'Nome da Empresa',
        siteId: 'ID do Local de Trabalho',
        siteName: 'Local de Trabalho',
        latitude: 'Latitude',
        longitude: 'Longitude',
        workPeriodStartDate: 'Período de Construção Real (Data de Início)',
        workPeriodEndDate: 'Período de Construção Real (Data de Fim)',
        photoUrl: 'URL da Imagem do Local',
        description: 'Descrição',
      },
    },
    userNotices: {
      name: 'Notificação',
      noticeType: {
        emergencyimportant: 'Emergência/Importante',
        emergency: 'Emergência',
        important: 'Importante',
      },
      fields: {
        noticeType: 'Importância',
        noticeTypes: 'Importância',
        noticeCategory: 'Tipo de Notificação',
        title: 'Título',
        content: 'Conteúdos',
        publicationStartDate: 'Data e Hora de Início (UTC)',
        publicationEndDate: 'Data e Hora de Término (UTC)',
        file: 'Anexos',
        language: 'Idioma',
        emergency: 'Emergência',
        important: 'Importante',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Versão',
        compatibilityLevel: 'Nível de Compatibilidade',
        firmwareFile: 'Arquivo de Firmware',
        firmwareFileCheckSum: 'Soma de Verificação do Firmware',
        firstInitializationFile: 'Arquivo de Inicialização 1',
        firstInitializationFileCheckSum:
          'Soma de Verificação do Arquivo de Inicialização 1',
        secondInitializationFile: 'Arquivo de Inicialização 2',
        secondInitializationFileCheckSum:
          'Soma de Verificação do Arquivo de Inicialização 2',
      },
    },
    bucketShares: {
      name: 'Compartilhamento de Balde',
      fields: {
        fromBucketId: 'Do ID da Balde',
        corporationId: 'ID da Empresa',
        name: 'Nome',
      },
    },
    extensionarmShare: {
      name: 'Compartilhamento do Braço Extensor',
      fields: {
        name: 'Nome',
      },
    },
    retrofitCorporationLicenses: {
      name: 'Licença',
      fields: {
        licenseId: 'ID da Licença',
        licenseType: 'Tipo',
        issuedAt: 'Data de Emissão',
        expiresAt: 'Data de Expiração',
        retrofitId: 'Máquina',
        comment: 'Descrição',
        licenseStatus: 'Estado',
        licensePermission: 'Licença',
        productType: 'Nome do Produto',
      },
      licenseStatus: {
        unlicense: 'Não Alocado',
        disable: 'Inválido',
        enable: 'Válido',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Nome da Empresa',
        namePlates: 'Foto',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Unidade de Comprimento',
      },
    },
    geofences: {
      name: 'Geocerca',
      fields: {
        type: 'Tipo',
        name: 'Nome',
        dimension: 'Dimensão',
        trigger: 'Disparador',
        valid: 'Válido',
        alertType: 'Tipo de Alerta',
        startDate: 'Data de Início',
        endDate: 'Data Final',
        transparency: 'Transparente',
        elevation: 'Elevação',
        height: 'Altura',
        radius: 'Raio',
        lastUpdated: 'Última Atualização',
        points: 'Alcance Suportado',
        thickness: 'Espessura',
      },
      type: {
        line: 'Linha',
        polygon: 'Polígono',
        circle: 'Círculo',
        point: 'Ponto',
        wall: 'Parede',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Válido',
        false: 'Inválido',
      },
      alertType: {
        attention: 'Atenção',
        notice: 'Aviso',
        warning: 'Aviso',
        caution: 'Cuidado',
        danger: 'Perigo',
      },
      transparency: {
        true: 'Transparente',
        false: 'Opaco',
      },
    },
    geofenceAlerts: {
      name: 'Alertas',
      fields: {
        timeStamp: 'Data e Hora de Detecção',
        geofenceName: 'Nome da Cerca Virtual',
        alertType: 'Tipo de Alerta',
        collisionPoint: 'Ponto de Colisão',
        retrofit: {
          machineInfoMachineName: 'Máquina',
        },
      },
      alertType: {
        attention: 'Atenção',
        notice: 'Aviso',
        warning: 'Aviso',
        caution: 'Cuidado',
        danger: 'Perigo',
      },
    },
    ntrips: {
      name: 'Gestão de Ntrip',
      fields: {
        name: 'ID do Usuário',
        ntripPass: 'Palavra-passe',
        serial: 'Nº de Série',
        customerCorporationId: 'ID da Empresa',
        customerCorporationName: 'Nome da Empresa',
        ntrip: 'Estado',
        numberOfExchanges: 'Número de Trocas',
        id: 'ID',
        licenceId: 'ID da Licença',
        dateOfRegistration: 'Data de Registro',
        billingToCustomers: 'Faturamento para Clientes',
        paymentToKomatsu: 'Pagamento para Komatsu',
        applicantId: 'ID do Requerente',
        applicant: 'Requerente',
        requestCorporationId: 'Solicitar ID da Empresa',
        requestCorporationName: 'Solicitar Nome da Empresa',
        isUsed: 'Está em Uso',
      },
      ntrip: {
        publish: 'Pago',
        stop: 'Parar',
        delete: 'Excluído',
      },
    },
    retrofitAlternateRegists: {
      name: 'Registro de Proxy do Controlador',
      fields: {
        id: 'ID',
        retrofitId: 'ID de retrofitagem',
        fromCorporationId: 'doCompanyId',
        fromCorporationName: 'Registro de Proxy do Controlador',
        toCorporationId: 'Usuário',
        toCorporationName: 'Nome da Empresa',
        isApproved: 'Estado',
        createDate: 'Data de Aplicação',
        actualDate: 'Data de Aprovação',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Última Atualização',
      },
      isApproved: {
        true: 'Aprovado',
        false: 'Não Aprovado',
      },
    },
    pendingApprovals: {
      name: 'Aguardando Aprovação',
      fields: {
        approvalType: 'Tipo',
        requestedBy: 'Empresa Requerente',
        lastUpdated: 'Última Atualização',
      },
      approvalType: {
        assignment: 'Atribuição',
        transfer: 'Transferência',
        agent: 'Registro de Proxy do Controlador',
      },
    },
    retrofitSendbacks: {
      name: 'Enviar de Volta',
      fields: {
        fromRetrofitId: 'Da Retrofitagem',
        toRetrofitId: 'Controlador Alternativo',
        notificationEmailAddress: 'E-mail',
        siteId: 'Local de Trabalho',
        corporationId: 'ID da Empresa',
        corporationName: 'Empresa em Uso',
        ntripId: 'Informações do Ntrip',
        isComplete: 'Envio de Volta Completo',
      },
    },
  },
};

export default message;
